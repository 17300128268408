import { Reducer } from "redux";
import { ICP_data } from "../../api/V2/3.CrossPromotion/API_CP";
import { ICPText, Language } from "../../api/V2/3.CrossPromotion/API_CPText";
import { IPlatform } from "../2.Platform/platform";

interface CPPlatformDatas {
  [os: string]: ICP_data;
}
const initialState: CPPlatformDatas = {
  ios: {
    game_id: "",
    platform: "ios",
    id: "",
    is_public: false,
    keep_time: 0,
    cpTextDatas: [],
    cpLinkDatas: [],
    cpResourceDatas: [],
    deleteCPTextQueue: [],
  },
  aos: {
    game_id: "",
    platform: "aos",
    id: "",
    is_public: false,
    keep_time: 0,
    cpTextDatas: [],
    cpLinkDatas: [],
    cpResourceDatas: [],
    deleteCPTextQueue: [],
  },
};

type CPPlatformDatasAction = ReturnType<
  | typeof initCP_data
  | typeof setCP_is_public
  | typeof setCP_keep_time
  | typeof addCP_textData
  | typeof setCP_textData
  | typeof editCP_textLang
  | typeof deleteCP_textData
  | typeof clearCP
>;

/**
 * Dispatch : Set Cross promotion data on OS platform
 * @param {ICP_data} CPData
 * @param {IPlatform} platform
 */
const initCP_data = (CPData: ICP_data, platform: IPlatform) => ({
  type: `initCP_data` as const,
  payload: CPData,
  platform,
});

/**
 * Dispatch : Set Cross promotion(is_public) allow on OS platform
 * @param {boolean} is_public
 * @param {IPlatform} platform
 */
const setCP_is_public = (is_public: boolean, platform: IPlatform) => ({
  type: `setCP_is_public` as const,
  payload: is_public,
  platform,
});

const setCP_keep_time = (time: number, platform: IPlatform) => ({
  type: `setCP_keep_time` as const,
  payload: time,
  platform,
});

/**
 * Dispatch : Add CP Text on OS platform
 * @param {ICPText} CPText
 * @param {IPlatform} platform
 */
const addCP_textData = (CPText: ICPText, platform: IPlatform) => ({
  type: `addCP_textData` as const,
  payload: CPText,
  platform,
});

/**
 * Dispatch : Edit CP Text on OS platform
 * @param {ICPText} CPText
 * @param {IPlatform} platform
 */
const setCP_textData = (CPText: ICPText, platform: IPlatform) => ({
  type: `setCP_textData` as const,
  payload: CPText,
  platform,
});

/**
 * Dispatch : Edit CP Text language on OS platform
 * @param {Language} before
 * @param {Language} after
 * @param {IPlatform} platform
 */
const editCP_textLang = (
  before: Language,
  after: Language,
  platform: IPlatform
) => ({
  type: `editCP_textLang` as const,
  payload: { before, after },
  platform,
});

/**
 * Dispatch : Delete CP_Language on OS platform
 * @param {string} lang
 * @param {IPlatform} platform
 */
const deleteCP_textData = (lang: Language, platform: IPlatform) => ({
  type: `deleteCP_textData` as const,
  payload: lang,
  platform,
});

/** Dispatch : clear CP data */
const clearCP = () => ({
  type: `clearCP` as const,
  payload: initialState,
  platform: undefined,
});

/**CP_data_Reducer creator
 * @return Cross Promotion Reducer : (state, action) => state
 */
const CP_datas: Reducer<CPPlatformDatas, CPPlatformDatasAction> = (
  state = initialState,
  action
) => {
  const { type, payload } = action;
  const platform = action.platform?.toLowerCase() as IPlatform;
  switch (type) {
    case `initCP_data`:
      return { ...state, [platform]: payload };
    case `setCP_is_public`:
      return {
        ...state,
        [platform]: { ...state[platform], is_public: payload },
      };
    case `setCP_keep_time`: {
      return {
        ...state,
        [platform]: { ...state[platform], keep_time: payload },
      };
    }
    case `addCP_textData`:
      return {
        ...state,
        [platform]: {
          ...state[platform],
          cpTextDatas: [...state[platform].cpTextDatas, payload],
        },
      };
    case `setCP_textData`: {
      const editedData = [...state[platform].cpTextDatas];
      const index = editedData.findIndex(
        (data) => data.language === payload.language
      );
      const editedText: ICPText = editedData[index];
      editedData.splice(index, 1, { ...editedText, ...payload });
      return {
        ...state,
        [platform]: { ...state[platform], cpTextDatas: editedData },
      };
    }
    case "editCP_textLang": {
      const editedData = [...state[platform].cpTextDatas];
      const standard = (data: ICPText) => data.language === payload.before;
      const index = editedData.findIndex(standard);
      const text = editedData.find(standard) as ICPText;
      editedData.splice(index, 1, { ...text, language: payload.after });
      return {
        ...state,
        [platform]: { ...state[platform], cpTextDatas: editedData },
      };
    }
    case `deleteCP_textData`:
      const deletedData = [...state[platform].cpTextDatas].filter(
        (data) => data.language !== payload
      );
      return {
        ...state,
        [platform]: { ...state[platform], cpTextDatas: deletedData },
      };
    case "clearCP":
      return initialState;
    default:
      return state;
  }
};

export default CP_datas;

export const CP_dataActions = {
  initCP_data,
  setCP_is_public,
  setCP_keep_time,
  addCP_textData,
  setCP_textData,
  editCP_textLang,
  deleteCP_textData,
  clearCP,
};

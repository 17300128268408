import axios from "axios";
import { isDebug } from "../../sdlib/sdutil";

/**
 * API 기본 주소
 * 디버그모드인 경우에는 local 서버를 바라보게끔 설정
 */
export const origin = /500[0-9]/.test(window.location.port.toString())
  ? window.location.origin
  : isDebug()
  ? "https://mayocmsdev.im-fine.dev"
  : window.location.origin;

export const base_url = `${origin}/api/V2`;

// export const base_url = "https://mayocms.im-fine.dev";

export function getServerVersion() {
  return axios.post(`${origin}/api/version`);
}

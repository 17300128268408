import React, { useState, useEffect } from "react";
import { useQueryClient } from "react-query";
import { useAppSelector } from "../../../../../modules";
import querykeys from "../../../../../api/V2/query";
import {
  Description,
  Label,
} from "../../../Interactions/Inputs/MayoInputStyled";
import ResourceBox from "./resource/ResourceBox";
import { MediaBoxs } from "../CrossPromotionStyled";
import { medias } from "../CrossPromotionPageData.json";

const { label, description } = medias;

interface IBannerMedias extends React.InputHTMLAttributes<HTMLInputElement> {}

const BannerMedias: React.FC<IBannerMedias> = () => {
  //State
  const platform = useAppSelector((state) => state.platform);
  const currentGame_cp_data = useAppSelector(
    (state) => state.currentGame.cp_datas[platform]
  );
  const CP_resources = useAppSelector((state) => state.CP_resources);
  const [isChanged, setIsChanged] = useState(false);
  //destruction State

  //Queried
  const queryClient = useQueryClient();
  const fetchedImg = queryClient.getQueryData<File>([
    querykeys.cross_promotion.files,
    currentGame_cp_data?.id,
    "image",
  ]);
  const fetchedVid = queryClient.getQueryData<File>([
    querykeys.cross_promotion.files,
    currentGame_cp_data?.id,
    "video",
  ]);

  //LifeCycle
  useEffect(() => {
    if (!!CP_resources.image.file && !!CP_resources.video.file)
      setIsChanged(
        fetchedImg?.size !== CP_resources.image.file?.size ||
          fetchedVid?.size !== CP_resources.video.file?.size
      );
    return () => {
      setIsChanged(false);
    };
  }, [fetchedImg, fetchedVid, CP_resources]);

  return (
    <Label
      id="CP_resource"
      className={"multi-platform" + (isChanged ? " changed" : "")}
    >
      {label}
      <Description>{description}</Description>
      <MediaBoxs>
        <ResourceBox resourceType="image" />
        <ResourceBox resourceType="video" />
      </MediaBoxs>
    </Label>
  );
};

export default React.memo(BannerMedias);

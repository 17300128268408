import React from "react";
import { useQueryClient } from "react-query";
import querykeys from "../../../../api/V2/query";
import { useAppDispatch, useAppSelector } from "../../../../modules";
import { IGameFullData } from "../../../../api/V2/1.Game/API_Game";
import setting_propsAPI from "../../../../api/V2/2.SettingProps/API_SettingProps";
import {
  currentGameActions,
  SettingPropsKey,
} from "../../../../modules/1.Game/currentGame";
import { platformActions } from "../../../../modules/2.Platform/platform";
import { submitBtnActions } from "../../../../modules/submitBtn";
import MayoToggleInput from "../../Interactions/Inputs/MayoToggleInput";
import { Form } from "./SettingPropsStyled";
import { formTerms } from "./SettingPropsPageData.json";

export interface ISettingPropsForm {
  formTerms: {
    name: string;
    label: string;
    description?: string;
    disabled?: boolean;
  }[];
}

const SettingPropsForm: React.FC = () => {
  //State
  const currentGame = useAppSelector((state) => state.currentGame);
  const platform = useAppSelector((state) => state.platform);
  const dispatch = useAppDispatch();

  //desctruction State
  const { id: game_id, setting_datas } = currentGame;
  const setting_properties = setting_datas[platform];
  const { setSettingProps } = currentGameActions;
  const { osStandard } = platformActions;
  const { giveSubmitBtnNoti, setSubmitBtnLoading } = submitBtnActions;

  //Query
  const queryClient = useQueryClient();
  const fetchedGameData = queryClient.getQueryData<IGameFullData>([
    querykeys.current_game,
    game_id,
  ]);
  const fetchedSettingProps = fetchedGameData?.setting_datas.find((data) =>
    osStandard(data, platform)
  );

  //Hooks & LifeCycles

  //Functions
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      currentTarget: { name, checked },
    } = event;
    dispatch(setSettingProps(name as SettingPropsKey, checked, platform));
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    //버튼 막기
    dispatch(setSubmitBtnLoading(true));

    const onSuccess = () => {
      dispatch(giveSubmitBtnNoti("save"));
    };
    const onFailed = () => {
      dispatch(giveSubmitBtnNoti("fail"));
    };

    const onFinal = () => {
      queryClient.refetchQueries([querykeys.current_game, game_id]);
      dispatch(setSubmitBtnLoading(false));
    };
    // 새롭게 제출하느냐 업데이트 하느냐 차이
    if (!!fetchedSettingProps) {
      setting_propsAPI
        .updateSettingProps(setting_properties)
        .then(onSuccess)
        .catch(onFailed)
        .finally(onFinal);
    } else {
      setting_propsAPI
        .createSettingProps({ ...setting_properties, game_id })
        .then(onSuccess)
        .catch(onFailed)
        .finally(onFinal);
    }
  };

  return (
    <Form
      id={`${platform}_setting_props_form`}
      key={platform}
      onSubmit={onSubmit}
    >
      {formTerms.map(({ name, label, description, disabled }) =>
        !disabled ? (
          <MayoToggleInput
            //custom props
            label={label}
            description={description}
            //Input Attr
            key={name}
            id={name}
            name={name}
            checked={setting_properties[name as SettingPropsKey] as boolean}
            beforeChecked={
              !!fetchedSettingProps
                ? (fetchedSettingProps[name as SettingPropsKey] as boolean)
                : false
            }
            onChange={onChange}
            disabled={disabled}
          />
        ) : null
      )}
    </Form>
  );
};

export default SettingPropsForm;

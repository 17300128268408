import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { gameRoutes } from "../../../../routes";
import { useCurrentGameQuery } from "../../../../api/V2/queryHooks";
import { useAppDispatch, useAppSelector } from "../../../../modules";
import { modalActions } from "../../../../modules/modal";
import { current_GameID, isChanged } from "../../../utils";
import { SettingIcon } from "../../SVGs";
import {
  Container,
  Title,
  CMSList,
  CMSListItem,
  CMSMenu,
  CreateNew,
} from "../MenubarStyled";
import { currentGameActions } from "../../../../modules/1.Game/currentGame";

/**
 * 현재 게임에 해당하는 Properties Menu 를 불러 옵니다.
 * @returns
 */
function GameCMSList() {
  //State
  const currentGame = useAppSelector((state) => state.currentGame);
  const dispatch = useAppDispatch();
  //destruction State
  const { setUnsavedModalOn } = modalActions;
  //Page
  const navigate = useNavigate();
  const cmsList = Object.values(gameRoutes);
  const currentPage = window.location.pathname.split("/").at(-1);
  //Query
  const { isLoading } = useCurrentGameQuery(current_GameID() as string, {});
  //LifeCycle
  useEffect(() => {
    return () => {
      dispatch(currentGameActions.resetCurrentGame());
    };
  }, []);
  //Function
  const navigateByParam = (menu: string) => {
    const cmsMenu = menu.replace(/\s/g, "_");
    const goToMenu = () => navigate(`/game/${currentGame.id}/${cmsMenu}`);
    if (currentPage === menu) return; //현재 페이지이면 아무것도 안합니다.
    isChanged() ? dispatch(setUnsavedModalOn(goToMenu)) : goToMenu();
  };

  return (
    <>
      {isLoading ? null : (
        <Container>
          <Title isCuurentPage={"setting" === currentPage}>
            {currentGame.title.replace("_", " ")}
            <CreateNew onClick={() => navigateByParam("setting")}>
              <SettingIcon />
            </CreateNew>
          </Title>
          <CMSList>
            {cmsList.map((menu) => (
              <CMSListItem key={menu} onClick={() => navigateByParam(menu)}>
                <CMSMenu isCuurentPage={menu === currentPage}>
                  {menu.replace("_", " ")}
                </CMSMenu>
              </CMSListItem>
            ))}
          </CMSList>
        </Container>
      )}
    </>
  );
}

export default GameCMSList;

import client from "../baseV2API";

export type Language =
  | "en"
  | "ko"
  | "ja"
  | "ru"
  | "de"
  | "es"
  | "fr"
  | "it"
  | "pt"
  | "tr"
  | "zh-chs"
  | "zh-cht"
  | "th";

export interface ICPText {
  cp_id?: string;
  id?: string;
  language: Language;
  title: string;
  script?: string;
}

//Text
/**
 * CPtext 형식 그대로 새로운 CPtext 생성.(formdata id항목은 자동으로 뻅니다.)
 * @param CPText
 * @returns
 */
const createCPText = (CPText: ICPText) => {
  const formData = new FormData();
  formData.delete("id");
  Object.entries(CPText).forEach(([name, value]) =>
    formData.append(name, value)
  );
  return client.post<ICPText>("/CP/cptext", formData);
};

/**
 * CPtext 형식 그대로 CPtext 업데이트.
 * @param CPText
 * @returns
 */
const updateCPText = (CPText: ICPText) => {
  const formData = new FormData();
  Object.entries(CPText).forEach(([name, value]) =>
    formData.append(name, value)
  );
  return client.put<ICPText>("/CP/cptext", formData);
};

const getCPText = () => {
  return client.get("/CP/cptext");
};

const deleteCPText = (cpText_id: string) => {
  const formData = new FormData();
  formData.append("id", cpText_id);
  return client.delete("/CP/cptext", { data: formData });
};

const cp_textAPI = {
  create: createCPText,
  update: updateCPText,
  get: getCPText,
  delete: deleteCPText,
};

export default cp_textAPI;

import styled from "styled-components";

export const AppContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, 550px) 1fr;
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
`;
export const AppNavi = styled.div<{ block: boolean }>`
  height: calc(100vh - 100px);
  padding-top: 100px;
  padding-left: 100px;
  ${(props) => (props.block ? "pointer-events: none;" : null)};
`;
export const AppMain = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px);
  padding-top: 100px;
  padding-right: 30px;
`;

export const Display = styled.div`
  padding-left: 120px;
  padding-right: 120px;
  overflow-y: scroll;
  background-color: transparent;
  ::-webkit-scrollbar {
    display: block;
    opacity: 0;
    width: 8px;
  }
  &:hover {
    ::-webkit-scrollbar {
      opacity: 1;
      width: 8px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: black;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-track {
      background-color: transparent;
      margin: 120px 0px;
    }
  }
`;

import { useNavigate } from "react-router-dom";
import { gameRoutes, mainRoutes } from "../../../../routes";
import {
  Container,
  Title,
  Number,
  List,
  ListItem,
  GameTitle,
  CreateNew,
} from "../MenubarStyled";
import GameIcon from "./GameIcon";
import { useAppDispatch } from "../../../../modules";
import { IGameBaseData } from "../../../../api/V2/1.Game/API_Game";
import { GameIconLoader } from "../../Interactions/Loaders";
import { isChanged } from "../../../utils";
import { modalActions } from "../../../../modules/modal";
import { useGameListQuery } from "../../../../api/V2/queryHooks";
/**
 * 게임 리스트를 불러오며 각 리스트 요소는 navigate + 해당 게임의 정보를 불러옵니다.
 * @returns GameIcon + Game name button
 */
function MayoGameList() {
  //State
  const dispatch = useAppDispatch();
  //destructionState
  const { setUnsavedModalOn } = modalActions;
  //Query
  const { isLoading: gameListLoading, data: gameList } = useGameListQuery({
    cacheTime: 1000 * 60 * 60,
    retry: 1,
  });

  //Page
  const navigate = useNavigate();
  const currentPage = window.location.pathname.split("/").at(-1);
  //Function
  const beforeLeave = (action: () => any) => {
    isChanged() ? dispatch(setUnsavedModalOn(action)) : action();
  };

  const onCreateClick = () => {
    beforeLeave(() => navigate(mainRoutes.create));
  };

  const onClick = (game: IGameBaseData) => {
    const game_id = game.id;
    // Navigate 할 떄, getGameList의 게임 기본 정보 활용하여 Full info 요청
    const navigateByGameId = () =>
      navigate(`/game/${game_id}/${gameRoutes.dashboard}`);
    beforeLeave(navigateByGameId);
  };

  return (
    <Container>
      <Title isCuurentPage={currentPage === "create_game"}>
        Games
        <Number>{gameList ? gameList.length : 0}</Number>
        <CreateNew onClick={onCreateClick}>+</CreateNew>
      </Title>
      <List>
        {gameListLoading ? (
          <GameIconLoader />
        ) : gameList ? (
          gameList.map((game, index) => {
            return (
              <ListItem key={game.title + index} onClick={() => onClick(game)}>
                <GameIcon game_id={game.id} game_title={game.title} />
                <GameTitle>{game.title.replace("_", " ")}</GameTitle>
              </ListItem>
            );
          })
        ) : null}
      </List>
    </Container>
  );
}

export default MayoGameList;

import { useState } from "react";
import { useQueryClient } from "react-query";
import requestRankboard from "../../../../../api/V2/5.Rankboard/API_Rank";
import { useAppDispatch, useAppSelector } from "../../../../../modules";
import { modalActions } from "../../../../../modules/modal";
import { ExistBoardConfig } from "../../../../../modules/rankboard";
import { CopyClipboardIcon, SaveIcon } from "../../../SVGs";
import BoardsByType from "./BoardsByType";
import {
  Section,
  Label,
  BoardBox,
  Name,
  Method,
  DeleteBtn,
} from "../RankbooadStyled";
import querykeys from "../../../../../api/V2/query";

interface IExistBoard {
  board: ExistBoardConfig;
}

const ExistBoard: React.FC<IExistBoard> = ({ board }) => {
  //State
  const currentGame = useAppSelector((state) => state.currentGame);
  const dispatch = useAppDispatch();
  //destruction State
  const { setDeleteModalOn, setWarningModalOn } = modalActions;
  //LocalState
  const [noti, setNoti] = useState(false);

  //Query
  const queryClient = useQueryClient();

  //Functions
  const onCopyClick = () => {
    navigator.clipboard.writeText(board.id);
    setNoti(true);
    setTimeout(() => setNoti(false), 5000);
  };

  const onDltClick: React.MouseEventHandler<HTMLButtonElement> = async (
    event
  ) => {
    event.preventDefault();
    const deleteAction = async () => {
      await requestRankboard
        .delete(board.id)
        .catch((err) => dispatch(setWarningModalOn("삭제에 실패했습니다.")));
      queryClient.refetchQueries<ExistBoardConfig[]>([
        querykeys.rankboard,
        currentGame.id,
      ]);
    };
    dispatch(setDeleteModalOn(deleteAction));
  };

  return (
    <BoardBox>
      <DeleteBtn type="button" onClick={onDltClick}>
        ×
      </DeleteBtn>
      Rankboad ID
      <span>
        {board.id}
        <CopyClipboardIcon className="copy" onClick={onCopyClick} />
        {noti ? <SaveIcon /> : null}
      </span>
      <Section>
        <Label>
          Rankboard 이름
          <Name>{board.title}</Name>
        </Label>
        <Label>
          정렬 방식
          <Method>{board.method === "asc" ? "내림차순" : "오름차순"}</Method>
        </Label>
      </Section>
      <BoardsByType board_id={board.id} block={false} />
    </BoardBox>
  );
};

export default ExistBoard;

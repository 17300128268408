import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const KeyBox = styled.div`
  display: grid;
  grid-template-columns: 100px 40px 40px 5fr 3fr 40px;
  align-items: center;
  padding: 20px 0px;
  column-gap: 2rem;
  row-gap: 2rem;
  & svg {
    justify-self: center;
    cursor: pointer;
  }
  & svg:hover {
    fill: ${(props) => props.theme.hover};
  }
`;

export const Label = styled.label`
  width: 100px;
  height: 36.6px;
  cursor: pointer;
`;

export const Toggle = styled.input`
  position: relative;
  top: -4px;
  left: 80px;
  width: 0px;
  height: 0px;
  z-index: 1;
  &::before {
    position: absolute;
    content: "";
    top: 0;
    right: 5px;
    width: 60px;
    height: 36.6px;
    border-radius: 19px;
    background-color: ${(props) => props.theme.toggle.unchecked};
    pointer-events: none;
    z-index: 1;
    cursor: pointer;
  }
  &:checked::before {
    background-color: ${(props) => props.theme.toggle.checked};
  }
  &::after {
    position: absolute;
    content: "";
    top: 5.8px;
    right: 33px;
    width: 25px;
    height: 25px;
    border-radius: 12.5px;
    background-color: ${(props) => props.theme.toggle.egg};
    pointer-events: none;
    transition: 0.36s;
    z-index: 1;
  }
  &:checked::after {
    right: 13px;
  }
`;

export const API = styled.div`
  position: relative;
  overflow: visible;
`;

export const Key = styled.input`
  width: 100%;
  min-width: 600px;
  display: flex;
  overflow: visible;
  font-size: 2rem;
  white-space: nowrap;
  letter-spacing: ${(props) => (props.type === "text" ? "0px" : "0.52rem")};
  border-bottom: none; ;
`;

export const Notification = styled.div`
  position: absolute;
  top: 3.5rem;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 700;
  color: ${(props) => props.theme.noti.success};
  z-index: 1;
  & svg {
    fill: ${(props) => props.theme.noti.success};
  }
`;

export const Issued = styled.div`
  width: fit-content;
  display: flex;
  justify-content: flex-start;
  color: ${(props) => props.theme.dimColor};
  font-size: 1.5rem;
  font-weight: 700;
`;

export const DeleteBtn = styled.button`
  min-width: 40px;
  padding: 1rem;
  font-size: 2rem;
  font-weight: 800;
  &:hover {
    color: ${(props) => props.theme.submitBtn.danger};
  }
  border: none;
  cursor: pointer;
`;

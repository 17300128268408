import { useEffect } from "react";
import { useRankboardListQuery } from "../../../../api/V2/queryHooks";
import { useAppDispatch, useAppSelector } from "../../../../modules";
import {
  addCreateRankboard,
  clearCreateRankboard,
  CreateBoardConfig,
} from "../../../../modules/rankboard";
import { FormLoader } from "../../Interactions/Loaders";
import NoData from "../../Interactions/notification/NoData";
import StickyHeader from "../../Interactions/StickyHeader/StickyHeader";
import BoardsForm from "./Form/BoardsForm";
import { Container } from "./RankbooadStyled";

function Rankboard() {
  const currentGame = useAppSelector((state) => state.currentGame);
  const rankboard = useAppSelector((state) => state.rankboard);
  const dispatch = useAppDispatch();

  //Query
  const { isLoading, data } = useRankboardListQuery(currentGame.id, {
    enabled: !!currentGame.id,
    retry: 2,
  });
  //LifeCycle
  useEffect(() => {
    return () => {
      dispatch(clearCreateRankboard());
    };
  }, []);

  //Function
  const onAddClick = () => {
    const baseConfig: CreateBoardConfig = {
      game_id: currentGame.id,
      title: "",
      method: "desc",
    };
    dispatch(addCreateRankboard(baseConfig));
  };

  return (
    <Container>
      <StickyHeader title="Rankboard" onClick={onAddClick} />
      {isLoading ? (
        <FormLoader />
      ) : !data || data?.length + rankboard.toCreateQueue.length === 0 ? (
        <NoData content="랭크보드를 생성하세요." />
      ) : (
        <BoardsForm />
      )}
    </Container>
  );
}

export default Rankboard;

import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../modules";
import { modalActions } from "../../../../modules/modal";
import { ModalTerm } from "../Modal";
import ModalBase from "./ModalBase";

const Warning: React.FC = () => {
  //State
  const modal = useAppSelector((state) => state.modal);
  const dispatch = useAppDispatch();
  const { setModalOff } = modalActions;

  const warning_term: ModalTerm = {
    instruction: modal.warningInstuction || "",
    buttons: [{ name: "확인", type: "check" }],
  };

  const onClick = () => {
    return dispatch(setModalOff());
  };

  return <ModalBase term={warning_term} onClick={onClick} />;
};

export default Warning;

import client from "./baseV2API";

/**
 * Axios : D 기록 ID 를 이용해서 기록 지우기.
 * @param record_id
 * @returns
 */
export const deleteRecord = (record_id: string) => {
  const formdata = new FormData();
  formdata.append("id", record_id);
  return client.delete("/record", { data: formdata });
};

export const deleteHonor = (record_id: string) => {
  const formdata = new FormData();
  formdata.append("id", record_id);
  return client.delete("/honor", { data: formdata });
};

import { useAppSelector } from "../../../modules";
import { ModalMode } from "./ModalStyled";
import Unsaved from "./type/Unsaved";
import Delete from "./type/Delete";
import DeleteGame from "./type/DeleteGame";
import Warning from "./type/Warning";
import NotCreated from "./type/NotCreated";
import NoFiles from "./type/NoFiles";

export type ModalTerm = {
  instruction: string;
  buttons: {
    name: string;
    type: string;
  }[];
};

/**
 * @setModalOn dispatch method 를 통해서 모달창이 UI 에 보여 지게끔 설계되어 있습니다.
 *
 * modal type 에 따라서 Modal 이 변경됩니다.
 *  * unsaved : 저장하지 않은 Form 제출 여부,
 *  * warning : 주의가 필요한 액션에 대한 경고 및 확인.
 *  * delete : 삭제 요청 재차 확인.
 * @returns
 */
const Modal: React.FC = () => {
  const modal = useAppSelector((state) => state.modal);
  const { activate, modalType } = modal;

  return (
    <>
      {activate ? (
        <ModalMode>
          {(() => {
            switch (modalType) {
              case "unsaved":
                return <Unsaved key="unsaved" />;
              case "warning":
                return <Warning key="warning" />;
              case "no_banner_files":
                return <NoFiles key="no_banner_files" />;
              case "delete":
                return <Delete key="delete" />;
              case "not_created":
                return <NotCreated key="not_created" />;
              case "delete_game":
                return <DeleteGame key="delete_game" />;
            }
          })()}
        </ModalMode>
      ) : null}
    </>
  );
};

export default Modal;

import {
  Label,
  Toggle,
  Description,
  ToggelLayout,
  ToggleArea,
} from "./MayoInputStyled";

interface IMayoToggleInput extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  description?: string;
  beforeChecked?: boolean;
}

/** 기본 Input 값을 계승하여 Label 과  description을 추가한
 * * Toggle type INPUT 컴포넌트
 * @param {string} label string
 * @param {boolean} beforeChecked boolean
 * @param {string} description string | undefine
 */
const MayoToggleInput: React.FC<IMayoToggleInput> = (props) => {
  return (
    <ToggelLayout>
      <Label
        id={props.id}
        className={props.beforeChecked !== props.checked ? "changed" : ""}
        htmlFor={props.id}
      >
        {props.label}
        {props.description ? (
          <Description>
            {props.description.split("\n").map((des) => (
              <span key={des}>{des}</span>
            ))}
          </Description>
        ) : null}
      </Label>
      <ToggleArea>
        <Toggle className="toggle-input" type="checkbox" {...props} />
      </ToggleArea>
    </ToggelLayout>
  );
};

export default MayoToggleInput;

import { noSpace } from "../../../v2/utils";
import client from "../baseV2API";

//Resource
export interface ICPResource {
  type: "image" | "video" | "video_thumbnail";
  blob_name: string;
  name: string;
  width: number;
  height: number;
  data_size: number;
  cp_id: string;
  id: string;
}

interface Resource {
  url: string;
  expires_on: string;
}

/**
 * Axios Post : Cross Promotion 사용할 파일 이미지 업로드
 * @param file
 * @param cp_id
 */
const createCPImage = async (file: File, cp_id: string) => {
  const image = document.createElement("img");
  const promise = new Promise<HTMLImageElement>((resolve, reject) => {
    image.onload = async () => {
      resolve(image);
    };
    image.src = URL.createObjectURL(file);
  });
  const loadedImage = await promise;
  const headers = {
    "Content-Type": file.type,
    name: noSpace(file.name),
    type: "image",
    width: loadedImage.width,
    height: loadedImage.height,
    data_size: file.size,
    cp_id,
  };
  console.log(headers);
  return client.post<ICPResource>("/CP/resource", file, { headers });
};

/**
 * Axios Put : Cross Promotion 사용할 파일 이미지 업데이트
 * @param file
 * @param cp_id
 */
const updateCPImage = async (
  file: File,
  cp_id: string,
  resource_id?: string
) => {
  const image = document.createElement("img");
  const promise = new Promise<HTMLImageElement>((resolve, reject) => {
    image.onload = async () => {
      resolve(image);
    };
    image.src = URL.createObjectURL(file);
  });
  const loadedImage = await promise;
  const headers = {
    "Content-Type": file.type,
    name: noSpace(file.name),
    type: "image",
    width: loadedImage.width,
    height: loadedImage.height,
    data_size: file.size,
    cp_id,
    id: resource_id,
  };
  return client.put<ICPResource>("/CP/resource", file, { headers });
};

/**
 * Axios Post : Cross Promotion 사용할 파일 비디오 업로드
 * @param file
 * @param cp_id
 */
const createCPVideo = async (file: File, cp_id: string) => {
  const video = document.createElement("video");
  const promise = new Promise<HTMLVideoElement>((resolve, reject) => {
    video.onloadedmetadata = () => {
      resolve(video);
    };
    video.src = URL.createObjectURL(file);
  });
  const loadedVideo = await promise;
  const headers = {
    "Content-Type": file.type,
    name: noSpace(file.name),
    type: "video",
    width: loadedVideo.videoWidth,
    height: loadedVideo.videoHeight,
    data_size: file.size,
    cp_id,
  };
  return client.post<ICPResource>("/CP/resource", file, { headers });
};

/**
 * Axios Put : Cross Promotion 사용할 파일 비디오 업데이트
 * @param file
 * @param cp_id
 */
const updateCPVideo = async (
  file: File,
  cp_id: string,
  resource_id?: string
) => {
  const video = document.createElement("video");
  const promise = new Promise<HTMLVideoElement>((resolve, reject) => {
    video.onloadedmetadata = () => {
      resolve(video);
    };
    video.src = URL.createObjectURL(file);
  });
  const loadedVideo = await promise;
  const headers = {
    "Content-Type": file.type,
    name: noSpace(file.name),
    type: "video",
    width: loadedVideo.videoWidth,
    height: loadedVideo.videoHeight,
    data_size: file.size,
    cp_id,
    id: resource_id,
  };
  return client.put<ICPResource>("/CP/resource", file, { headers });
};

/**
 * Axios Get : Get Resource by blob_name
 * @param blob_name
 */
const getCP_resource = async (blob_name: string) => {
  const data = await client
    .get<Resource>("/CP/resource", {
      params: { blob_name },
    })
    .then((response) => response.data);

  const blob = await (await fetch(data.url)).blob();
  const file = new File([blob], noSpace(blob_name) || "", {
    type: blob.type,
  });
  return file;
};

const CPImage = {
  create: createCPImage,
  update: updateCPImage,
};

const CPVideo = {
  create: createCPVideo,
  update: updateCPVideo,
};

const cp_resourceAPI = { CPImage, CPVideo, getCP_resource };

export default cp_resourceAPI;

import client from "../baseV2API";

export interface ICPLinkData {
  cp_id: string;
  link_id: string;
  id: string;
  platform?: string;
}
/**
 * cp_id + target_cp_id(link_id) => cp linked. return cp_link_id
 * @param cp_id
 * @param link_target_cp_id
 * @returns
 */
const createCPLink = async (cp_id: string, link_target_cp_id: string) => {
  const formData = new FormData();
  formData.append("cp_id", cp_id);
  formData.append("link_id", link_target_cp_id);
  return client.post<ICPLinkData>("/CP/cplink", formData);
};

const deleteCPLink = (LinkData_id: string) => {
  const formdata = new FormData();
  formdata.append("id", LinkData_id);
  return client.delete("/CP/cplink", { data: formdata });
};

const cp_linkAPI = {
  create: createCPLink,
  delete: deleteCPLink,
};

export default cp_linkAPI;

import { IPlatform } from "../../../modules/2.Platform/platform";
import { objectToFormData } from "../../../v2/utils";
import client from "../baseV2API";

export type PlatformDataKey = keyof IPlatformData;
export interface IPlatformData {
  id?: string;
  game_id?: string;
  platform?: IPlatform;
  major?: string;
  minor?: string;
  patch?: string;
  reversion?: string;
  version_str?: string;
  url_store?: string;
  url_rating?: string;
}

/** Axios Post : 플랫폼 데이터를 등록합니다.
 * @param {IPlatformData} platformData
 * @param {string} game_id
 */
const uploadPlatform = (platformData: IPlatformData, game_id: string) => {
  const formdata = objectToFormData(platformData);
  formdata.append("game_id", game_id);
  return client.post<IPlatformData>("/game/platform", formdata, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

/**
 * Axios Put : 플랫폼 데이터를 업데이트 합니다.
 * @param {IPlatformData} platformData
 */
const updatePlatform = (platformData: IPlatformData) => {
  const formdata = objectToFormData(platformData);
  return client.put<IPlatformData>("/game/platform", formdata);
};

/**
 * Axios Delete : 플랫폼 데이터를 삭제합니다.
 * @param {IPlatformData['id']} platformData_id
 */
const deletePlatform = (platform_id: IPlatformData["id"]) => {
  const formdata = new FormData();
  if (platform_id) formdata.append("id", platform_id);
  return client.delete(`/game/platform`, { data: formdata });
};

const platform_dataAPI = {
  uploadPlatform,
  updatePlatform,
  deletePlatform,
};

export default platform_dataAPI;

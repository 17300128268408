import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import styled from "styled-components";
import { mainRoutes } from "../../../routes";
import SubbmitButton from "../Interactions/submitButton/SubmitBtn";

const Container = styled.div`
  min-height: 130px;
  display: flex;
  justify-content: space-between;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  text-transform: capitalize;
`;

type HeaderInfo = "dashboard" | "settings" | "신규 게임 등록" | "게임 설정";

function MainHeader() {
  const [title, setTitle] = useState<HeaderInfo>("dashboard");

  //location function
  const location = useLocation();
  const currentPath = window.location.pathname;
  const { settings, create } = mainRoutes;

  useEffect(() => {
    switch (true) {
      case currentPath === settings:
        return setTitle("settings");
      case currentPath === create:
        return setTitle("신규 게임 등록");
      case /setting$/.test(currentPath):
        return setTitle("게임 설정");
      default:
        return setTitle("dashboard");
    }
  }, [location]);

  return (
    <Container>
      <Title>{title}</Title>
      <SubbmitButton />
    </Container>
  );
}

export default MainHeader;

import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const Form = styled.form`
  position: relative;
  margin: 50px 50px 50px 0px;
  row-gap: 56px;
`;

export const MediaBoxs = styled.div`
  display: flex;
  padding-top: 42px;
  column-gap: 35px;
`;

export const GridTemplate = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-content: space-between;
  justify-content: flex-start;
  gap: 16px;
`;

export const ExposeSelection = styled.label`
  display: grid;
  grid-template-columns: 50px 1fr;
  align-items: center;
  width: fit-content;
  min-width: 160px;
  max-height: 80px;
  height: 80px;
  padding: 0 18px;
  column-gap: 12px;
  font-size: 1.5rem;
  text-transform: capitalize;
  border-width: 4px;
  border-style: solid;
  border-color: transparent;
  border-radius: 16px;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  &.checked {
    border-color: ${(props) => props.theme.border.active};
  }
  &.disabled {
    opacity: 0.35;
  }
  &.not_released {
    border-color: ${(props) => props.theme.border.inactive};
  }
  & input {
    position: absolute;
    opacity: 0;
  }
  & h1 {
    text-align: center;
  }
`;

export const IconImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 10px;
`;

export const NumberInput = styled.input`
  max-width: 70px;
  padding: 10px 18px;
`;
export const Flexbox = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 3rem;
`;

import { ISettingProperties } from "../../../modules/1.Game/currentGame";
import { objectToFormData } from "../../../v2/utils";
import client from "../baseV2API";

const createSettingProps = (setting_datas: ISettingProperties) => {
  const formdata = objectToFormData(setting_datas);
  formdata.delete("id");
  formdata.delete("platfomName");
  return client.post<ISettingProperties>("/setting", formdata);
};

const updateSettingProps = (setting_datas: ISettingProperties) => {
  const formdata = objectToFormData(setting_datas);
  return client.put<ISettingProperties>("/setting", formdata);
};

const deleteSettingProps = (item_id: string) => {
  const formdata = new FormData();
  formdata.append("id", item_id);
  return client.delete("/setting", { data: formdata });
};

const setting_propsAPI = {
  createSettingProps,
  updateSettingProps,
  deleteSettingProps,
};

export default setting_propsAPI;

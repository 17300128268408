import { To, useMatch, useNavigate } from "react-router-dom";
import { mainRoutes } from "../../../routes";
import { useAppDispatch } from "../../../modules";
import { modalActions } from "../../../modules/modal";
import { isChanged } from "../../utils";
import GameCMSList from "./InGame/GameCMSList";
import MayoGameList from "./InMain/GameList";
import MenuBarTitle from "./Title";
import VersionInfo from "./VersionInfo";
import { Menu, MenuContainer, MenuSection } from "./MenubarStyled";

function MenuBar() {
  //State
  const dispatch = useAppDispatch();
  //destruction State
  const { setUnsavedModalOn } = modalActions;
  //Pages
  const { home, settings } = mainRoutes;
  const navigate = useNavigate();
  const isGamePage = useMatch("/game/*");
  const currentPage = window.location.pathname.split("/").at(-1);

  const navigateAfterCheck = (to: To) => {
    isChanged()
      ? dispatch(setUnsavedModalOn(() => navigate(to)))
      : navigate(to);
  };

  const onDashboardClick = () => {
    navigateAfterCheck(home);
  };

  const onSettingsClick = () => {
    navigateAfterCheck(settings);
  };

  return (
    <MenuContainer>
      <MenuBarTitle />
      <MenuSection>
        {isGamePage ? (
          <GameCMSList />
        ) : (
          <>
            <Menu onClick={onDashboardClick} isCurrentPage={"" === currentPage}>
              Dashboard
            </Menu>
            <MayoGameList />
            <Menu
              onClick={onSettingsClick}
              isCurrentPage={"settings" === currentPage}
            >
              Settings
            </Menu>
          </>
        )}
      </MenuSection>
      <VersionInfo />
    </MenuContainer>
  );
}

export default MenuBar;

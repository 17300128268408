import { useAPIKeyQuery } from "../../../../api/V2/queryHooks";
import api_keyAPI from "../../../../api/V2/7.APIKey/API_APIkey";
import { useAppSelector } from "../../../../modules";
import { FormLoader } from "../../Interactions/Loaders";
import NoData from "../../Interactions/notification/NoData";
import StickyHeader from "../../Interactions/StickyHeader/StickyHeader";
import APIkey from "./APIkey";
import { Container } from "./APIkeyStyled";

function APIKeys() {
  //State
  const currentGame = useAppSelector((state) => state.currentGame);
  //destruction State
  const { id: game_id } = currentGame;
  //Query
  const {
    isLoading,
    isFetching,
    data: APIkeyListData,
    refetch: refetchAPIKeyList,
  } = useAPIKeyQuery(game_id, { retry: 1 });
  //Function
  const onClick = async () => {
    await api_keyAPI.createAPIKey(game_id);
    await refetchAPIKeyList();
  };

  return (
    <Container>
      <StickyHeader title="API Key" onClick={onClick} />
      {isLoading || isFetching ? (
        <FormLoader />
      ) : APIkeyListData === undefined || APIkeyListData.length === 0 ? (
        <NoData content="발행한 API Key가 없습니다." />
      ) : (
        APIkeyListData.map((keyData) => (
          <APIkey key={keyData.key} data={keyData} game_id={currentGame.id} />
        ))
      )}
    </Container>
  );
}

export default APIKeys;

import axios from "axios";
import { base_url } from ".";
import { isChanged } from "../../v2/utils";
import client from "../V2/baseV2API";

const auth_base_URL = `${process.env.REACT_APP_JWT_BASE_URL}`;

export type AuthData = {
  accessToken: { expireAt: string; tokenString: string; username: string };
  refreshToken: { expireAt: string; tokenString: string; username: string };
};

const jwt = axios.create({ baseURL: `${base_url}` });
export default jwt;

/**
 * generate SessionStorage with pram
 *
 * key is "mayo"
 * @pram data : "value"
 */
export const setSessionToken = (data: AuthData) => {
  sessionStorage.setItem("mayo", JSON.stringify(data));
  jwt.defaults.headers.Authorization = `Bearer ${data.accessToken.tokenString}`;
};

export const getSessionToken = () => {
  return sessionStorage.getItem("mayo");
};

/**
 * Clear "mayo" SessionStorage
 */
export const clearSessionToken = () => {
  sessionStorage.removeItem("mayo");
  jwt.defaults.headers.Authorization = null;
};

/**
 * generate JWT with Basic Auth
 * @pram username : string means ID
 * @pram password : string
 */

export const jwtLogin = async (username: string, password: string) => {
  const response = await axios
    .post<AuthData>(
      `${base_url}/CMSUser/login`,
      {},
      {
        headers: {
          Authorization: `Basic ${btoa(`${username}:${password}`)}`,
        },
      }
    )
    .then((response) => {
      setSessionToken(response.data);
      client.defaults.headers = {
        Authorization: "Bearer " + response.data.accessToken.tokenString,
      };
      return response;
    });
  return response.data;
};

//Token Refreshing & Expired Token Handling Sections
type ErrorType = "Expired" | "No Token" | "Unexpected";

/**
 * Alert Token state, then clear Session & reload page
 *
 * @poram type : "Expired" | "No Token" | catch(error)
 */
const tokenErrorHandling = (type: ErrorType, err?: any) => {
  const baseActtion = () => {
    window.location.replace(window.location.origin);
    window.location.reload();
  };
  switch (type) {
    case "Expired":
      alert("Expired Token, Page refreshing ...");
      baseActtion();
      break;
    case "No Token":
      alert("No Token in you SessionStorage ...");
      baseActtion();
      break;
    default:
      alert(`Can't refresh JWT, maybe Invalid, Unexpected Error :${err}`);
      baseActtion();
      break;
  }
};

//refresh
/**
 * try refreshing "Access Token" with "Refresh Token"
 *
 * if Token data doesn't exist in user browser SessionStorage, clear SessionStorage and reload page
 */
export const jwtRefresh = async () => {
  const sessionToken = sessionStorage.getItem("mayo");
  if (!sessionToken) {
    tokenErrorHandling("No Token");
    return window.location.reload();
  }
  const TokenData: AuthData = JSON.parse(sessionToken);
  const isRefreshTokenExpired =
    new Date(TokenData.refreshToken.expireAt).getTime() + 3600000 * 9 <
    new Date().getTime();
  if (isRefreshTokenExpired) {
    return tokenErrorHandling("Expired");
  }

  return await jwt.post<AuthData>(`/CMSUser/refresh`, TokenData).then(
    async (response) => {
      const bearerToken: AuthData = await response.data;
      const AccessToken = bearerToken.accessToken.tokenString;
      setSessionToken(bearerToken);
      // response.config.headers["Authorization"] = `Bearer ${AccessToken}`;
      // // err.config.baseURL = undefined;
      client.defaults.headers = {
        Authorization: `Bearer ${AccessToken}`,
      };
      // console.log("JWT Refreshed");
      // response.config.baseURL = undefined;
      return response.data;
    },
    async (reject) => {
      clearSessionToken();
      window.location.replace(window.location.origin);
    }
  );
};

export const getInfoByJWT = () => {
  const sessionToken = sessionStorage.getItem("mayo");
  if (sessionToken) {
    const data: AuthData = JSON.parse(sessionToken);
    let response = jwt.get(`/CMSUser/getinfo`, {
      headers: {
        Authorization: `Bearer ${data.accessToken.tokenString}`,
      },
    });
    return response;
  } else {
    return tokenErrorHandling("No Token");
  }
};

/**
 * Logout JWT session
 *
 * Clear JWT data in user's Session Storage
 */
export const jwtLogout = () => {
  const sessionToken = sessionStorage.getItem("mayo");
  const data: AuthData = JSON.parse(sessionToken || "");
  jwt.post(
    `/CMSUser/logout`,
    {},
    {
      headers: {
        Authorization: `Bearer ${data.accessToken.tokenString}`,
      },
    }
  );
  clearSessionToken();
};

import { Reducer } from "redux";

export interface Modal {
  activate: boolean;
  modalType:
    | "unsaved"
    | "warning"
    | "no_banner_files"
    | "delete"
    | "not_created"
    | "delete_game";
  warningInstuction?: string;
  defaultAction?: () => void;
  deleteAction?: () => void;
}

const initialState: Modal = {
  activate: false,
  modalType: "unsaved",
  warningInstuction: undefined,
  defaultAction: () => {},
  deleteAction: () => {},
};

type ModalAction = ReturnType<
  | typeof setUnsavedModalOn
  | typeof setWarningModalOn
  | typeof setNoFilesModalOn
  | typeof setDeleteModalOn
  | typeof setDeleteGameModalOn
  | typeof setModalOff
>;

const setUnsavedModalOn = (defaultAction: Modal["defaultAction"]) => ({
  type: "setUnsavedModalOn" as const,
  defaultAction,
});

/**
 * 원하는 경고 Modal 내용을 띄웁니다.
 * @param instruction
 */
const setWarningModalOn = (instruction: Modal["warningInstuction"]) => ({
  type: "setWarningModalOn" as const,
  instruction,
});

const setNoFilesModalOn = () => ({
  type: "setNoFilesModalOn" as const,
});

const setDeleteModalOn = (
  deleteAction: Modal["defaultAction"],
  instruction?: string
) => ({
  type: "setDeleteModalOn" as const,
  deleteAction,
  instruction,
});

const setDeleteGameModalOn = (deleteAction: Modal["defaultAction"]) => ({
  type: "setDeleteGameModalOn" as const,
  deleteAction,
});

const setModalOff = () => ({
  type: "setModalOff" as const,
});

const modal: Reducer<Modal, ModalAction> = (state = initialState, action) => {
  switch (action.type) {
    case "setUnsavedModalOn":
      return {
        ...state,
        activate: true,
        modalType: "unsaved",
        defaultAction: action.defaultAction,
      };
    case "setWarningModalOn":
      return {
        ...state,
        activate: true,
        modalType: "warning",
        warningInstuction: action.instruction,
      };
    case "setDeleteModalOn":
      return {
        ...state,
        activate: true,
        modalType: "delete",
        warningInstuction: action.instruction,
        deleteAction: action.deleteAction,
      };
    case "setDeleteGameModalOn":
      return {
        ...state,
        activate: true,
        modalType: "delete_game",
        deleteAction: action.deleteAction,
      };
    case "setModalOff":
      return initialState;
    default:
      return state;
  }
};

export default modal;

export const modalActions = {
  setUnsavedModalOn,
  setWarningModalOn,
  setNoFilesModalOn,
  setDeleteModalOn,
  setDeleteGameModalOn,
  setModalOff,
};

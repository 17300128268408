import styled from "styled-components";

export const ModalMode = styled.div`
  position: absolute;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 10;
`;

export const ModalContainer = styled.div`
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  max-height: 220px;
  height: 220px;
  padding: 50px 75px;
  font-weight: 600;
  color: ${(props) => props.theme.modal.text};
  background-color: ${(props) => props.theme.modal.background};
  z-index: 11;
`;

export const Instruction = styled.div`
  display: flex;
  font-size: 1.7rem;
  line-height: 2rem;
  & svg {
    margin-right: 1rem;
    fill: ${(props) => props.theme.text.reversal};
  }
`;

export const ModalBtnsBox = styled.div`
  position: absolute;
  right: 75px;
  bottom: 50px;
  display: flex;
  justify-content: space-between;
  column-gap: 30px;
`;

export const ModalBtn = styled.button`
  min-width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 35px;
  font-family: "Noto Sans CJK KR";
  font-size: 1.5rem;
  font-weight: 600;
  color: ${(props) => props.theme.modal.text};
  border-width: 5px;
  border-style: solid;
  border-radius: 15px;
  border-color: ${(props) => props.theme.modal.buttonBorder};
  background-color: inherit;
  cursor: pointer;
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
  &.ignore {
    color: ${(props) => props.theme.modal.delete};
    border-color: ${(props) => props.theme.modal.delete};
  }
  &.delete {
    color: ${(props) => props.theme.modal.delete};
    border-color: ${(props) => props.theme.modal.delete};
  }
`;

export const ModalInput = styled.input`
  position: absolute;
  bottom: 50px;
  color: ${(props) => props.theme.modal.text};
  border-bottom: 4px solid ${(props) => props.theme.modal.text};
`;

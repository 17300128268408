import React from "react";
import {
  ICPText,
  Language,
} from "../../../../api/V2/3.CrossPromotion/API_CPText";
import { useAppDispatch, useAppSelector } from "../../../../modules";
import { CP_dataActions } from "../../../../modules/3.CrossPromotion/CPDatas";
import {
  LanguageLayout,
  LanguageInput,
  DeleteLangBtn,
  DefaultLanguage,
} from "./MayoInputStyled";

interface IMayoLanguageInput
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  defaultLanguage?: boolean;
  languages?: string[];
  onDelete?: React.MouseEventHandler<HTMLButtonElement>;
}

const MayoLanguageInput: React.FC<IMayoLanguageInput> = ({
  defaultLanguage,
  languages,
  ...props
}) => {
  //State
  const platform = useAppSelector((state) => state.platform);
  const CP_data = useAppSelector((state) => state.CP_datas[platform]);
  const dispatch = useAppDispatch();

  //destruction State
  const textDataOnLanguage = CP_data.cpTextDatas.find(
    (data) => data.language === props.lang
  ) as ICPText;
  const { setCP_textData } = CP_dataActions;

  //Function
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      currentTarget: { name, value, lang },
    } = event;
    const editedTextData: ICPText = {
      ...textDataOnLanguage,
      [name]: value,
      language: lang as Language,
    };
    dispatch(setCP_textData(editedTextData, platform));
  };

  return (
    <LanguageLayout>
      {defaultLanguage ? (
        <DefaultLanguage>en</DefaultLanguage>
      ) : (
        <select name={props.name} value={props.lang} onChange={props.onChange}>
          <option value={props.lang}>{props.lang}</option>
          {languages?.map((language) => (
            <option key={language} value={language}>
              {language}
            </option>
          ))}
        </select>
      )}
      <section>
        <LanguageInput
          className="title"
          type="text"
          lang={props.lang}
          name="title"
          value={textDataOnLanguage?.title}
          placeholder="Game Title"
          onChange={onChange}
          required
        />
        <LanguageInput
          className="description"
          type="text"
          lang={props.lang}
          name="script"
          value={textDataOnLanguage?.script}
          onChange={onChange}
          placeholder="introduce the features and fun elements of the game."
        />
      </section>
      {defaultLanguage ? null : (
        <DeleteLangBtn
          name={
            textDataOnLanguage.id === undefined
              ? undefined
              : textDataOnLanguage.id
          }
          onClick={props.onDelete}
        >
          ×
        </DeleteLangBtn>
      )}
    </LanguageLayout>
  );
};

export default MayoLanguageInput;

import { useAppDispatch, useAppSelector } from "../../../../modules";
import { modalActions } from "../../../../modules/modal";
import { ModalTerm } from "../Modal";
import {
  Instruction,
  ModalBtn,
  ModalBtnsBox,
  ModalContainer,
} from "../ModalStyled";
import { CautionIcon } from "../../SVGs";
import modalTerms from "../ModalTerms.json";
import { linebreak } from "./ModalBase";

const delete_term: ModalTerm = modalTerms.delete;

function Delete() {
  //State
  const modal = useAppSelector((state) => state.modal);
  const dispatch = useAppDispatch();
  //destruction State
  const { deleteAction } = modal;
  const { setModalOff } = modalActions;
  //Function
  const onClick = (type: "delete" | "cancel" | string) => {
    const clear = () => {
      deleteAction && deleteAction();
      dispatch(setModalOff());
    };
    switch (type) {
      case "delete":
        return clear();
      case "cancel": {
        return dispatch(setModalOff());
      }
      default:
        return console.error(
          `No such modal button type : ${type} / in "Delete" modal Error`
        );
    }
  };

  return (
    <ModalContainer>
      <Instruction>
        <CautionIcon />
        {linebreak(
          delete_term.instruction +
            (modal.warningInstuction === undefined
              ? ""
              : modal.warningInstuction)
        )}
      </Instruction>
      <ModalBtnsBox>
        {delete_term.buttons.map(({ name, type }) => (
          <ModalBtn
            key={type}
            className={`${name} ${type}`}
            onClick={() => onClick(type)}
          >
            {name || "undefined"}
          </ModalBtn>
        ))}
      </ModalBtnsBox>
    </ModalContainer>
  );
}

export default Delete;

import StickyPlatformHeader from "../../Interactions/StickyHeader/StickyPlatformHeader";
import SettingPropsForm from "./SettingPropsForm";
import { Container } from "./SettingPropsStyled";
import pageData from "./SettingPropsPageData.json";
import { useAppDispatch } from "../../../../modules";
import { modalActions } from "../../../../modules/modal";

export type SettingPropsPageData = {
  header: { title: string };
};
const {
  header: { title },
}: SettingPropsPageData = pageData;

function SettingProps() {
  //State
  const dispatch = useAppDispatch();
  //destruction State
  const { setWarningModalOn } = modalActions;
  //Functions
  const onPlusClick = () => {
    dispatch(setWarningModalOn("아직 구현되지 않은 기능입니다."));
  };
  return (
    <Container>
      <StickyPlatformHeader
        key={title}
        title={title}
        onPlusClick={onPlusClick}
      />
      <SettingPropsForm />
    </Container>
  );
}

export default SettingProps;

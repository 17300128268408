import { DefaultTheme } from "styled-components";

export const theme: DefaultTheme = {
  color: "rgba(0,0,0,1)",
  dimColor: "rgba(140,140,140,1)",
  changed: "rgba(255,137,0,1)",
  activce: "rgba(230,82,55,1)",
  inactive: "rgba(0,0,0,0.3)",
  hover: "rgba(255,137,0,1)",
  warn: "rgba(255,137,0,1)",
  border: {
    base: "rgba(0,0,0,1)",
    active: "rgba(70,200,60,1)",
    inactive: "rgba(235,60,60,1)",
    disabled: "rgba(200,60,50,1)",
  },
  backgroundColor: {
    base: "rgba(255,255,255,1)",
    reversal: "rgba(0,0,0,1)",
  },
  text: {
    normal: "rgba(0,0,0,1)",
    hover: "rgba(230,82,55,1)",
    highlight: "rgba(230,82,55,1)",
    reversal: "rgba(255,255,255,1)",
  },
  noti: {
    alert: "rgba(225,50,15,1)",
    caution: "rgba(255,137,0,1)",
    success: "rgba(12,207,143,1)",
  },
  modal: {
    text: "rgba(255,255,255,1)",
    background: "rgba(239,173,19,1)",
    buttonBorder: "rgba(255,255,255,1)",
    delete: "rgba(235,54,23,1)",
    ignore: "rgba(235,54,23,1)",
  },
  submitBtn: {
    safe: "rgba(12,207,143,1)",
    danger: "rgba(235,70,15,1)",
  },
  input: {
    valid: "rgba(0,0,0,1)",
    changed: "rgba(255, 137, 0, 1)",
    invalid: "rgba(255, 0, 0, 1)",
  },
  toggle: {
    egg: "rgba(255,255,255,1)",
    checked: "rgba(30, 185, 161, 1)",
    unchecked: "rgba(0,0,0,0.3)",
  },
};

import React from "react";
import { Description, Label, TextInput } from "./MayoInputStyled";

interface IMayoTextInput extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  beforeValue?: string;
  description?: string;
  invalidNoti?: string;
}

/** 기본 Input 값을 계승하여 Label 과  description을 추가한
 *  *  Text type INPUT 컴포넌트
 * * 추가된 Params
 * @param {string} label string
 * @param {string} beforeValue string
 * @param {string} description string | undefine
 */
const MayoInput: React.FC<IMayoTextInput> = ({
  beforeValue = "",
  invalidNoti = "",
  ...props
}) => {
  return (
    <Label
      id={props.id}
      className={beforeValue !== props.value ? "changed" : ""}
    >
      {props.label}
      {props.description ? (
        <Description>
          {props.description.split("\n").map((des) => (
            <span>{des}</span>
          ))}
        </Description>
      ) : null}
      <TextInput
        className="text-input"
        type={props.type || "text"}
        {...props}
      />
      <span>{invalidNoti}</span>
    </Label>
  );
};

export default MayoInput;

import React from "react";
import { Detail, Sum } from "../Table/TableStyled";
import Table from "../Table/Table";
import CountryTable from "../Table/CountryTable";

interface IPagenation extends React.DetailsHTMLAttributes<HTMLDetailsElement> {
  board_id?: string;
  block: boolean;
}

const rankingBy: { name: string; type: "entire" | "country" | "honor" }[] = [
  { name: "전체 순위", type: "entire" },
  { name: "국가 순위", type: "country" },
  { name: "명예 전당", type: "honor" },
];

const BoardsByType: React.FC<IPagenation> = ({ board_id, block, ...props }) => {
  return (
    <>
      {rankingBy.map((standard) => {
        const { name, type } = standard;
        switch (standard.type) {
          case "country":
            return (
              <Detail key={name} block={block}>
                <Sum>{name}</Sum>
                {!board_id ? null : <CountryTable board_id={board_id} />}
              </Detail>
            );
          default:
            return (
              <Detail key={name} block={block}>
                <Sum>{name}</Sum>
                {!board_id ? null : (
                  <Table key={name} board_id={board_id} type={type} />
                )}
              </Detail>
            );
        }
      })}
    </>
  );
};

export default BoardsByType;

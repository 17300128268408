import { useEffect } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../../modules";
import { submitBtnActions, SubmitButton } from "../../../../modules/submitBtn";
import { FailedIcon, SaveIcon } from "../../SVGs";
import comments from "./NotiComments.json";

export const Noti = styled.div<{ type: SubmitButton["notiType"] }>`
  position: absolute;
  display: flex;
  align-items: center;
  width: fit-content;
  height: fit-content;
  bottom: -2rem;
  font-size: 1.5rem;
  color: ${(props) => {
    switch (props.type) {
      case "save":
        return props.theme.submitBtn.safe;
      case "copy":
        return props.theme.submitBtn.safe;
      case "fail":
        return props.theme.submitBtn.danger;
      case "error":
        return "black";
    }
  }};
  white-space: nowrap;
  & svg {
    fill: ${(props) => {
      switch (props.type) {
        case "save":
          return props.theme.submitBtn.safe;
        case "copy":
          return props.theme.submitBtn.safe;
        case "fail":
          return props.theme.submitBtn.danger;
        default:
          return "black";
      }
    }};
  }
`;

interface INotificationProps {
  type: SubmitButton["notiType"];
}

const Notification: React.FC<INotificationProps> = ({ type }) => {
  //State
  const submitBtn = useAppSelector((state) => state.submitBtn);
  const dispatch = useAppDispatch();
  //destruction State
  const { clearSubmitBtnNoti } = submitBtnActions;

  const icon = () => {
    switch (type) {
      case "save":
        return <SaveIcon />;
      case "copy":
        return <SaveIcon />;
      case "fail":
        return <FailedIcon />;
      case "error":
        return <FailedIcon />;
    }
  };

  const comment = () => {
    if (!!submitBtn.error) return submitBtn.error;
    switch (type) {
      case "save":
        return comments.save;
      case "copy":
        return comments.copy;
      case "fail":
        return comments.fail;
      default:
        return submitBtn.error;
    }
  };

  useEffect(() => {
    setTimeout(() => dispatch(clearSubmitBtnNoti()), 5000);
  }, []);

  return (
    <Noti type={type}>
      <>{icon()}</>
      <>{comment()}</>
    </Noti>
  );
};

export default Notification;

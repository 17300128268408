import { useQuery } from "react-query";
import { getRanking } from "../../../../../../api/V2/5.Rankboard/API_Rank";
import querykeys from "../../../../../../api/V2/query";
import { FormLoader } from "../../../../Interactions/Loaders";
import NoData from "../../../../Interactions/notification/NoData";
import DataRow from "../dataComponents/TableRow";

interface ICountryTableRowProps {
  page: number;
  board_id: string;
  country?: number;
}

function Country({ board_id, page, country }: ICountryTableRowProps) {
  const { isLoading, isFetching, isFetched, data } = useQuery(
    [querykeys.rankboard, board_id, "country", page],
    () =>
      getRanking({
        board_id,
        from: (page - 1) * 10,
        count: 10,
        country,
      }).then((response) => response.data),
    { enabled: board_id !== undefined, retry: 1 }
  );

  return (
    <>
      {isLoading || isFetching ? (
        <FormLoader />
      ) : isFetched ? (
        data?.length !== 0 ? (
          data?.map((data, index) => (
            <DataRow
              key={board_id + index + page}
              data={data}
              board_id={board_id}
              index={index}
              page={page}
              type="country"
            />
          ))
        ) : (
          <NoData key={"No data"} content={"보드가 텅 비었어요."} />
        )
      ) : (
        <NoData
          key={"can't load data"}
          content={"데이터를 불러오지 못했습니다."}
        />
      )}
    </>
  );
}

export default Country;

import TBD from "../../TBD";

function LetterBox() {
  return (
    <div>
      <TBD />
    </div>
  );
}

export default LetterBox;

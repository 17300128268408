import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../modules";
import {
  CPLinkQueue,
  CP_linkActions,
} from "../../../../../modules/3.CrossPromotion/CPLinkQueue";
import { modalActions } from "../../../../../modules/modal";

import {
  Description,
  Label,
} from "../../../Interactions/Inputs/MayoInputStyled";
import { bannerExposeList } from "../CrossPromotionPageData.json";
import { GridTemplate } from "../CrossPromotionStyled";
import ExposeGameIcon from "./ExposeGameIcon";

const { label, description } = bannerExposeList;

function BannerExposeListSet() {
  //Local State
  const [isChanged, setIsChanged] = useState(false);
  //State
  const gameList = useAppSelector((state) => state.gameList);
  const game_id = useAppSelector((state) => state.currentGame.id);
  const platform = useAppSelector((state) => state.platform);
  const CP_data = useAppSelector((state) => state.CP_datas[platform]);
  const CP_linkQueues = useAppSelector((state) => state.CP_linkQueues);
  const dispatch = useAppDispatch();

  //destruction State
  const { id: cp_id } = CP_data;
  const { setLinkQueue } = CP_linkActions;
  const { setWarningModalOn } = modalActions;

  //Function
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      currentTarget: { name: target_cp_id, checked, labels, className },
    } = event;
    if (!labels) return;
    if (!cp_id)
      return dispatch(
        setWarningModalOn(
          "아직 이 게임의 Cross Promotion를 등록하지 않아 다른 게임의 Cross Promotion을 활성화 할 수 없습니다."
        )
      );
    if (!target_cp_id)
      return dispatch(
        setWarningModalOn(
          "해당 게임은 Cross Promotion 배너가 지정되지 않은 게임입니다."
        )
      );
    if (className.includes("disabled"))
      return dispatch(
        setWarningModalOn("해당 게임은 Cross Promotion 비활성화 상태입니다.")
      );
    const newQue: CPLinkQueue = {
      cp_id,
      link_id: target_cp_id,
    };
    const isExisting =
      CP_linkQueues.current.findIndex(
        (data) => data.link_id === target_cp_id
      ) !== -1;
    // request Que 로 저장.
    if (checked) {
      if (!isExisting)
        dispatch(setLinkQueue([...CP_linkQueues.current, newQue].sort()));
    } else if (!checked) {
      dispatch(
        setLinkQueue(
          CP_linkQueues.current
            .filter((data) => data.link_id !== target_cp_id)
            .sort()
        )
      );
    }
  };

  //useEffect
  useEffect(() => {
    const current = CP_linkQueues.current.map((data) => data.link_id).sort();
    const before = CP_linkQueues.default.map((data) => data.link_id).sort();
    console.log("LINKS :", current, before);
    const result = JSON.stringify(current) !== JSON.stringify(before);
    setIsChanged(result);
  }, [CP_linkQueues]);

  return (
    <>
      <Label id="CP_expose_list" className={isChanged ? "changed" : ""}>
        {label}
        <Description>{description}</Description>
      </Label>
      <GridTemplate>
        {gameList
          ?.filter((game) => game.id !== game_id)
          .map((game) => {
            const data = game.cp_datas.find(
              (data) => data.platform.toLowerCase() === platform
            );
            const is_public = data?.is_public;
            const target_cp_id = data?.id;
            return (
              <ExposeGameIcon
                key={game.id}
                game_id={game.id}
                game_title={game.title}
                name={target_cp_id}
                onChange={onChange}
                // checked={CP_linkQueues.current.some(
                //   ({ link_id }) => link_id === target_cp_id
                // )}
                disabled={!is_public}
              />
            );
          })}
      </GridTemplate>
    </>
  );
}

export default BannerExposeListSet;

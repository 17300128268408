import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 205px;
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const Button = styled.button<{ isValid: boolean }>`
  width: 100%;
  height: 100%;
  border: 4px solid black;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 800;
  font-family: "Noto Sans CJK KR";
  background-color: inherit;
  opacity: ${(props) => (props.isValid ? 1 : 0.3)};
  cursor: ${(props) => (props.isValid ? "pointer" : "unset")};
  pointer-events: ${(props) => (props.isValid ? "auto" : "none")};
`;

import styled from "styled-components";
import { IGameBaseData } from "../../../../api/V2/1.Game/API_Game";
import { useGameIconQuery } from "../../../../api/V2/queryHooks";
import { GameIconLoader } from "../../Interactions/Loaders";
import defaultImage from "../../../../assets/sd.png";

interface IGameIcon {
  game_id: IGameBaseData["id"];
  game_title: IGameBaseData["title"];
}

const IconImg = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 10px;
`;

function GameIcon({ game_id, game_title }: IGameIcon) {
  //Query
  const { isLoading, data: iconData } = useGameIconQuery(game_id, game_title, {
    refetchOnMount: false,
    retry: false,
    cacheTime: 1000 * 60 * 60,
  });

  return isLoading ? (
    <GameIconLoader />
  ) : !!iconData ? (
    <IconImg src={iconData.url} alt="" />
  ) : (
    <IconImg src={defaultImage} />
  );
}

export default GameIcon;

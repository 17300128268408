import { Routes, Route } from "react-router-dom";
import { gameRoutes, mainRoutes } from "./routes";
import MainDashboard from "./v2/components/DisplayMain/MainDashBoard";
import Settings from "./v2/components/DisplayMain/GlobalSettings";
import CreateGame from "./v2/components/DisplayGame/1.Game/CreateGame";
import EditGame from "./v2/components/DisplayGame/1.Game/EditGame";
import Dashboard from "./v2/components/DisplayGame/0.Dashboard/Dashboard";
import SettingProps from "./v2/components/DisplayGame/2.SettingProps/SettingProps";
import CrossPromotion from "./v2/components/DisplayGame/3.CrossPromotion/CrossPromotion";
import Rankboard from "./v2/components/DisplayGame/5.Rankboard/Rankboard";
import LetterBox from "./v2/components/DisplayGame/6.LetterBox/LetterBox";
import APIKeys from "./v2/components/DisplayGame/7.APIkey/APIKeys";
import User from "./v2/components/DisplayGame/4.User/User";

function Router() {
  const { home, settings, create, game } = mainRoutes;
  const {
    dashboard,
    settingProperties,
    crossPromotion,
    user,
    rankboard,
    letterBox,
    apiKey,
  } = gameRoutes;

  return (
    <Routes>
      <Route path={home} element={<MainDashboard />} />
      <Route path={settings} element={<Settings />} />
      <Route path={create} element={<CreateGame />} />
      <Route path={game}>
        <Route path={dashboard} element={<Dashboard />} />
        <Route path={settingProperties} element={<SettingProps />} />
        <Route path={crossPromotion} element={<CrossPromotion />} />
        <Route path={user} element={<User />} />
        <Route path={rankboard} element={<Rankboard />} />
        <Route path={letterBox} element={<LetterBox />} />
        <Route path={apiKey} element={<APIKeys />} />
        {/*이 아래 Route는 Game Navibar/Header 에서 gameRoute 
        와 공유되지 않고 mainRoute의 layout 사용응 위해 독립적으로 사용 */}
        <Route path={"setting"} element={<EditGame />} />
      </Route>
    </Routes>
  );
}

export default Router;

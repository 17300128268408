import client from "../baseV2API";

export interface APIKeyData {
  enable: boolean;
  key: string;
  id: string;
  game_id: string;
  create_at: string;
  update_at: string;
}

const createAPIKey = (game_id: string) => {
  const formdata = new FormData();
  formdata.append("game_id", game_id);
  return client.post<APIKeyData>("/apikey/create", formdata);
};

const getAPIKey = (APIKey_id: string) => {
  return client.get<APIKeyData>("/apikey", { params: { id: APIKey_id } });
};

const updateAPIKey = (APIKey_id: string, enable: boolean) => {
  const formdata = new FormData();
  formdata.append("id", APIKey_id);
  formdata.append("enable", enable.toString());
  return client.put<APIKeyData>("/apikey/enable", formdata);
};

const deleteAPIKey = (APIKey_id: string) => {
  const formdata = new FormData();
  formdata.append("id", APIKey_id);
  return client.delete("/apikey", { data: formdata });
};

const getAPIKeyList = (game_id: string) => {
  return client.get<APIKeyData[]>("/apikey/list", {
    params: { game_id },
  });
};

const api_keyAPI = {
  createAPIKey,
  updateAPIKey,
  getAPIKey,
  getAPIKeyList,
  deleteAPIKey,
};

export default api_keyAPI;

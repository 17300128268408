import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const Form = styled.form`
  row-gap: 100px;
`;

import { useState } from "react";
import { useGameIconQuery } from "../../../api/V2/queryHooks";
import { useAppSelector } from "../../../modules";
import { IPlatform } from "../../../modules/2.Platform/platform";
import SubbmitButton from "../Interactions/submitButton/SubmitBtn";
import { Noti } from "../Interactions/notification/Notifcations";
import defaultImg from "../../../assets/sd.png";
import {
  AppStoreIcon,
  CopyClipboardIcon,
  PlaystoreIcon,
  SaveIcon,
} from "../SVGs";
import {
  Container,
  GameIcon,
  Info,
  Title,
  StoreSection,
  Version,
  IdSection,
  ID,
  HUD,
} from "./GameHeaderStyled";

function GameHeader() {
  //State
  const currentGame = useAppSelector((state) => state.currentGame);
  //destruction State
  const { title: game_title, id: game_id, platform_datas } = currentGame;

  //LocalState
  const [showComment, setShowComment] = useState(false);
  //Query
  const { data: iconData } = useGameIconQuery(game_id, game_title, {
    enabled: !!game_id,
  });

  const getVersion = (platform: IPlatform) => {
    if (!platform_datas) return "0.0.0.0";
    const targetPlatform = platform_datas[platform];
    if (!targetPlatform) return `Unreleased`;
    const { major, minor, patch, reversion } = targetPlatform;
    const trimedPatch = patch?.replace(/\s/g, "");
    const version = [major, minor, trimedPatch, reversion].join(".");
    return version;
  };

  const onClick = () => {
    setShowComment(true);
    navigator.clipboard.writeText(game_id);
    setTimeout(() => setShowComment(false), 5000);
  };

  return (
    <Container>
      <HUD>
        <GameIcon src={iconData?.url || defaultImg} />
        <Info>
          <Title>{game_title}</Title>
          <StoreSection>
            <Version>
              <AppStoreIcon />
              {getVersion("ios")}
            </Version>
            <Version>
              <PlaystoreIcon />
              {getVersion("aos")}
            </Version>
          </StoreSection>
          <IdSection>
            Game ID
            <ID>
              {game_id}
              {showComment ? (
                <Noti type="copy">
                  <SaveIcon />
                  클림보드에 복사되었습니다.
                </Noti>
              ) : null}
            </ID>
            <CopyClipboardIcon onClick={onClick} />
          </IdSection>
        </Info>
      </HUD>
      <SubbmitButton />
    </Container>
  );
}

export default GameHeader;

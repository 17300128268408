import { useState } from "react";
import Country from "./TableTypes/Country";
import Hall from "./TableTypes/Hall";
import Whole from "./TableTypes/Whole";
import {
  CountryCode,
  Data,
  Nickname,
  PageNumber,
  PageSelection,
  Point,
  Ranking,
  TableColumn,
  TableContainer,
} from "./TableStyled";

interface ITableProps {
  board_id: string;
  type: "entire" | "country" | "honor";
  country?: number;
}

function Table({ board_id, type, country }: ITableProps) {
  //State
  const [currentPage, setCurrnetPage] = useState(1);

  //Function
  const onClick = (index: number) => {
    setCurrnetPage(index + 1);
  };

  return (
    <>
      <TableContainer>
        <TableColumn>
          <Ranking>순위</Ranking>
          <Nickname>닉네임</Nickname>
          <CountryCode>국가코드</CountryCode>
          <Point>점수</Point>
          <Data>데이터</Data>
          <div></div>
        </TableColumn>
        {type === "entire" ? (
          <Whole key="entire" page={currentPage} board_id={board_id} />
        ) : type === "country" ? (
          <Country
            key="country"
            page={currentPage}
            board_id={board_id}
            country={country}
          />
        ) : (
          <Hall key="hall" page={currentPage} board_id={board_id} />
        )}
      </TableContainer>
      <PageSelection>
        {[1, 2, 3, 4, 5].map((page, index) => (
          <PageNumber
            key={page + " page"}
            currentPage={currentPage === page}
            onClick={() => onClick(index)}
          >
            {page}
          </PageNumber>
        ))}
      </PageSelection>
    </>
  );
}

export default Table;

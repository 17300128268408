import React, { useEffect } from "react";
import { useNavigate } from "react-router";

import { useQueryClient } from "react-query";
import { IconData } from "../../../../api/V2/queryHooks";
import querykeys from "../../../../api/V2/query";

import gameAPI, {
  FullDataKey,
  IGameFullData,
} from "../../../../api/V2/1.Game/API_Game";
import iconAPI from "../../../../api/V2/1.Game/API_GameIcon";
import platform_dataAPI, {
  IPlatformData,
  PlatformDataKey,
} from "../../../../api/V2/1.Game/API_PlatformDatas";

import { useAppDispatch, useAppSelector } from "../../../../modules";
import { modalActions } from "../../../../modules/modal";
import {
  platformActions,
  IPlatform,
} from "../../../../modules/2.Platform/platform";
import {
  CurrentGameKey,
  ICurrentGame,
  currentGameActions,
} from "../../../../modules/1.Game/currentGame";
import { submitBtnActions } from "../../../../modules/submitBtn";

import { DeleteButton, Form } from "./Gamestyled";
import PlatformInputField from "./PlatformInputField";
import MayoInput from "../../Interactions/Inputs/MayoTextInput";
import MayoIconInput from "../../Interactions/Inputs/MayoIconInput";
import NoGameData from "../../Interactions/notification/NoGameData";
import { consoleLog, isThisChanged } from "../../../utils";
import { formTerms } from "./GamePageData.json";

function EditGame() {
  //State
  const currentGame = useAppSelector((state) => state.currentGame);
  const dispatch = useAppDispatch();

  //destruction State
  const {
    id: game_id,
    icon: currentIcon,
    title: game_title,
    package_name,
    url_customer,
    url_gamepage,
    platform_datas: { ios, aos },
  } = currentGame;
  const {
    setCurrentGame,
    setCurrentGameIcon,
    setCurrentGameIos,
    setCurrentGameAos,
  } = currentGameActions;
  const { osStandard } = platformActions;
  const { setDeleteModalOn, setDeleteGameModalOn, setWarningModalOn } =
    modalActions;
  const { giveSubmitBtnNoti, setSubmitBtnLoading } = submitBtnActions;

  //Queried
  const queryClient = useQueryClient();
  const fetchedGameData = queryClient.getQueryData<IGameFullData>([
    querykeys.current_game,
    game_id,
  ]);
  const fetchedIcon = queryClient.getQueryData<IconData>([
    querykeys.game_icon,
    game_id,
  ]);

  const fetchedPlatformDatas = fetchedGameData?.platform_datas;

  //Page
  const navigate = useNavigate();

  //Lifecycle
  useEffect(() => {
    if (fetchedIcon?.file) dispatch(setCurrentGameIcon(fetchedIcon.file));
  }, [fetchedIcon]);

  //Function
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      currentTarget: { name, value },
    } = event;
    dispatch(
      setCurrentGame(
        name as CurrentGameKey,
        value as ICurrentGame[CurrentGameKey]
      )
    );
  };

  const onIconChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      currentTarget: { files },
    } = event;
    if (!files) return;
    const file = files[0];
    dispatch(setCurrentGameIcon(file));
  };

  const onToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      currentTarget: { id: platform, checked },
    } = event;
    const osFetchedData = fetchedPlatformDatas?.find((data) =>
      osStandard(data, platform as IPlatform)
    );
    const onNoff = checked
      ? { ...osFetchedData, platform: platform as IPlatform }
      : undefined;

    if (platform === "ios")
      !!osFetchedData
        ? dispatch(
            setDeleteModalOn(() => {
              platform_dataAPI.deletePlatform(ios?.id).then(() => {
                dispatch(setCurrentGameIos(undefined));
              });
            }, "\n ‼ 즉시 iOS 플랫폼 데이터가 제거됩니다.")
          )
        : dispatch(setCurrentGameIos(onNoff));
    if (platform === "aos")
      !!osFetchedData
        ? dispatch(
            setDeleteModalOn(() => {
              platform_dataAPI.deletePlatform(aos?.id).then(() => {
                dispatch(setCurrentGameAos(onNoff));
              });
            }, "\n ‼ 즉시 AOS 플랫폼 데이터가 제거됩니다.")
          )
        : dispatch(setCurrentGameAos(onNoff));
  };

  const onVersionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      currentTarget: { id: platform, value },
    } = event;
    const target = currentGame.platform_datas[platform as IPlatform];
    const [major, minor, patch, reversion] = value.split(".");
    const edited: IPlatformData = {
      ...target,
      major,
      minor,
      patch,
      reversion,
      version_str: value,
    };
    if (platform === "ios") dispatch(setCurrentGameIos(edited));
    if (platform === "aos") dispatch(setCurrentGameAos(edited));
  };

  const onFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      currentTarget: { id: platform, name, value },
    } = event;
    const target = currentGame.platform_datas[platform as IPlatform];
    const edited: IPlatformData = {
      ...target,
      [name as PlatformDataKey]: value,
    };
    if (platform === "ios") dispatch(setCurrentGameIos(edited));
    if (platform === "aos") dispatch(setCurrentGameAos(edited));
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(setSubmitBtnLoading(true));
    if (!!ios || !!aos) {
      try {
        //게임 아이콘이 바뀐 상태 + 게임 아이콘이 없으면 안됨.
        if (currentIcon?.size !== fetchedIcon?.file.size && !!currentIcon) {
          await iconAPI
            .updateGameIcon(game_id, currentIcon)
            .then(() =>
              queryClient.refetchQueries([querykeys.game_icon, game_id])
            )
            .catch((error) => {
              dispatch(
                giveSubmitBtnNoti(
                  "fail",
                  "게임 아이콘 업데이트에 실패했습니다."
                )
              );
              throw new Error(error);
            });
        }
        // 기본 Game info 변경된 사항 있을시 업데이트
        if (
          isThisChanged("title") ||
          isThisChanged("url_customer") ||
          isThisChanged("url_gamepage") ||
          isThisChanged("package_name")
        ) {
          await gameAPI
            .updateGame({
              id: game_id,
              title: game_title,
              package_name,
              url_customer,
              url_gamepage,
            })
            .catch((error) => {
              dispatch(
                giveSubmitBtnNoti("fail", "게임 정보 업데이트에 실패했습니다.")
              );
              throw new Error(error);
            });
        }
        // 'ios'변경 사항이 있을 시 업데이트 / 업로드
        if (isThisChanged("ios") && !!ios) {
          if (!!ios.id) {
            await platform_dataAPI.updatePlatform(ios).catch((error) => {
              dispatch(
                giveSubmitBtnNoti("fail", "iOS 정보 업로드에 실패했습니다.")
              );
              throw new Error(error);
            });
          } else {
            await platform_dataAPI
              .uploadPlatform(ios, game_id)
              .catch((error) => {
                dispatch(
                  giveSubmitBtnNoti("fail", "iOS 정보 업로드에 실패했습니다.")
                );
                throw new Error(error);
              });
          }
        }
        // 'aos' 작성시 업데이트 혹은 업로드
        if (isThisChanged("aos") && !!aos) {
          if (!!aos.id) {
            await platform_dataAPI.updatePlatform(aos).catch((error) => {
              dispatch(
                giveSubmitBtnNoti("fail", "AOS 정보 업로드에 실패했습니다.")
              );
              throw new Error(error);
            });
          } else {
            await platform_dataAPI
              .uploadPlatform(aos, game_id)
              .catch((error) => {
                dispatch(
                  giveSubmitBtnNoti("fail", "AOS 정보 업로드에 실패했습니다.")
                );
                throw new Error(error);
              });
          }
        }
        await queryClient.refetchQueries([querykeys.game_list]);
        await queryClient.refetchQueries([querykeys.current_game, game_id]);
        await queryClient.refetchQueries([querykeys.game_icon, game_id]);
        return dispatch(giveSubmitBtnNoti("save"));
      } catch (error) {
        consoleLog("Update Game Failed", error);
      } finally {
        dispatch(setSubmitBtnLoading(false));
      }
    } else {
      dispatch(setSubmitBtnLoading(false));
      dispatch(
        setWarningModalOn(
          "출시 스토어는  iOS, AOS 중 한 곳은 필수로 활성화해야 합니다."
        )
      );
    }
  };

  const onDeleteClick = () => {
    const deletion = () =>
      gameAPI
        .deleteGame(currentGame.id)
        .then(() => navigate("/"))
        .catch((error) =>
          dispatch(setWarningModalOn("삭제가 되지 않았습니다."))
        );
    dispatch(setDeleteGameModalOn(deletion));
  };

  return (
    <>
      {!!fetchedPlatformDatas ? (
        <div>
          <Form id="setting-game-form" onSubmit={onSubmit}>
            {formTerms.map(
              ({ type, label, name, platform, placeholder, fields }) => {
                switch (type) {
                  case "file":
                    return (
                      <MayoIconInput
                        key={name}
                        id={name}
                        label={label}
                        name={name}
                        beforefile={fetchedIcon?.file}
                        currentfile={currentIcon}
                        onChange={onIconChange}
                      />
                    );
                  case "text":
                    return (
                      <MayoInput
                        key={name}
                        id={name}
                        label={label}
                        name={name}
                        placeholder={placeholder}
                        value={currentGame[name as CurrentGameKey] as string}
                        beforeValue={
                          fetchedGameData[name as FullDataKey] as string
                        }
                        onChange={onChange}
                        required
                      />
                    );
                  case "url":
                    return (
                      <MayoInput
                        key={name}
                        id={name}
                        label={label}
                        name={name}
                        type="url"
                        invalidNoti="URL 형식으로 입력해주세요. Ex: http://~"
                        placeholder={placeholder}
                        value={currentGame[name as CurrentGameKey] as string}
                        beforeValue={
                          fetchedGameData[name as FullDataKey] as string
                        }
                        onChange={onChange}
                        required
                      />
                    );
                  case "field":
                    return (
                      <PlatformInputField
                        //custom props
                        label={label}
                        fields={fields}
                        values={
                          currentGame.platform_datas[platform as IPlatform]
                        }
                        beforeValues={fetchedPlatformDatas.find((data) =>
                          osStandard(data, platform as IPlatform)
                        )}
                        onVersionChange={onVersionChange}
                        onFieldChange={onFieldChange}
                        //attr
                        key={platform}
                        id={platform}
                        checked={
                          !!currentGame.platform_datas[platform as IPlatform]
                        }
                        defaultChecked={
                          !!fetchedPlatformDatas?.find((data) =>
                            osStandard(data, platform as IPlatform)
                          )
                        }
                        onToggle={onToggle}
                      />
                    );
                  default:
                    return null;
                }
              }
            )}
          </Form>
          <DeleteButton onClick={onDeleteClick}>게임 삭제하기</DeleteButton>
        </div>
      ) : (
        <NoGameData content="게임 데이터를 불러오지 못했습니다." />
      )}
    </>
  );
}

export default EditGame;

import styled from "styled-components";
import { SomethingIcon } from "../../SVGs";

export const EmptyBox = styled.div`
  position: relative;
  left: -20px;
  width: 100%;
  height: 100%;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: 600;
  & svg {
    margin-bottom: 60px;
    width: 120px;
  }
`;

interface INoData {
  content: string;
}

const NoData: React.FC<INoData> = ({ content }: INoData) => {
  return (
    <EmptyBox>
      <SomethingIcon />
      {content}
    </EmptyBox>
  );
};

export default NoData;

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getServerVersion } from "./api/rest";
import { setClientVersion, setServerVersion } from "./modules/version";
import { jwtLogout } from "./api/rest/jwt";
import { AppContainer, AppMain, AppNavi, Display } from "./style/AppLayout";
import Router from "./Router";
import MenuBar from "./v2/components/Menubar/Menubar";
import Header from "./v2/components/Header/Header";
import Modal from "./v2/components/Modal/Modal";
import { isChanged } from "./v2/utils";
import { useAppSelector } from "./modules";
import AdminPassword from "./AdminPassword";
import { useNavigate } from "react-router";

function App() {
  //State
  const [isLogedIn, setIsLogedIn] = useState(false);
  const isModal = useAppSelector((state) => state.modal.activate);
  const dispatch = useDispatch();

  //page
  const navigate = useNavigate();

  //서버 버전
  useEffect(() => {
    dispatch(setClientVersion(process.env.REACT_APP_VERSION_CODE || ""));
    getServerVersion().then((response) => {
      console.log(response.data.version.version);
      dispatch(setServerVersion(response.data.version.version));
    });
  }, []);

  //나가기 전 클리어.
  useEffect(() => {
    window.addEventListener("beforeunload", (event) => {
      navigate("/");
      jwtLogout();
      if (isChanged()) {
        event.returnValue = "세션이 종료됩니다.";
      }
    });
    return () => {
      setIsLogedIn(false);
    };
  }, []);

  return (
    <AppContainer>
      {isLogedIn ? (
        <>
          <AppNavi id="naviSection" block={isModal}>
            <MenuBar />
          </AppNavi>
          <AppMain id="main">
            <Modal />
            <Header />
            <Display id="display">
              <Router />
            </Display>
          </AppMain>
        </>
      ) : (
        <AdminPassword set={setIsLogedIn} />
      )}
    </AppContainer>
  );
}

export default App;

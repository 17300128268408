import React, { useEffect, useState } from "react";
import BannerPhrases from "./components/BannerPhrases";
import BannerActiveSet from "./components/BannerActiveSet";
import BannerExposeListSet from "./components/BannerExposeListSet";

import { useQueryClient } from "react-query";
import querykeys from "../../../../api/V2/query";

import cp_dataAPI, {
  ICP_data,
} from "../../../../api/V2/3.CrossPromotion/API_CP";
import cp_resourceAPI, {
  ICPResource,
} from "../../../../api/V2/3.CrossPromotion/API_CPresource";
import cp_textAPI, {
  ICPText,
} from "../../../../api/V2/3.CrossPromotion/API_CPText";
import cp_linkAPI from "../../../../api/V2/3.CrossPromotion/API_CPLink";

import { useAppDispatch, useAppSelector } from "../../../../modules";
import { CP_resourceActions } from "../../../../modules/3.CrossPromotion/CPResources";
import { submitBtnActions } from "../../../../modules/submitBtn";

import { consoleLog } from "../../../utils";
import { Form } from "./CrossPromotionStyled";
import { AxiosResponse } from "axios";
import { CP_dataActions } from "../../../../modules/3.CrossPromotion/CPDatas";
import { CP_linkActions } from "../../../../modules/3.CrossPromotion/CPLinkQueue";
import BannerMedias from "./components/BannerMedias";
import { FormLoader } from "../../Interactions/Loaders";
import { useCP_dataQuery } from "../../../../api/V2/queryHooks";
import BannerShowForce from "./components/BannerShowForce";

function CPForm() {
  //State
  const currentGame = useAppSelector((state) => state.currentGame);
  const platform = useAppSelector((state) => state.platform);
  const currentGame_cp_data = currentGame.cp_datas[platform];
  const CP_data = useAppSelector((state) => state.CP_datas[platform]);
  const CP_resources = useAppSelector((state) => state.CP_resources);
  const CP_linkQueues = useAppSelector((state) => state.CP_linkQueues);
  const submitBtn = useAppSelector((state) => state.submitBtn);
  const dispatch = useAppDispatch();

  //destruction State
  // const { initCP_data } = CP_dataActions;
  const { initCP_data, setCP_textData } = CP_dataActions;
  const { setCP_blobName } = CP_resourceActions;
  const { addDefaultLink, deleteDefaultLink } = CP_linkActions;
  const { setSubmitBtnLoading, giveSubmitBtnNoti } = submitBtnActions;

  //Query
  const queryClient = useQueryClient();
  const fetchedCP_data = queryClient.getQueryData<ICP_data>([
    querykeys.cross_promotion.data,
    currentGame_cp_data?.id,
  ]);
  const {
    isLoading: CP_dataLoading,
    isFetching: CP_dataFetching,
    refetch,
  } = useCP_dataQuery(
    currentGame_cp_data?.id || CP_data.id,
    platform,
    // OS에 맞는 쿼리 cp_id 가 없으면 안함.
    {
      enabled: !!currentGame_cp_data?.id,
    }
  );

  const isLoading = CP_dataLoading || submitBtn.isLoading;
  const isFetching = CP_dataFetching;

  const CP_resource = document
    .getElementById("CP_resource")
    ?.classList.contains("changed");
  const CP_text_data = document
    .getElementById("CP_text_data")
    ?.classList.contains("changed");
  const CP_expose_list = document
    .getElementById("CP_expose_list")
    ?.classList.contains("changed");

  //Selectors
  /////////////////////////////////////////////////////////////////////
  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    //버튼 막기
    dispatch(setSubmitBtnLoading(true));
    const submitResource = async (cp_id: string) => {
      if (!!CP_resource)
        try {
          //현재파일
          const image = CP_resources.image.file;
          const video = CP_resources.video.file;
          if (!image) throw new Error("업로드할 이미지 파일이 없습니다.");
          if (!video) throw new Error("업로드할 비디오 파일이 없습니다.");

          const existImageId = fetchedCP_data?.cpResourceDatas.find(
            (res) => res.type === "image"
          )?.id;
          const existVideoId = fetchedCP_data?.cpResourceDatas.find(
            (res) => res.type === "video"
          )?.id;
          //update blobname
          const onSuccess = async ({ data }: AxiosResponse<ICPResource>) => {
            dispatch(setCP_blobName(data.blob_name, data.type));
          };
          //image
          !existImageId
            ? await cp_resourceAPI.CPImage.create(image, cp_id)
                .then(onSuccess)
                .catch((error) => {
                  throw new Error("CP_IMAGE UPLOAD FAILED", error);
                })
            : await cp_resourceAPI.CPImage.update(image, cp_id, existImageId)
                .then(onSuccess)
                .catch((error) => {
                  throw new Error("CP_IMAGE UPDATE FAILED", error);
                });
          //video
          !existVideoId
            ? await cp_resourceAPI.CPVideo.create(video, cp_id)
                .then(onSuccess)
                .catch((error) => {
                  throw new Error("CP_VIDEO UPLOAD FAILED", error);
                })
            : await cp_resourceAPI.CPVideo.update(video, cp_id, existVideoId)
                .then(onSuccess)
                .catch((error) => {
                  throw new Error("CP_VIDEO UPDATE FAILED", error);
                });
        } finally {
          consoleLog("Resource Submit Done");
        }
    };

    const submitPhrases = async (cp_id: string) => {
      if (!!CP_text_data)
        try {
          const onSuccess = ({ data }: AxiosResponse<ICPText>) => {
            dispatch(setCP_textData(data, platform));
            consoleLog("CP_Text of response", data);
          };
          [...CP_data.cpTextDatas].forEach(async (CPText) => {
            if (!CPText.id) {
              await cp_textAPI
                .create({ ...CPText, cp_id })
                .then(onSuccess)
                .catch((error) => {
                  throw new Error("CP_Text UPLOAD FAILED", error);
                });
            } else {
              await cp_textAPI
                .update({ ...CPText, cp_id })
                .then(onSuccess)
                .catch((error) => {
                  throw new Error("CP_Text UPDATE FAILED", error);
                });
            }
          });
        } finally {
          consoleLog("Phrases Submit Done");
        }
    };

    const submitLinks = async () => {
      if (!!CP_expose_list)
        try {
          const willCreateLinks = CP_linkQueues.current.filter(
            (queue) => !queue.id
          );
          const willDeleteLinks = CP_linkQueues.default.filter(
            (data) =>
              !CP_linkQueues.current.find(
                (target) => target.link_id === data.link_id
              )
          );
          [...willCreateLinks].forEach(async (data) => {
            await cp_linkAPI.create(data.cp_id, data.link_id).catch((error) => {
              throw new Error("MAKE LINKS ERROR", error);
            });
          });
          [...willDeleteLinks].forEach(
            async (data) =>
              await cp_linkAPI.delete(data.id as string).catch((error) => {
                throw new Error("DELETE LINKS ERROR", error);
              })
          );
        } finally {
          consoleLog("Link Submit done");
        }
    };
    try {
      try {
        if (!currentGame_cp_data?.id) {
          // id 없는 경우 Create(Post)
          const newCP_data = await cp_dataAPI.createCrossPromotion(
            currentGame.id,
            CP_data.is_public,
            platform
          );
          if (!newCP_data) throw new Error("Create New CP Data FAILED");
          await submitResource(newCP_data.data.id).catch((error) => {
            throw new Error("Resource Creating Failed");
          });
          await submitPhrases(newCP_data.data.id).catch((error) => {
            throw new Error("Resource Creating Failed");
          });
          setTimeout(async () => {
            await cp_dataAPI
              .getCrossPromotion(newCP_data.data.id)
              .then(async (res) => {
                await queryClient
                  .refetchQueries([querykeys.current_game, currentGame.id])
                  .then(() => {
                    dispatch(initCP_data(res.data, platform));
                    dispatch(giveSubmitBtnNoti("save"));
                  });
              })
              .catch((err) => dispatch(giveSubmitBtnNoti("fail")))
              .finally(() => {
                dispatch(setSubmitBtnLoading(false));
              });
          }, 1000);
        } else {
          // CP id 가 존재하면 Update(put)
          await cp_dataAPI
            .updateCrossPromotion(
              currentGame_cp_data?.id as string,
              CP_data.is_public,
              CP_data.keep_time
            )
            .catch((error) => {
              throw new Error(error);
            });
          await submitResource(CP_data.id);
          await submitPhrases(CP_data.id);
          await submitLinks();
          setTimeout(async () => {
            await queryClient
              .refetchQueries([querykeys.cross_promotion.data, CP_data.id])
              .then(() => {
                dispatch(giveSubmitBtnNoti("save"));
                dispatch(setSubmitBtnLoading(false));
              });
          }, 1000);
        }
      } catch (error) {
        if (error instanceof Error) {
          consoleLog("Request Failed", error);
          dispatch(giveSubmitBtnNoti("fail", error.message));
        }
        dispatch(setSubmitBtnLoading(false));
      }
      //refresh
    } catch (error) {
      consoleLog("Form Base ERROR", error);
      dispatch(giveSubmitBtnNoti("fail"));
    }
  };
  //////////////////////////////////////////////////////////////////////////
  return (
    <Form id="cross-propmotion-form" onSubmit={onSubmit}>
      {isLoading || isFetching ? (
        <FormLoader />
      ) : (
        <>
          <BannerMedias key="common" />
          <BannerPhrases />
          <BannerShowForce />
          <BannerActiveSet />
          <BannerExposeListSet />
        </>
      )}
    </Form>
  );
}

export default React.memo(CPForm);

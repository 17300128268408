import { Reducer } from "redux";
import { ICPResource } from "../../api/V2/3.CrossPromotion/API_CPresource";

export interface CPResources {
  image: {
    blob_name?: string;
    file?: File;
  };
  video: {
    blob_name?: string;
    file?: File;
  };
  video_thumbnail: {
    blob_name?: string;
    file?: File;
  };
}

const initialState: CPResources = {
  image: {
    blob_name: undefined,
    file: undefined,
  },
  video: {
    blob_name: undefined,
    file: undefined,
  },
  video_thumbnail: {
    blob_name: undefined,
    file: undefined,
  },
};

type CPResourcesAction = ReturnType<
  | typeof initCP_resource
  | typeof setCP_file
  | typeof setCP_blobName
  | typeof clearCP_uploadFiles
>;

/**
 * Dispatch :  CP_resource 초기값 설정을 합니다. Query에서 사용.
 * @param CPresource
 * @param  cp_id
 */
const initCP_resource = (CPresource: ICPResource[]) => ({
  type: "initCP_resource" as const,
  payload: CPresource,
});

/**
 * Dispatch : CP_resource 이미지나 비디오를 설정합니다.
 * @param file
 * @param type
 */
const setCP_file = (file: File, type: ICPResource["type"]) => ({
  type: "setCP_file" as const,
  payload: { file, type },
});
/**
 * Dispatch : CP_resource 이미지나 비디오를 설정합니다.
 * @param file
 * @param type
 */
const setCP_blobName = (blob_name: string, type: ICPResource["type"]) => ({
  type: "setCP_blobName" as const,
  payload: { blob_name, type },
});

/**
 * Dispatch : CP_resource state를 default 초기화합니다.
 */
const clearCP_uploadFiles = () => ({
  type: "clearCP_uploadFIles" as const,
  payload: initialState,
});

const CP_resources: Reducer<CPResources, CPResourcesAction> = (
  state = initialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case "initCP_resource": {
      const image_blob_name = payload.find(
        (data) => data.type === "image"
      )?.blob_name;
      const video_blob_name = payload.find(
        (data) => data.type === "video"
      )?.blob_name;
      const video_thumbnail_blob_name = payload.find(
        (data) => data.type === "video_thumbnail"
      )?.blob_name;
      return {
        ...state,
        image: { blob_name: image_blob_name, file: undefined },
        video: { blob_name: video_blob_name, file: undefined },
        video_thumbnail: {
          blob_name: video_thumbnail_blob_name,
          file: undefined,
        },
      };
    }
    case "setCP_file":
      return {
        ...state,
        [payload.type]: { ...state[payload.type], file: payload.file },
      };
    case "setCP_blobName":
      return {
        ...state,
        [payload.type]: {
          ...state[payload.type],
          blob_name: payload.blob_name,
        },
      };
    case "clearCP_uploadFIles":
      return initialState;
    default:
      return state;
  }
};

export default CP_resources;

export const CP_resourceActions = {
  initCP_resource,
  setCP_file,
  setCP_blobName,
  clearCP_uploadFiles,
};

import { useEffect } from "react";
import { useAppDispatch } from "../../../modules";
import { currentGameActions } from "../../../modules/1.Game/currentGame";
import TBD from "../TBD";

function DashBoard() {
  //State
  const dispatch = useAppDispatch();
  //destruction State
  const { resetCurrentGame } = currentGameActions;

  useEffect(() => {
    dispatch(resetCurrentGame());
  });
  return <TBD />;
}

export default DashBoard;

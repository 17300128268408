import client from "../baseV2API";
import { IPlatformData } from "./API_PlatformDatas";
import { ICP_data } from "../3.CrossPromotion/API_CP";
import { objectToFormData } from "../../../v2/utils";
import { ISettingProperties } from "../../../modules/1.Game/currentGame";

export interface IGameBaseData {
  title: string;
  package_name: string;
  url_customer: string;
  url_gamepage: string;
  id: string;
}

export interface IGameList extends IGameBaseData {
  cp_datas: {
    platform: "IOS" | "AOS" | "aos" | "ios";
    is_public: boolean;
    game_id: string;
    id: string;
  }[];
}

export type FullDataKey = keyof IGameFullData;
export interface IGameFullData extends IGameBaseData {
  setting_datas: ISettingProperties[];
  platform_datas: IPlatformData[];
  cp_datas: ICP_data[];
}
/**
 * Axios Post
 * @param newGameFormData
 * @returns
 */
const createNewGame = (newGameFormData: FormData) => {
  return client.post<IGameBaseData>("/game", newGameFormData);
};

/**
 * 게임 정보 업데이트
 * @param gameInfo
 * @param game_id
 * @returns
 */
const updateGame = (gameInfo: IGameBaseData) => {
  const formdata = objectToFormData(gameInfo);
  return client.put<IGameBaseData>("/game", formdata);
};

const getGameList = async () => {
  return await client.get<IGameList[]>("/game/gamelist");
};

const getGameFullInfo = async (gameId: IGameBaseData["id"]) => {
  const response = await client.get<IGameFullData>("/game", {
    params: { id: gameId },
  });
  const game_full_data = response.data;
  return game_full_data;
};

const deleteGame = (gameId: IGameBaseData["id"]) => {
  const formdata = new FormData();
  formdata.append("id", gameId);
  return client.delete(`/game`, { data: formdata });
};

const gameAPI = {
  createNewGame,
  updateGame,
  getGameFullInfo,
  getGameList,
  deleteGame,
};

export default gameAPI;

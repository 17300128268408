import React from "react";
import { useAppDispatch } from "../../../../modules";
import { modalActions } from "../../../../modules/modal";
import { ModalTerm } from "../Modal";
import modalTerms from "../ModalTerms.json";
import ModalBase from "./ModalBase";

const no_banner_files_terms: ModalTerm = modalTerms.no_banner_files;

const NoFiles: React.FC = () => {
  //State
  const dispatch = useAppDispatch();
  const { setModalOff } = modalActions;

  const onClick = () => {
    return dispatch(setModalOff());
  };

  return <ModalBase term={no_banner_files_terms} onClick={onClick} />;
};

export default NoFiles;

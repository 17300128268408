import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 330px);
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  `;

const Box = styled.h1`
  position: relative;
  top:-100px;
  left: -100px;
  padding: 24px 36px;
  font-size: 2rem;
  background-color: ${(props) => props.theme.backgroundColor.reversal};
  color: ${(props) => props.theme.text.reversal};
`;

function TBD() {
  return (
    <Container>
      <Box>TBD</Box>
    </Container>
  );
}

export default TBD;

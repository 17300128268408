import axios from "axios";
import { base_url } from "../rest";
import { AuthData, jwtLogout, jwtRefresh } from "../rest/jwt";

//비동기 리프레싱 처리  .
const client = axios.create({
  baseURL: `${base_url}`,
});

//response interceptor Section
let isRefreshing: boolean = false;
let erroredRequests: any[] = [];
let interceptedErrorsStauts: (number | undefined)[] = [];
let error401LoopCount = () =>
  interceptedErrorsStauts.filter((err) => err === 401).length;

client.interceptors.response.use(
  async (res) => {
    interceptedErrorsStauts = [];
    return res;
  },
  async (err) => {
    const originalRequest = err.config;
    //401 일때만 다음을 실행합니다.
    if (err.response.status === 401) {
      //401 error 는 모두 모아놓습니다.
      erroredRequests = [...erroredRequests, originalRequest];
      if (isRefreshing) {
        //갱신 중인 동안에는 Error config를 모읍니다.
        console.log(erroredRequests);
      } else if (!isRefreshing) {
        //갱신 중인 상태로 변경.
        isRefreshing = true;
        //401 횟수를 카운트 합니다.
        interceptedErrorsStauts.push(err.response.status);
        if (error401LoopCount() > 10) {
          //401 error 가 10번을 초과하면 401 stack 을 초기화.
          interceptedErrorsStauts = [];
          alert(
            "refreshing JWT & request are in infinite loop with 401 code \n Auto logout & reload page "
          );
          //모두 중단하고 세션을 종료합니다.
          jwtLogout();
          return window.location.reload();
        }

        console.log("[ERROR]", err.message);
        // Refresh 를 시도합니다.
        await jwtRefresh()
          .then(async (response) => {
            //성공하면 세션에 토큰을 가져옵니다.
            console.log("JWT Refreshed");
            const sessionData = await sessionStorage.getItem("mayo");
            //토큰을 가져오지 못하는 경우에 직접 reponse 를 사용.
            const bearerToken: AuthData = sessionData
              ? JSON.parse(sessionData)
              : response;
            const AccessToken = bearerToken.accessToken.tokenString;
            //Stack 에 대한 header 에 토큰을 바꿔주고, 재실행합니다. 실행이 모두 끝나면 isRefreshing 을 종료.
            while (!(erroredRequests.length === 0)) {
              // erroredRequests[0]._retry = true;
              erroredRequests[0].headers = {
                ...erroredRequests[0].headers,
                Authorization: `Bearer ${AccessToken}`,
              };

              await client(erroredRequests[0]).catch((err) => {
                alert(
                  "refreshing JWT & request are in infinite loop with 401 code \n Auto logout & reload page "
                );
                //모두 중단하고 세션을 종료합니다.
                jwtLogout();
                window.location.reload();
              });
              erroredRequests.shift();
            }

            //Stack 을 날려버리고, 갱신 상태를 Off합니다.
            isRefreshing = false;
          })
          .catch((err) => {
            return Promise.reject(err);
          })
          .finally(() => console.log(erroredRequests));
      } else {
        console.log(err.reponse.status);
        return Promise.reject(err);
      }
    }
  }
);

export default client;

import styled from "styled-components";

export const Container = styled.div`
  height: 230px;
  display: flex;
  justify-content: space-between;
`;

export const HUD = styled.div`
  display: flex;
`;

export const Info = styled.div`
  margin-left: 70px;
`;

export const GameIcon = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 20px;
`;

export const Title = styled.h1`
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  height: 47px;
  font-size: 2.5rem;
  text-transform: capitalize;
`;

export const StoreSection = styled.section`
  padding: 15px 0%;
  display: flex;
  align-items: center;
  column-gap: 50px;
`;

export const Version = styled.span`
  display: flex;
  align-items: center;
  column-gap: 15px;
  font-size: 1.5rem;
  font-weight: 600;
`;

export const IdSection = styled.div`
  display: flex;
  font-family: "Noto Sans CJK KR";
  font-size: 1.5rem;
  font-weight: 900;
  white-space: nowrap;
`;

export const ID = styled.div`
  position: relative;
  margin-left: 15px;
  margin-right: 10px;
  font-weight: 600;
`;

import { useCallback, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import AlertIcon from "./assets/cms/alert_icon.svg";
import { jwtLogin, setSessionToken } from "./api/rest/jwt";
import VersionInfo from "./v2/components/Menubar/VersionInfo";
import MenuBarTitle from "./v2/components/Menubar/Title";

const Root = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Top = styled.div`
  position: fixed;
  left: 75px;
  top: 50px;
`;
const Version = styled.div`
  position: fixed;
  left: 75px;
  bottom: 50px;
`;

export const HorizontalLine = styled.div`
  display: flex;
`;

const Title = styled.div`
  margin-top: 45px;
  font-weight: 900;
  font-size: 35px;
  word-spacing: 10px;
  margin-bottom: 10px;
`;

const Circle = styled.div<{ is_active: boolean }>`
  width: 19px;
  height: 19px;
  border-radius: 50%;
  background-color: black;
  margin: 20px 12px;
  opacity: ${(props) => (props.is_active ? 1 : 0.2)};
`;

const PasswordMessage = styled.div`
  color: #e65237;
  font-weight: 500;
  font-size: 22px;
  letter-spacing: -1.4px;
`;

const AlertIconContainer = styled.img`
  margin-left: 2px;
  height: 26.67px;
  margin-top: 6px;
  margin-right: 5px;
`;
let PASSWORD = "";
function AdminPassword({
  set,
}: {
  set: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [error, setError] = useState(false);
  const [code, setCode] = useState("");

  const dispatch = useDispatch();

  const onKeyDown = useCallback(
    async (event: KeyboardEvent) => {
      let key = event.key;

      if (key === "Backspace") {
        PASSWORD = PASSWORD.substring(0, PASSWORD.length - 1);
      } else if (key === "Escape") {
        PASSWORD = "";
      } else {
        if (PASSWORD.length >= 6) return;

        PASSWORD = PASSWORD + key;
      }

      setCode(PASSWORD);
      if (PASSWORD.length === 6) {
        // 정답 확인
        try {
          let response = await jwtLogin(
            `${process.env.REACT_APP_JWT_BASE_USERNAME}`,
            PASSWORD
          );
          setSessionToken(response);
          setTimeout(() => set(true), 1000);
        } catch (e) {
          setError(true);
        }
        return;
      }

      setError(false);
    },
    [dispatch]
  );

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown, true);
    return () => {
      window.removeEventListener("keydown", onKeyDown, true);
    };
  }, [onKeyDown]);

  return (
    <>
      <Top>
        <MenuBarTitle />
      </Top>
      <Root>
        <Title>Administrator Password</Title>

        <HorizontalLine>
          <Circle is_active={code.length >= 1} />
          <Circle is_active={code.length >= 2} />
          <Circle is_active={code.length >= 3} />
          <Circle is_active={code.length >= 4} />
          <Circle is_active={code.length >= 5} />
          <Circle is_active={code.length === 6} />
        </HorizontalLine>

        <HorizontalLine
          style={{
            marginTop: "44px",
            height: "80px",
            marginLeft: "-8px",
            opacity: error ? 1 : 0.01,
          }}
        >
          <>
            <AlertIconContainer src={AlertIcon} />
            <PasswordMessage>
              비밀번호가 틀렸습니다. 다시 입력해 주세요.
            </PasswordMessage>
          </>
        </HorizontalLine>
      </Root>
      <Version>
        <VersionInfo />
      </Version>
    </>
  );
}

export default AdminPassword;

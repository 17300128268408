import styled from "styled-components";

export const Form = styled.form`
  gap: 80px;
  font-size: 1.6rem;
  font-weight: 700;
  letter-spacing: -1px;
`;

export const DeleteButton = styled.button`
  margin: 50px 0;
  width: 240px;
  height: 76px;
  font-size: 1.5rem;
  font-weight: 800;
  color: ${props=>props.theme.submitBtn.danger};
  border : 4px solid ${props=>props.theme.submitBtn.danger};
  border-radius: 20px;
  background-color: inherit;
  cursor: pointer;
  `
import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { mainRoutes } from "../../../../routes";
import { useAppDispatch, useAppSelector } from "../../../../modules";
import { submitBtnActions } from "../../../../modules/submitBtn";
import { Button, Container } from "./SubmitBtnStyled";
import Notification from "../notification/Notifcations";
import { BtnLoader } from "../Loaders";
import { isChanged } from "../../../utils";

const SubbmitButton: React.FC = () => {
  const {
    createGame,
    currentGame,
    CP_datas,
    CP_resources,
    CP_linkQueues,
    rankboard,
  } = useAppSelector((state) => state);
  const btnProps = useAppSelector((state) => state.submitBtn);
  // const form = useAppSelector((state) => state.form);
  const form = document.querySelector("form");
  const dispatch = useAppDispatch();

  //destruction
  const { valid, btnName, notiType, notiShow, isLoading } = btnProps;
  const { setSubmitBtnName, setSubmitBtnValid } = submitBtnActions;

  //location function
  const currentPath = window.location.pathname;
  const location = useLocation();
  const { create } = mainRoutes;

  useEffect(() => {
    switch (true) {
      case currentPath === create:
        dispatch(setSubmitBtnName("등록하기"));
        break;
      default:
        dispatch(setSubmitBtnName("저장하기"));
        break;
    }
  }, [location]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(setSubmitBtnValid(isChanged()));
    }, 300);
  }, [
    createGame,
    currentGame,
    CP_datas,
    CP_resources,
    CP_linkQueues,
    rankboard,
  ]);

  return (
    <>
      {form ? (
        <Container>
          <Button
            id="submit-button"
            type="submit"
            form={form.id}
            isValid={valid}
            disabled={isLoading}
          >
            {isLoading ? <BtnLoader /> : <>{btnName}</>}
          </Button>
          {notiShow ? (
            <Notification key="submit-button" type={notiType} />
          ) : null}
        </Container>
      ) : null}
    </>
  );
};

export default React.memo(SubbmitButton);

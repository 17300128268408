import client from "./baseV2API";

export interface GlobalSetting {
    [key: string]: string;
    url_website: string;
    url_instagram: string;
    url_facebook: string;
    url_google_developer: string;
    url_apple_developer: string;
    id: string;
  }
  
  export const getGlobalSetting = async () => {
    return await client.get<GlobalSetting>("/globalsetting").then(res=>res.data);
  };
  
  export const uploadGlobalSetting = (formdata: FormData) => {
    return client.post<GlobalSetting>("/globalsetting", formdata);
  };
  
  export const updateGlobalSetting = (formdata: FormData) => {
    return client.put<GlobalSetting>("/globalsetting", formdata);
  };
  
import { noSpace } from "../../../v2/utils";
import client from "../baseV2API";
import { IGameBaseData } from "./API_Game";

/**
 * Axios Post : upload Game Icon
 * @param game_id
 * @param file
 */
const uploadGameIcon = async (game_id: IGameBaseData["id"], file: File) => {
  const image = document.createElement("img");
  const promise = new Promise<HTMLImageElement>((resolve, reject) => {
    image.onload = async () => {
      resolve(image);
    };
    image.src = URL.createObjectURL(file);
  });
  const loadedImage = await promise;
  const headers = {
    "Content-Type": file.type,
    file_name: noSpace(file.name),
    type: "image",
    width: loadedImage.width,
    height: loadedImage.height,
    data_size: file.size,
    game_id,
  };
  return client.post("/game/icon", file, {
    headers,
  });
};

/**
 * Axios Put : Game Icon을 업데이트 합니다.
 * @param game_id
 * @param file
 * @returns File (png blob)
 */
const updateGameIcon = async (game_id: IGameBaseData["id"], file: File) => {
  const image = document.createElement("img");
  const promise = new Promise<HTMLImageElement>((resolve, reject) => {
    image.onload = async () => {
      resolve(image);
    };
    image.src = URL.createObjectURL(file);
  });
  const loadedImage = await promise;
  const headers = {
    "Content-Type": file.type,
    file_name: noSpace(file.name),
    type: "image",
    width: loadedImage.width,
    height: loadedImage.height,
    data_size: file.size,
    game_id,
  };
  return client.put("/game/icon", file, {
    headers,
  });
};
type Icon_url = {
  url: string;
  expires_on: string;
};
/**
 * Axios Get : Game Icon을 가져옵니다.
 * @param game_id
 * @param game_title
 * @returns Blob of Icon image
 */
const getIcon = async (game_id: IGameBaseData["id"]) => {
  const blob = await client
    .get<Icon_url>("/game/icon_url", {
      params: { game_id },
    })
    .then(async (response) => {
      const result = await (await fetch(response.data.url)).blob();
      return result;
    });
  return blob;
};

/**
 * Axios Get : Game Icon's Base64 가져옵니다.
 * @param game_id
 * @param game_title
 * @returns Base64 of Icon image url
 */
export const getIcon64 = async (game_id: IGameBaseData["id"]) => {
  const response = await client.get("/game/icon_base64", {
    params: { game_id },
  });
  const base64 = "data:image/png;base64," + response.data;
  return base64;
};

const iconAPI = {
  uploadGameIcon,
  updateGameIcon,
  getIcon,
  getIcon64,
};

export default iconAPI;

export const mainRoutes = {
  home: "/",
  settings: "/settings",
  create: "/create_game",
  game: "/game/:id/*",
};

export const gameRoutes = {
  dashboard: "dashboard",
  settingProperties: "setting_properties",
  crossPromotion: "cross_promotion",
  user: "user",
  rankboard: "rankboard",
  letterBox: "letter_box",
  apiKey: "API_key",
};

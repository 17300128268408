export const national_codes = [
  { name: "Afghanistan", code: 4 },
  { name: "Albania", code: 8 },
  { name: "Algeria", code: 12 },
  { name: "American Samoa", code: 16 },
  { name: "Andorra", code: 20 },
  { name: "Angola", code: 24 },
  { name: "Anguilla", code: 660 },
  { name: "Antarctica", code: 10 },
  { name: "Antigua and Barbuda", code: 28 },
  { name: "Argentina", code: 32 },
  { name: "Armenia", code: 51 },
  { name: "Aruba", code: 533 },
  { name: "Australia", code: 36 },
  { name: "Austria", code: 40 },
  { name: "Azerbaijan", code: 31 },
  { name: "Bahamas", code: 44 },
  { name: "Bahrain", code: 48 },
  { name: "Bangladesh", code: 50 },
  { name: "Barbados", code: 52 },
  { name: "Belarus", code: 112 },
  { name: "Belgium", code: 56 },
  { name: "Belize", code: 84 },
  { name: "Benin", code: 204 },
  { name: "Bermuda", code: 60 },
  { name: "Bhutan", code: 64 },
  { name: "Bolivia", code: 68 },
  { name: "Bosnia and Herzegovina", code: 70 },
  { name: "Botswana", code: 72 },
  { name: "Bouvet Island", code: 74 },
  { name: "Brazil", code: 76 },
  { name: "British-Indian ocean continent", code: 86 },
  { name: "Brunei Darussalam", code: 96 },
  { name: "Bulgaria", code: 100 },
  { name: "Burkinafaso", code: 854 },
  { name: "Burundi", code: 108 },
  { name: "Cambodia", code: 116 },
  { name: "Cameroon", code: 120 },
  { name: "Canada", code: 124 },
  { name: "Capeverde", code: 132 },
  { name: "Cayman Islands", code: 136 },
  { name: "Central African Republic", code: 140 },
  { name: "Chad", code: 148 },
  { name: "Chile", code: 152 },
  { name: "China", code: 156 },
  { name: "Christmas Island", code: 162 },
  { name: "Cocos Islands", code: 166 },
  { name: "Colombia", code: 170 },
  { name: "Comoros", code: 174 },
  { name: "Congo", code: 178 },
  { name: "Cook Islands", code: 184 },
  { name: "Costarica", code: 188 },
  { name: "Cotedivoire", code: 384 },
  { name: "Croatia", code: 191 },
  { name: "Cuba", code: 192 },
  { name: "Cyprus", code: 196 },
  { name: "Czech Republic", code: 203 },
  { name: "Denmark", code: 208 },
  { name: "Djibouti", code: 262 },
  { name: "Dominica", code: 212 },
  { name: "Dominican Republic", code: 214 },
  { name: "Ecuador", code: 218 },
  { name: "Egypt", code: 818 },
  { name: "Elsalvador", code: 222 },
  { name: "Equatorial Guinea", code: 226 },
  { name: "Eritrea", code: 232 },
  { name: "Estonia", code: 233 },
  { name: "Ethiopia", code: 210 },
  { name: "Falkland Islands", code: 238 },
  { name: "Faroe Islands", code: 234 },
  { name: "Fiji", code: 242 },
  { name: "Finland", code: 246 },
  { name: "France", code: 250 },
  { name: "France Metropolitan", code: 249 },
  { name: "French Guiana", code: 254 },
  { name: "French Polynesia", code: 258 },
  { name: "French Southern Territories", code: 260 },
  { name: "Gabon", code: 266 },
  { name: "Gambia", code: 270 },
  { name: "Georgia", code: 268 },
  { name: "Germany", code: 276 },
  { name: "Ghana", code: 288 },
  { name: "Gibraltar", code: 292 },
  { name: "Greece", code: 300 },
  { name: "Greenland", code: 304 },
  { name: "Grenada", code: 308 },
  { name: "Guadeloupe", code: 312 },
  { name: "Guam", code: 316 },
  { name: "Guatemala", code: 320 },
  { name: "Guinea", code: 324 },
  { name: "Guinea Bissau", code: 624 },
  { name: "Guyana", code: 328 },
  { name: "Haiti", code: 332 },
  { name: "Heard Island and McDonald Islands", code: 334 },
  { name: "Honduras", code: 340 },
  { name: "Hongkong", code: 344 },
  { name: "Hungary", code: 348 },
  { name: "Iceland", code: 352 },
  { name: "India", code: 356 },
  { name: "Indonesia", code: 360 },
  { name: "Iran Islamic Republic Of", code: 364 },
  { name: "Iraq", code: 368 },
  { name: "Ireland", code: 372 },
  { name: "Israel", code: 376 },
  { name: "Italy", code: 380 },
  { name: "Jamaica", code: 388 },
  { name: "Japan", code: 392 },
  { name: "Jordan", code: 400 },
  { name: "Kazakhstan", code: 398 },
  { name: "Kenya", code: 404 },
  { name: "Kiribati", code: 296 },
  { name: "Korea Democratic People's Republic Of", code: 408 },
  { name: "Korea Republic Of", code: 410 },
  { name: "Kuwait", code: 414 },
  { name: "Kyrgyzstan", code: 417 },
  { name: "Lao People's Democratic Republic", code: 418 },
  { name: "Latvia", code: 428 },
  { name: "Lebanon", code: 422 },
  { name: "Lesotho", code: 426 },
  { name: "Liberia", code: 430 },
  { name: "Libyan Arab Jamahiriya", code: 434 },
  { name: "Liechtenstein", code: 438 },
  { name: "Lithuania", code: 440 },
  { name: "Luxembourg", code: 442 },
  { name: "Macau", code: 446 },
  { name: "Macedonia The former yugoslav Republic Of", code: 807 },
  { name: "Madagascar", code: 450 },
  { name: "Malawi", code: 454 },
  { name: "Malaysia", code: 458 },
  { name: "Maldives", code: 462 },
  { name: "Mali", code: 466 },
  { name: "Malta", code: 470 },
  { name: "Marshall Islands", code: 584 },
  { name: "Martinique", code: 474 },
  { name: "Mauritania", code: 478 },
  { name: "Mauritius", code: 480 },
  { name: "Mayotte", code: 175 },
  { name: "Mexico", code: 484 },
  { name: "Micronesia Federated States Of", code: 583 },
  { name: "Moldova Republic Of", code: 498 },
  { name: "Monaco", code: 492 },
  { name: "Mongolia", code: 496 },
  { name: "Montserrat", code: 500 },
  { name: "Morocco", code: 504 },
  { name: "Mozambique", code: 508 },
  { name: "Myanmar", code: 104 },
  { name: "Namibia", code: 516 },
  { name: "Nauru", code: 520 },
  { name: "Nepal", code: 524 },
  { name: "Netherlands", code: 528 },
  { name: "Netherlands Antilles", code: 530 },
  { name: "Newcaledonia", code: 540 },
  { name: "Newzealand", code: 554 },
  { name: "Nicaragua", code: 558 },
  { name: "Niger", code: 562 },
  { name: "Nigeria", code: 566 },
  { name: "Niue", code: 570 },
  { name: "Norfolk Island", code: 574 },
  { name: "Northern Mariana Islands", code: 580 },
  { name: "Norway", code: 578 },
  { name: "Oman", code: 512 },
  { name: "Pakistan", code: 586 },
  { name: "Palau", code: 585 },
  { name: "Panama", code: 591 },
  { name: "Papuanewguinea", code: 598 },
  { name: "Paraguay", code: 600 },
  { name: "Peru", code: 604 },
  { name: "Philippines", code: 608 },
  { name: "Pitcairn", code: 612 },
  { name: "Poland", code: 616 },
  { name: "Portugal", code: 620 },
  { name: "Puertorico", code: 630 },
  { name: "Qatar", code: 634 },
  { name: "Reunion", code: 638 },
  { name: "Romania", code: 642 },
  { name: "Russian Federation", code: 643 },
  { name: "Rwanda", code: 646 },
  { name: "Saint Helena", code: 654 },
  { name: "Saint Kitts Nevis", code: 659 },
  { name: "Saintlucia", code: 662 },
  { name: "Saint Pierre And Miquelon", code: 666 },
  { name: "Saint Vincent And The Grenadines", code: 670 },
  { name: "Samoa", code: 882 },
  { name: "Sanmarino", code: 674 },
  { name: "Sao Tome And Principe", code: 678 },
  { name: "Saudiarabia", code: 682 },
  { name: "Senegal", code: 686 },
  { name: "Seychelles", code: 690 },
  { name: "Sierraleone", code: 694 },
  { name: "Singapore", code: 702 },
  { name: "Slovakia", code: 703 },
  { name: "Slovenia", code: 705 },
  { name: "Solomon Islands", code: 90 },
  { name: "Somalia", code: 706 },
  { name: "Southafrica", code: 710 },
  { name: "Spain", code: 724 },
  { name: "Srilanka", code: 144 },
  { name: "Sudan", code: 736 },
  { name: "Suriname", code: 740 },
  { name: "Svalbard And Jan Mayen Islands", code: 744 },
  { name: "Swaziland", code: 748 },
  { name: "Sweden", code: 752 },
  { name: "Switzerland", code: 756 },
  { name: "Syrian Arab Republic", code: 760 },
  { name: "Taiwan province of china", code: 158 },
  { name: "Tajikistan", code: 762 },
  { name: "Tanzania United Republic Of", code: 834 },
  { name: "Thailand", code: 764 },
  { name: "Togo", code: 768 },
  { name: "Tokelau", code: 772 },
  { name: "Tonga", code: 776 },
  { name: "Trinidad And Tobago", code: 780 },
  { name: "Tunisia", code: 788 },
  { name: "Turkey", code: 792 },
  { name: "Turkmenistan", code: 795 },
  { name: "Turks and Caicos Islands", code: 796 },
  { name: "Tuvalu", code: 798 },
  { name: "Uganda", code: 800 },
  { name: "Ukraine", code: 804 },
  { name: "United Arabemirates", code: 784 },
  { name: "United Kingdom", code: 826 },
  { name: "United States", code: 840 },
  { name: "United States Minor Outlying Islands", code: 581 },
  { name: "Uruguay", code: 858 },
  { name: "Uzbekistan", code: 860 },
  { name: "Vanuatu", code: 548 },
  { name: "Vaticancitystate", code: 336 },
  { name: "Venezuela", code: 862 },
  { name: "Vietnam", code: 704 },
  { name: "Virgin Islands UK", code: 92 },
  { name: "Virgin Islands US", code: 850 },
  { name: "Wallis and Futuna Islands", code: 876 },
  { name: "Western Sahara", code: 732 },
  { name: "Yemen", code: 887 },
  { name: "Yugoslavia", code: 891 },
  { name: "Zaire", code: 180 },
  { name: "Zambia", code: 894 },
  { name: "Zimbabwe", code: 716 },
];

import { useQuery, UseQueryOptions } from "react-query";
import querykeys from "./query";
//API
import gameAPI, { IGameFullData, IGameList } from "./1.Game/API_Game";
import iconAPI from "./1.Game/API_GameIcon";
import cp_dataAPI, { ICP_data } from "./3.CrossPromotion/API_CP";
import cp_resourceAPI, { ICPResource } from "./3.CrossPromotion/API_CPresource";
import api_keyAPI, { APIKeyData } from "./7.APIKey/API_APIkey";
//State
import { useAppDispatch } from "../../modules";
import { currentGameActions } from "../../modules/1.Game/currentGame";
import { IPlatform } from "../../modules/2.Platform/platform";
import { CP_dataActions } from "../../modules/3.CrossPromotion/CPDatas";
import { CP_resourceActions } from "../../modules/3.CrossPromotion/CPResources";
import { ExistBoardConfig } from "../../modules/rankboard";
import requestRankboard from "./5.Rankboard/API_Rank";
import { CP_linkActions } from "../../modules/3.CrossPromotion/CPLinkQueue";
import { gameListActions } from "../../modules/1.Game/gameList";

const { initCurrentGame } = currentGameActions;
const { initGameList } = gameListActions;
const { initCP_data } = CP_dataActions;
const { initCP_resource, setCP_file } = CP_resourceActions;
const { initDefaultLinks } = CP_linkActions;

type Config<T> = Omit<UseQueryOptions<T>, "queryKey" | "queryFn">;
/**
 * useQuery : Game_id 기반 현재 게임 정보를 가져오고 , state를 갱신합니다.
 *
 * key : ["current_game", "game_id"]
 * @param game_id
 * @param config
 * @returns useQueryResult
 */
export const useCurrentGameQuery = (
  game_id: string,
  config?: Config<IGameFullData>
) => {
  const dispatch = useAppDispatch();
  const query = useQuery<IGameFullData>(
    [querykeys.current_game, game_id],
    () =>
      gameAPI.getGameFullInfo(game_id).then((response) => {
        dispatch(initCurrentGame(response));
        return response;
      }),
    config
  );
  return query;
};

/**
 * useQuery : 게임 리스트 정보
 *
 * key : ["game_list"]
 * @param config
 * @returns useQueryResult
 */
export const useGameListQuery = (config?: Config<IGameList[]>) => {
  const dispatch = useAppDispatch();
  const query = useQuery<IGameList[]>(
    [querykeys.game_list],
    () =>
      gameAPI.getGameList().then((response) => {
        dispatch(initGameList(response.data));
        return response.data;
      }),
    config
  );
  return query;
};

/**
 * useQuery : Game_id 기반 현재 게임 아이콘 base64을 가져오고 , state를 갱신합니다.
 *  *
 * key : ["game_icon","game_id"]
 * @param game_id
 * @param config
 * @returns useQueryResult
 */
export const useGameIcon64Query = (
  game_id: string,
  config?: Config<string>
) => {
  const query = useQuery<string>(
    [querykeys.game_icon, game_id],
    () => iconAPI.getIcon64(game_id),
    config
  );
  return query;
};

export type IconData = { file: File; url: string };
/**
 * useQuery : Game_id 기반 현재 게임 아이콘 game_title 이름의 File(blob) 를 가져오고 , state를 갱신합니다.
 *
 * key : ["game_icon", "game_id"]
 * @param game_id
 * @param game_title
 * @param config
 * @returns useQueryResult
 */
export const useGameIconQuery = (
  game_id: string,
  game_title: string,
  config?: Config<IconData>
) => {
  const query = useQuery<IconData>(
    [querykeys.game_icon, game_id],
    ({ queryKey }: any) => {
      const [, game_id] = queryKey;
      return iconAPI.getIcon(game_id).then((blob) => {
        const format = blob.type.match(/(?<=\/).*/);
        const file = new File([blob], `${game_title}.${format}`);
        const url = URL.createObjectURL(blob);
        return { file, url };
      });
    },
    config
  );
  return query;
};

/**
 * useQuery : cp_id & platform 기반 cross_platform_data 를 가져오고 , state를 갱신합니다.
 *
 * key : ["cross_promotion_data","cp_id"]
 * @param cp_id
 * @param platform
 * @param config
 * @returns useQueryResult
 */
export const useCP_dataQuery = (
  cp_id: string,
  platform: IPlatform,
  config?: Config<ICP_data>
) => {
  const dispatch = useAppDispatch();
  const query = useQuery<ICP_data>(
    [querykeys.cross_promotion.data, cp_id],
    () =>
      cp_dataAPI.getCrossPromotion(cp_id).then((response) => {
        dispatch(initCP_data(response.data, platform));
        dispatch(initCP_resource(response.data.cpResourceDatas));
        dispatch(initDefaultLinks(response.data.cpLinkDatas));
        return response.data;
      }),
    config
  );
  return query;
};

/**
 * useQuery : blob_name 기반 cross_platform_file 를 가져오고 , state를 갱신합니다.
 *
 * key : ["cross_promotion_files", type]
 * @param blob_name
 * @param config
 * @returns useQueryResult
 */
export const useCP_resourceQuery = (
  cp_id: string,
  blob_name: string,
  type: ICPResource["type"],
  config?: Config<File>
) => {
  const dispatch = useAppDispatch();
  const query = useQuery<File>(
    [querykeys.cross_promotion.files, cp_id, type],
    () => {
      return cp_resourceAPI.getCP_resource(blob_name).then((response) => {
        dispatch(setCP_file(response, type));
        return response;
      });
    },
    { ...config }
  );
  return query;
};

export const useRankboardListQuery = (
  game_id: string,
  config?: Config<ExistBoardConfig[]>
) => {
  const query = useQuery<ExistBoardConfig[]>(
    [querykeys.rankboard, game_id],
    () => requestRankboard.getList(game_id).then((response) => response.data),
    config
  );

  return query;
};

/**
 * useQuery : Game_id 기반 API Key List를 Query
 *
 * key : ["api_key","game_id"]
 * @param game_id
 * @param config
 * @returns useQueryResult
 */
export const useAPIKeyQuery = (
  game_id: string,
  config?: Config<APIKeyData[]>
) => {
  const query = useQuery<APIKeyData[]>(
    [querykeys.api_key, game_id],
    () => api_keyAPI.getAPIKeyList(game_id).then((response) => response.data),
    config
  );
  return query;
};

import React from "react";
import styled from "styled-components";
import { useAppSelector } from "../../../modules";

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Noto Sans CJK KR";
  & span {
    padding: 5px 0px;
    font-size: 20px;
    font-weight: 700;
    opacity: 0.3;
    letter-spacing: -0.7px;
  }
`;

function VersionInfo() {
  const version = useAppSelector((state) => state.version);
  return (
    <InfoContainer>
      <span>Server {version.server}</span>
      <span>Client {version.client}</span>
    </InfoContainer>
  );
}

export default VersionInfo;

import { Reducer } from "redux";

/** plaform 이 있는 array type data 에서 해당 OS 에 해당하는 요소 기준입니다.
 * find, filter 등에 사용.
 */
const osStandard = (data: any, plaform: IPlatform) =>
  data.platform.toLowerCase() === plaform;
/** plaform 이 있는 array type data 에서 iOS 요소 기준입니다.
 * find, filter 등에 사용.
 */
const iosStandard = (data: any) => data.platform.toLowerCase() === "ios";
/** plaform 이 있는 array type data 에서 AOS 요소 기준입니다.
 * find, filter 등에 사용.
 */
const aosStandard = (data: any) => data.platform.toLowerCase() === "aos";
/**
 * OS platform, default value is 'ios'
 */
export type IPlatform = "ios" | "aos";

const initialState: IPlatform = "ios";

type PlatformAction = ReturnType<typeof setPlatform>;
/**
 * Dispatch : Set current game's OS platform
 * @param platform
 */
export const setPlatform = (platform: IPlatform) => ({
  type: "setPlatform",
  payload: platform,
});

/**
 * Default value is 'ios', set action when currentGame info set.
 */
const platform: Reducer<IPlatform, PlatformAction> = (
  state = initialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case "setPlatform":
      return payload;
    default:
      return state;
  }
};

export default platform;

export const platformActions = {
  setPlatform,
  osStandard,
  iosStandard,
  aosStandard,
};

import { Reducer } from "redux";

export type CreateBoardConfig = {
  game_id: string;
  title: string;
  method: "desc" | "asc";
};

export type ExistBoardConfig = {
  game_id: string;
  id: string;
  title: string;
  method: "desc" | "asc";
};

interface Rankboard {
  data: ExistBoardConfig[];
  toCreateQueue: CreateBoardConfig[];
}

type RankboardAction = ReturnType<
  | typeof addCreateRankboard
  | typeof updateExistRankboards
  | typeof editCreateRankboard
  | typeof removeCreateRankboard
  | typeof clearCreateRankboard
  | typeof deleteExistRankboard
>;

export const addCreateRankboard = (data: CreateBoardConfig) => ({
  type: "addCreateRankboard" as const,
  payload: data,
});

export const updateExistRankboards = (data: ExistBoardConfig) => ({
  type: "updateExistRankboards" as const,
  payload: data,
});

export const editCreateRankboard = (
  data: CreateBoardConfig,
  index: number
) => ({
  type: "editCreateRankboard" as const,
  payload: { data, index },
});

export const removeCreateRankboard = (index: number) => ({
  type: "removeCreateRankboard" as const,
  payload: index,
});

export const clearCreateRankboard = () => ({
  type: "clearCreateRankboard" as const,
  payload: [],
});

export const deleteExistRankboard = (id: string) => ({
  type: "deleteExistRankboard" as const,
  payload: id,
});

const initialState: Rankboard = {
  data: [],
  toCreateQueue: [],
};

const rankboard: Reducer<Rankboard, RankboardAction> = (
  state = initialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case "addCreateRankboard":
      return { ...state, toCreateQueue: [...state.toCreateQueue, payload] };
    case "updateExistRankboards":
      return { ...state, data: [...state.data, payload] };
    case "editCreateRankboard": {
      const changed = [...state.toCreateQueue];
      changed.splice(payload.index, 1, payload.data);
      return { ...state, toCreateQueue: changed };
    }
    case "removeCreateRankboard": {
      const removed = [...state.toCreateQueue];
      removed.splice(payload, 1);
      return { ...state, toCreateQueue: removed };
    }
    case "clearCreateRankboard": {
      return { ...state, toCreateQueue: payload };
    }
    case "deleteExistRankboard": {
      const deleted = [...state.data].filter((data) => data.id !== payload);
      return { ...state, data: deleted };
    }
    default:
      return state;
  }
};

export default rankboard;

import { useMatch } from "react-router"
import styled from "styled-components";
import { mainRoutes } from "../../../routes"
import GameHeader from "./GameHeader";
import MainHeader from "./MainHeader";

 const AppHeader = styled.div`
  padding-right: 120px;
  padding-left: 120px;
`

function Header() {
    const gamePage = useMatch(mainRoutes.game);
    const settingPage = useMatch('/game/:id/setting');
  return (
    <AppHeader id='header'>
    {gamePage && !settingPage ?  <GameHeader/> : <MainHeader/>}
    </AppHeader>
  )
}

export default Header
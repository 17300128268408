import styled from "styled-components";

const LoaderContainer = styled.div<{ color: string }>`
  display: inline-block;
  position: relative;
  min-width: 50px;
  height: 50px;
  border-radius: 10px;
  & div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 38px;
    height: 38px;
    margin: 5px;
    border: 5px solid ${({ color }) => color};
    border-radius: 50%;
    animation: rotating 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${({ color }) => color} transparent transparent transparent;
  }
  & div:nth-child(1) {
    animation-delay: -0.45s;
  }
  & div:nth-child(2) {
    animation-delay: -0.3s;
  }
  & div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes rotating {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Loader = ({ color = "black" }) => (
  <LoaderContainer color={color}>
    <div></div>
    <div></div>
    <div></div>
  </LoaderContainer>
);

const SubmitBtnLoader = styled.div``;

export const BtnLoader = ({ color = "black" }) => (
  <SubmitBtnLoader>
    <Loader color={color} />
  </SubmitBtnLoader>
);

const GameLoader = styled.div``;
export const GameIconLoader = ({ color = "black" }) => (
  <GameLoader>
    <Loader color={color} />
  </GameLoader>
);

const FormLoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
`;

export const FormLoader = () => (
  <FormLoaderContainer>
    <Loader />
    데이터 처리 중...
  </FormLoaderContainer>
);

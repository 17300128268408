import React from "react";
import {
  IPlatformData,
  PlatformDataKey,
} from "../../../../api/V2/1.Game/API_PlatformDatas";
import {
  Fieldset,
  Label,
  ToggelLayout,
  Toggle,
  ToggleArea,
} from "../../Interactions/Inputs/MayoInputStyled";
import MayoInput from "../../Interactions/Inputs/MayoTextInput";

interface IMayoPlatformField
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  fields?: {
    label: string;
    name: string;
    type: "url" | "version" | string;
    placeholder?: string;
  }[];
  values?: IPlatformData;
  beforeValues?: IPlatformData;
  onVersionChange?: React.ChangeEventHandler<HTMLInputElement>;
  onFieldChange?: React.ChangeEventHandler<HTMLInputElement>;
  onToggle?: React.ChangeEventHandler<HTMLInputElement>;
}

const PlatformInputField: React.FC<IMayoPlatformField> = ({ ...props }) => {
  const getVersion = (data?: IPlatformData) => {
    if (!data) return "";
    const { major, minor, patch, reversion } = data;
    const trimedPatch = patch !== undefined ? patch.replace(/\s/g, "") : patch;
    const result = [major, minor, trimedPatch, reversion].join(".");
    return result.length === 3 ? "" : result;
  };
  return (
    <div>
      <ToggelLayout>
        <Label
          className={props.defaultChecked !== props.checked ? "changed" : ""}
          htmlFor={props.id}
        >
          {props.label}
        </Label>
        <ToggleArea>
          <Toggle
            id={props.id}
            className="toggle-input"
            type="checkbox"
            checked={props.checked}
            onChange={props.onToggle}
          />
        </ToggleArea>
      </ToggelLayout>
      {props.checked ? (
        <Fieldset>
          {props.fields?.map((field) => {
            switch (field.type) {
              case "url":
                return (
                  <MayoInput
                    key={props.id + field.name}
                    id={props.id}
                    label={field.label}
                    name={field.name}
                    type="url"
                    invalidNoti="링크 형식에 맞춰주세요."
                    placeholder={field.placeholder}
                    value={
                      props.values &&
                      props.values[field.name as PlatformDataKey]
                    }
                    beforeValue={
                      props.beforeValues &&
                      props.beforeValues[field.name as PlatformDataKey]
                    }
                    onChange={props.onFieldChange}
                    required
                  />
                );
              case "version":
                return (
                  <MayoInput
                    key={props.id + field.name}
                    id={props.id}
                    label={field.label}
                    name={field.name}
                    type="text"
                    pattern="\d+\.\d+\.\d+\.\d+"
                    invalidNoti="버전 형식에 맞춰주세요.  Ex: 0.0.0.0"
                    placeholder="0.0.0.0"
                    value={props.values?.version_str}
                    beforeValue={
                      props.beforeValues && getVersion(props.beforeValues)
                    }
                    onChange={props.onVersionChange}
                    required
                  />
                );
              default:
                return null;
            }
          })}
        </Fieldset>
      ) : null}
    </div>
  );
};

export default PlatformInputField;

import { useQueryClient } from "react-query";
import { useAppDispatch, useAppSelector } from "../../../../../modules";
import requestRankboard from "../../../../../api/V2/5.Rankboard/API_Rank";
import querykeys from "../../../../../api/V2/query";
import {
  clearCreateRankboard,
  ExistBoardConfig,
} from "../../../../../modules/rankboard";
import CreatingBoard from "./CreatingBoard";
import ExistBoard from "./ExistBoard";
import { BoardForm } from "../RankbooadStyled";
import { submitBtnActions } from "../../../../../modules/submitBtn";
import { consoleLog } from "../../../../utils";

function Boards() {
  //State
  const currentGame = useAppSelector((state) => state.currentGame);
  const rankboard = useAppSelector((state) => state.rankboard);
  const dispatch = useAppDispatch();

  //destruction State
  const { setSubmitBtnLoading, giveSubmitBtnNoti } = submitBtnActions;

  //Query
  const queryClient = useQueryClient();
  const ExistRankboards = queryClient.getQueryData<ExistBoardConfig[]>([
    querykeys.rankboard,
    currentGame.id,
  ]);

  //Function
  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    dispatch(setSubmitBtnLoading(true));
    try {
      const boardsToMake = rankboard.toCreateQueue.map((data) => {
        return requestRankboard.create(data);
      });
      await Promise.all(boardsToMake)
        .then(
          (res) => {
            if (!!res) {
              dispatch(clearCreateRankboard());
              queryClient
                .refetchQueries<ExistBoardConfig[]>([
                  querykeys.rankboard,
                  currentGame.id,
                ])
                .then(() => dispatch(giveSubmitBtnNoti("save")));
            } else {
              throw new Error("res failed but catch also failed");
            }
          },
          (rej) => {
            console.log(rej);
          }
        )
        .catch((err) => {
          dispatch(giveSubmitBtnNoti("fail"));
          throw new Error("new Board Post Failed", err);
        });
    } catch (error) {
      dispatch(giveSubmitBtnNoti("fail"));
      consoleLog("Create Rankboard Failed", error);
    } finally {
      dispatch(setSubmitBtnLoading(false));
    }
  };

  return (
    <BoardForm id="rankboard-form" onSubmit={onSubmit}>
      {rankboard.toCreateQueue.map((config, index) => (
        <CreatingBoard key={index + "queue"} index={index} config={config} />
      ))}
      {ExistRankboards?.map((board, index) => (
        <ExistBoard key={index + "data"} board={board} />
      ))}
    </BoardForm>
  );
}

export default Boards;

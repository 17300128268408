const game_list = "game_list" as const;
const game_icon = "game_icon" as const;
const current_game = "current_game" as const;
const cross_promotion = {
  data: "cross_promotion_data" as const,
  files: "cross_promotion_files" as const,
} as const;
const rankboard = {
  board_list: "rankboad_list" as const,
  hall_of_fame: "hall_of_fame" as const,
  country: "rankboard_by_country" as const,
} as const;
const api_key = "api_key" as const;

const querykeys = {
  game_list,
  game_icon,
  current_game,
  cross_promotion,
  rankboard,
  api_key,
};

export default querykeys;

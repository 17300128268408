import React, { useState, useEffect } from "react";
import { useQueryClient } from "react-query";
import api_keyAPI, { APIKeyData } from "../../../../api/V2/7.APIKey/API_APIkey";
import querykeys from "../../../../api/V2/query";
import { useAppDispatch } from "../../../../modules";
import { modalActions } from "../../../../modules/modal";
import {
  CopyClipboardIcon,
  SaveIcon,
  UnvisibleIcon,
  VisibleIcon,
} from "../../SVGs";
import {
  API,
  Toggle,
  Issued,
  KeyBox,
  Label,
  DeleteBtn,
  Key,
  Notification,
} from "./APIkeyStyled";

interface IAPIKey extends React.InputHTMLAttributes<HTMLInputElement> {
  data: APIKeyData;
  game_id: string;
}

const APIkey: React.FC<IAPIKey> = ({ data, game_id, ...props }) => {
  //State
  const dispatch = useAppDispatch();
  const { setWarningModalOn, setDeleteModalOn } = modalActions;
  //Local State
  const [enable, setEnable] = useState<boolean>();
  const [visible, setVisible] = useState<boolean>(false);
  const [saveComplete, setSaveComplete] = useState<boolean>(false);

  const FixLength = (string: string) =>
    string.length > 1 ? string : "0" + string;
  const day = new Date(data.create_at);
  const year = day.getFullYear().toString();
  const month = FixLength((day.getMonth() + 1).toString());
  const date = FixLength(day.getDate().toString());
  const hour = FixLength(day.getHours().toString());
  const min = FixLength(day.getMinutes().toString());
  //Query
  const queryClient = useQueryClient();

  //LifeCylce
  useEffect(() => {
    setEnable(data.enable);
  }, [data.enable]);

  //Function
  const onChange: React.ChangeEventHandler<HTMLInputElement> = async (
    event
  ) => {
    const {
      currentTarget: { checked },
    } = event;
    await api_keyAPI.updateAPIKey(data.id, checked).then(
      (response) => setEnable(checked),
      (reject) => {
        dispatch(
          setWarningModalOn(
            "API key 상태 변경이 실패했습니다. \n 서버응답을 확인해주세요."
          )
        );
      }
    );
  };

  const onCopy = () => {
    navigator.clipboard.writeText(data.key);
    setSaveComplete(true);
    setTimeout(() => setSaveComplete(false), 5000);
  };

  const onDelete: React.MouseEventHandler<HTMLButtonElement> = () => {
    const deleteAction = async () => {
      await api_keyAPI.deleteAPIKey(data.id);
      await queryClient.refetchQueries([querykeys.api_key, game_id]);
    };
    dispatch(setDeleteModalOn(deleteAction));
  };

  return (
    <KeyBox>
      <Label>
        <Toggle type="checkbox" checked={enable} onChange={onChange} />
      </Label>
      {visible ? (
        <UnvisibleIcon onClick={() => setVisible(false)} />
      ) : (
        <VisibleIcon onClick={() => setVisible(true)} />
      )}
      <CopyClipboardIcon onClick={onCopy} />
      <API>
        <Key type={visible ? "text" : "password"} value={data.key} readOnly />
        {saveComplete ? (
          <Notification>
            <SaveIcon />
            클림보드에 복사되었습니다.
          </Notification>
        ) : null}
      </API>
      <Issued>발급일 {`${year}.${month}.${date} ${hour}:${min}`}</Issued>
      <DeleteBtn onClick={onDelete}>×</DeleteBtn>
    </KeyBox>
  );
};

export default APIkey;

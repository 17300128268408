import { Reducer } from "redux";

export type NotiType = "save" | "copy" | "fail" | "error";

export interface SubmitButton {
  btnName: string;
  valid: boolean;
  notiType: NotiType;
  notiShow: boolean;
  error?: string;
  isLoading: boolean;
}

type SubmitBtnAction = ReturnType<
  | typeof setSubmitBtnName
  | typeof setSubmitBtnValid
  | typeof setSubmitBtnLoading
  | typeof giveSubmitBtnNoti
  | typeof clearSubmitBtnNoti
  | typeof resetSubmitBtn
>;

const setSubmitBtnName = (btnName: SubmitButton["btnName"]) => ({
  type: "setSubmitBtnName" as const,
  btnName,
});
const setSubmitBtnValid = (valid: SubmitButton["valid"]) => ({
  type: "setSubmitValid" as const,
  valid,
});

const setSubmitBtnLoading = (isLoading: boolean) => ({
  type: "setSubmitBtnLoading" as const,
  payload: isLoading,
});

const giveSubmitBtnNoti = (
  notiType: SubmitButton["notiType"],
  error?: string
) => ({
  type: "giveSubmitBtnNoti" as const,
  notiType,
  error,
});
const clearSubmitBtnNoti = () => ({
  type: "clearSubmitBtnNoti" as const,
});
const resetSubmitBtn = () => ({
  type: "resetSubmitBtn" as const,
});

const initialState: SubmitButton = {
  btnName: "저장하기",
  valid: false,
  notiType: "save",
  notiShow: false,
  isLoading: false,
};

const submitBtn: Reducer<SubmitButton, SubmitBtnAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "setSubmitBtnName":
      return { ...state, btnName: action.btnName };
    case "setSubmitValid":
      return { ...state, valid: action.valid };
    case "setSubmitBtnLoading":
      return { ...state, isLoading: action.payload };
    case "giveSubmitBtnNoti":
      return {
        ...state,
        notiType: action.notiType,
        notiShow: true,
        error: action.error,
      };
    case "clearSubmitBtnNoti":
      return { ...state, notiShow: false };
    case "resetSubmitBtn":
      return initialState;
    default:
      return state;
  }
};

export default submitBtn;

export const submitBtnActions = {
  setSubmitBtnName,
  setSubmitBtnValid,
  setSubmitBtnLoading,
  giveSubmitBtnNoti,
  clearSubmitBtnNoti,
  resetSubmitBtn,
};

import client from "../baseV2API";
import { IPlatform } from "../../../modules/2.Platform/platform";
import { ICPResource } from "./API_CPresource";
import { ICPText } from "./API_CPText";
import { ICPLinkData } from "./API_CPLink";

export interface ICP_data {
  platform: "IOS" | "AOS" | "ios" | "aos";
  id: string;
  game_id: string;
  is_public: boolean;
  keep_time: number;
  cpResourceDatas: ICPResource[];
  cpTextDatas: ICPText[];
  cpLinkDatas: ICPLinkData[];
  deleteCPTextQueue?: string[];
}

/**
 * 게임 아이디와 플랫폼으로 새로운 플랫폼 CP 데이터 생성.
 * @param game_id
 * @param platform
 * @returns CP id 생성.
 */
const createCrossPromotion = async (
  game_id: string,
  is_public: boolean,
  platform: IPlatform
) => {
  const formdata = new FormData();
  formdata.append("game_id", game_id);
  formdata.append("is_public", is_public + "");
  formdata.append("platform", platform);
  const response = await client.post<ICP_data>("/CP", formdata);
  return response;
};

const updateCrossPromotion = async (
  cp_id: string,
  is_public: boolean,
  keep_time: number
) => {
  const formdata = new FormData();
  formdata.append("id", cp_id);
  formdata.append("is_public", is_public + "");
  formdata.append("keep_time", keep_time + "");
  const response = await client.put<ICP_data>("/CP", formdata);
  return response;
};

const getCrossPromotion = (cp_id: string) => {
  return client.get<ICP_data>("/CP", { params: { id: cp_id } });
};
const deleteCrossPromotion = (cp_id: string) => {
  return client.delete("/CP", { data: { id: cp_id } });
};

const cp_dataAPI = {
  createCrossPromotion,
  updateCrossPromotion,
  getCrossPromotion,
  deleteCrossPromotion,
};

export default cp_dataAPI;

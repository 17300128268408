import { Reducer } from "redux";
import { IGameFullData } from "../../api/V2/1.Game/API_Game";
import { IPlatformData } from "../../api/V2/1.Game/API_PlatformDatas";
import { ICP_data } from "../../api/V2/3.CrossPromotion/API_CP";
import { platformActions, IPlatform } from "../2.Platform/platform";

const { iosStandard, aosStandard } = platformActions;

export type SettingPropsKey = keyof ISettingProperties;
export interface ISettingProperties {
  id?: string;
  game_id?: string;
  platform: IPlatform;
  platformName: IPlatform;
  is_hidden_function_enabled: boolean;
  is_force_update_enabled: boolean;
  is_ad_request_enabled: boolean;
  is_cross_promotion_enabled: boolean;
}

export type CurrentGameKey = keyof ICurrentGame;
export interface ICurrentGame {
  id: string;
  title: string;
  package_name: string;
  url_customer: string;
  url_gamepage: string;
  icon?: File;
  setting_datas: {
    ios: ISettingProperties;
    aos: ISettingProperties;
  };
  platform_datas: {
    ios?: IPlatformData;
    aos?: IPlatformData;
  };
  cp_datas: {
    ios?: ICP_data;
    aos?: ICP_data;
  };
}

const initialState: ICurrentGame = {
  id: "",
  title: "",
  package_name: "",
  url_customer: "",
  url_gamepage: "",
  icon: undefined,
  setting_datas: {
    ios: {
      platform: "ios",
      platformName: "ios",
      is_hidden_function_enabled: false,
      is_force_update_enabled: false,
      is_ad_request_enabled: false,
      is_cross_promotion_enabled: false,
    },
    aos: {
      platform: "aos",
      platformName: "aos",
      is_hidden_function_enabled: false,
      is_force_update_enabled: false,
      is_ad_request_enabled: false,
      is_cross_promotion_enabled: false,
    },
  },
  platform_datas: {
    ios: undefined,
    aos: undefined,
  },
  cp_datas: { ios: undefined, aos: undefined },
};

type ICurrentGameInfoAction = ReturnType<
  | typeof initCurrentGame
  | typeof setCurrentGame
  | typeof setCurrentGameIcon
  | typeof setCurrentGameIos
  | typeof setCurrentGameAos
  | typeof setSettingProps
  | typeof resetCurrentGame
>;

/**
 * Dispatch : 첫 currentGame state 를 지정합니다.
 *
 *  useQuery custom hook에 붙여서 자동으로 query시에 적용 되도록 사용.
 * @param game_full_data
 */
const initCurrentGame = (game_full_data: IGameFullData) => ({
  type: "initCurrentGame" as const,
  payload: game_full_data,
});

/**
 * Dispatch : currentGame  key에 해당하는 value를 설정합니다.
 * @param key
 * @param value
 */
const setCurrentGame = (
  key: CurrentGameKey,
  value: ICurrentGame[CurrentGameKey]
) => ({
  type: "setCurrentGame" as const,
  payload: { key, value },
});

/**
 * Dispatch : currentGame 에서 게임 아이콘을 설정합니다.
 * @param {File} icon
 */
const setCurrentGameIcon = (icon: File) => ({
  type: "setCurrentGameIcon" as const,
  payload: icon,
});

/**
 * Dispatch : currentGame 의 iOS platform_data 를 설정합니다.
 * @param platform_data
 */
const setCurrentGameIos = (platform_data: IPlatformData | undefined) => ({
  type: "setCurrentGameIos" as const,
  payload: platform_data,
});

/**
 * Dispatch : currentGame 의 AOS platform_data 를 설정합니다.
 * @param platform_data
 */
const setCurrentGameAos = (platform_data: IPlatformData | undefined) => ({
  type: "setCurrentGameAos" as const,
  payload: platform_data,
});

/**
 * Dispatch : currentGame 의 [platform] 기준 [key]값의 value를 설정합니다.
 * @param key
 * @param value
 * @param plaform
 */
const setSettingProps = (
  key: SettingPropsKey,
  value: ISettingProperties[SettingPropsKey],
  platform: IPlatform
) => ({
  type: "setSettingProps" as const,
  payload: { key, value, platform },
});

/** Dispatch : currentGame을 default 로 초기화합니다 */
const resetCurrentGame = () => ({
  type: "resetCurrentGame" as const,
  payload: initialState,
});

const currentGame: Reducer<ICurrentGame, ICurrentGameInfoAction> = (
  state = initialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case "initCurrentGame": {
      const { setting_datas, platform_datas, cp_datas } = payload;
      const init: ICurrentGame = {
        ...state,
        ...payload,
        setting_datas: {
          ios: {
            ...state.setting_datas.ios,
            ...setting_datas.find(iosStandard),
          },
          aos: {
            ...state.setting_datas.aos,
            ...setting_datas.find(aosStandard),
          },
        },
        platform_datas: {
          ios: platform_datas.find(iosStandard),
          aos: platform_datas.find(aosStandard),
        },
        cp_datas: {
          ios: cp_datas.find(iosStandard),
          aos: cp_datas.find(aosStandard),
        },
      };
      return init;
    }
    case "setCurrentGame": {
      return { ...state, [payload.key]: payload.value };
    }
    case "setCurrentGameIcon": {
      return { ...state, icon: payload };
    }
    case "setCurrentGameIos":
      return {
        ...state,
        platform_datas: { ...state.platform_datas, ios: payload },
      };
    case "setCurrentGameAos":
      return {
        ...state,
        platform_datas: { ...state.platform_datas, aos: payload },
      };
    case "setSettingProps":
      return {
        ...state,
        setting_datas: {
          ...state.setting_datas,
          [payload.platform]: {
            ...state.setting_datas[payload.platform],
            [payload.key]: payload.value,
          },
        },
      };
    case "resetCurrentGame":
      return initialState;
    default:
      return state;
  }
};

export default currentGame;

export const currentGameActions = {
  initCurrentGame,
  setCurrentGame,
  setCurrentGameIcon,
  setCurrentGameIos,
  setCurrentGameAos,
  setSettingProps,
  resetCurrentGame,
};

import { useAppDispatch, useAppSelector } from "../../../../../modules";
import {
  CreateBoardConfig,
  editCreateRankboard,
  removeCreateRankboard,
} from "../../../../../modules/rankboard";
import BoardsByType from "./BoardsByType";
import {
  Section,
  Label,
  Input,
  Select,
  BoardBox,
  DeleteBtn,
} from "../RankbooadStyled";

interface ICreateBoard {
  config: CreateBoardConfig;
  index: number;
}

const CreatingBoard: React.FC<ICreateBoard> = ({ config, index }) => {
  const rankboard = useAppSelector((state) => state.rankboard);
  const dispatch = useAppDispatch();
  const onTextChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const {
      currentTarget: { value },
    } = event;
    const edited: CreateBoardConfig = {
      ...rankboard.toCreateQueue[index],
      title: value,
    };
    dispatch(editCreateRankboard(edited, index));
  };

  const onSelectChange: React.ChangeEventHandler<HTMLSelectElement> = (
    event
  ) => {
    const {
      currentTarget: { value },
    } = event;
    const edited: CreateBoardConfig = {
      ...rankboard.toCreateQueue[index],
      method: value as "desc" | "asc",
    };
    dispatch(editCreateRankboard(edited, index));
  };

  const onDltClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    dispatch(removeCreateRankboard(index));
  };

  return (
    <BoardBox className="changed">
      <DeleteBtn onClick={onDltClick}>×</DeleteBtn>
      Rankboad ID
      <Section>
        <Label>
          Rankboard 이름
          <Input
            type="text"
            name="title"
            value={config.title}
            onChange={onTextChange}
            required
          />
        </Label>
        <Label>
          정렬 방식
          <Select name="method" value={config.method} onChange={onSelectChange}>
            <option value={"desc"}>오름차순</option>
            <option value={"asc"}>내림차순</option>
          </Select>
        </Label>
      </Section>
      <BoardsByType block={true} />
    </BoardBox>
  );
};

export default CreatingBoard;

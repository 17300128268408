import React, { useState } from "react";
import { useQueryClient } from "react-query";
import {
  HonorRankingConfig,
  RankingConfig,
} from "../../../../../../api/V2/5.Rankboard/API_Rank";
import { deleteHonor, deleteRecord } from "../../../../../../api/V2/gameRecord";
import querykeys from "../../../../../../api/V2/query";
import { useAppDispatch } from "../../../../../../modules";
import { modalActions } from "../../../../../../modules/modal";
import { SaveIcon } from "../../../../SVGs";
import {
  CountryCode,
  Data,
  Delete,
  Nickname,
  Point,
  Ranking,
  TableColumn,
} from "../TableStyled";

interface IRankingData {
  data: RankingConfig | HonorRankingConfig;
  index: number;
  page: number;
  board_id: string;
  type: "entire" | "hall" | "country";
}

function DataRow({ data, board_id, index, page, type }: IRankingData) {
  const tableIndex = index + 1 + (page - 1) * 10;
  //State
  const dispatch = useAppDispatch();
  //Local State
  const [noti, setNoti] = useState(false);
  //destruction State
  const { setDeleteModalOn, setWarningModalOn } = modalActions;
  //Query
  const queryClient = useQueryClient();

  //Function
  const onNicknameClick = () => {
    if (data.id) navigator.clipboard.writeText(data.id);
    setNoti(true);
    setTimeout(() => setNoti(false), 2000);
  };
  const onDltClick: React.MouseEventHandler<HTMLDivElement> = async (event) => {
    const {
      currentTarget: { id },
    } = event;
    const dltAction = async () => {
      const onError = () => dispatch(setWarningModalOn("삭제에 실패했습니다."));
      if (type === "hall") {
        await deleteHonor(id).catch(onError);
        await queryClient.refetchQueries([
          querykeys.rankboard,
          board_id,
          "hall",
          page,
        ]);
      } else if (type === "country") {
        await deleteRecord(id).catch(onError);
        await queryClient.refetchQueries([
          querykeys.rankboard,
          board_id,
          "country",
          page,
        ]);
        await queryClient.refetchQueries([
          querykeys.rankboard,
          board_id,
          "entire",
          page,
        ]);
      } else {
        await deleteRecord(id).catch(onError);
        await queryClient.refetchQueries([
          querykeys.rankboard,
          board_id,
          "entire",
          page,
        ]);
        await queryClient.refetchQueries([
          querykeys.rankboard,
          board_id,
          "country",
          page,
        ]);
      }
    };
    dispatch(setDeleteModalOn(dltAction));
  };

  return (
    <TableColumn className="row" key={page + " page"} onClick={onNicknameClick}>
      <Ranking>{tableIndex}</Ranking>
      <Nickname>
        {data.userData?.nick || "-"}
        {noti ? <SaveIcon /> : null}
      </Nickname>
      <CountryCode>{data.country || "-"}</CountryCode>
      <Point>{data.score || "-"}</Point>
      <Data>{data.data || "-"}</Data>
      <Delete id={data.id} onClick={onDltClick}>
        ×
      </Delete>
    </TableColumn>
  );
}

export default DataRow;

import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useAppDispatch } from "../../../modules";
import { modalActions } from "../../../modules/modal";
import { isChanged } from "../../utils";

const Header = styled.div`
  float: left;
  & div {
    cursor: pointer;
    width: fit-content;
  }
`;
const Title = styled.div`
  font-size: 45px;
  margin-bottom: 20px;
`;
const AuthLevel = styled.div`
  font-weight: 700;
  font-size: 25px;
`;

function MenuBarTitle() {
  //State
  const dispatch = useAppDispatch();
  //destruction State
  const { setUnsavedModalOn } = modalActions;
  //Pages
  const navigate = useNavigate();
  //Functions
  const onClick = () => {
    isChanged()
      ? dispatch(setUnsavedModalOn(() => navigate("/")))
      : navigate("/");
  };
  //setRole.
  return (
    <Header>
      <div onClick={onClick}>
        <Title>CMS</Title>
        <AuthLevel>Administrator</AuthLevel>
      </div>
    </Header>
  );
}

export default MenuBarTitle;

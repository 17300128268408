interface IIcon extends React.SVGProps<SVGSVGElement> {}
export const ReplaceIcon: React.FC<IIcon> = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="25.1"
    height="24.1"
    viewBox="0 0 25.1 24.1"
  >
    <path
      id="FontAwsome_sync_"
      data-name="FontAwsome (sync)"
      d="M21.516.589l.2,3.88A12.281,12.281,0,0,0,12.492.375,11.986,11.986,0,0,0,.6,9.835a.546.546,0,0,0,.126.462.6.6,0,0,0,.45.2h2.4a.581.581,0,0,0,.57-.434,8.451,8.451,0,0,1,6.832-6.184A8.747,8.747,0,0,1,19.62,7.4l-4.954-.228a.6.6,0,0,0-.432.155.552.552,0,0,0-.181.408V9.954a.575.575,0,0,0,.586.562h9.776A.575.575,0,0,0,25,9.954V.563A.575.575,0,0,0,24.414,0H22.1a.6.6,0,0,0-.424.174.55.55,0,0,0-.161.415ZM12.492,20.25A8.677,8.677,0,0,1,5.363,16.6l4.971.228a.6.6,0,0,0,.432-.155.552.552,0,0,0,.181-.408V14.047a.575.575,0,0,0-.586-.562H.586A.575.575,0,0,0,0,14.047v9.39A.575.575,0,0,0,.586,24H2.9a.6.6,0,0,0,.425-.175.55.55,0,0,0,.161-.416l-.2-3.87a12.282,12.282,0,0,0,9.21,4.086,11.986,11.986,0,0,0,11.892-9.46.546.546,0,0,0-.126-.462.6.6,0,0,0-.45-.2h-2.4a.582.582,0,0,0-.57.434,8.525,8.525,0,0,1-8.351,6.316Z"
      transform="translate(0.05 0.05)"
      fill="#fff"
      stroke="#fff"
      strokeWidth="0.1"
    />
  </svg>
);
export const SettingIcon: React.FC<IIcon> = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="26.115"
    viewBox="0 0 25 26.115"
  >
    <g id="그룹_63" data-name="그룹 63" transform="translate(-376 -283)">
      <path
        id="FontAwsome_cog_"
        data-name="FontAwsome (cog)"
        d="M43.325,24.295,41.082,23a10.142,10.142,0,0,0,0-3.7l2.243-1.3a.636.636,0,0,0,.29-.737,13.113,13.113,0,0,0-2.88-4.981.634.634,0,0,0-.779-.121l-2.243,1.3a9.937,9.937,0,0,0-3.2-1.848V9.031a.631.631,0,0,0-.495-.616,13.236,13.236,0,0,0-5.75,0,.631.631,0,0,0-.495.616v2.59a10.25,10.25,0,0,0-3.2,1.848l-2.238-1.3a.626.626,0,0,0-.779.121,13.034,13.034,0,0,0-2.88,4.981.63.63,0,0,0,.29.737l2.243,1.3a10.142,10.142,0,0,0,0,3.7l-2.243,1.3a.636.636,0,0,0-.29.737,13.113,13.113,0,0,0,2.88,4.981.634.634,0,0,0,.779.121l2.243-1.3a9.937,9.937,0,0,0,3.2,1.848v2.59a.631.631,0,0,0,.495.616,13.236,13.236,0,0,0,5.75,0,.631.631,0,0,0,.495-.616v-2.59a10.25,10.25,0,0,0,3.2-1.848l2.243,1.3a.626.626,0,0,0,.779-.121,13.034,13.034,0,0,0,2.88-4.981A.65.65,0,0,0,43.325,24.295ZM31.141,25.364a4.212,4.212,0,1,1,4.212-4.212A4.218,4.218,0,0,1,31.141,25.364Z"
        transform="translate(357.356 274.901)"
      />
    </g>
  </svg>
);

export const AppStoreIcon: React.FC<IIcon> = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    height="38"
    viewBox="0 0 40 47.6"
  >
    <path
      id="FontAwsome_apple_"
      data-name="FontAwsome (apple)"
      d="M37.443,57.154c-.021-3.9,1.743-6.844,5.313-9.012-2-2.859-5.016-4.431-9-4.74-3.773-.3-7.9,2.2-9.4,2.2-1.594,0-5.25-2.094-8.119-2.094C10.3,43.6,4,48.238,4,57.664a26.487,26.487,0,0,0,1.53,8.629c1.36,3.9,6.27,13.464,11.392,13.305,2.678-.064,4.57-1.9,8.055-1.9,3.379,0,5.133,1.9,8.119,1.9C38.261,79.524,42.7,70.831,44,66.92c-6.929-3.262-6.557-9.564-6.557-9.766ZM31.428,39.7a10.009,10.009,0,0,0,2.55-7.7,11.268,11.268,0,0,0-7.216,3.709,10.166,10.166,0,0,0-2.721,7.641C26.816,43.562,29.345,42.138,31.428,39.7Z"
      transform="translate(-4 -32)"
    />
  </svg>
);

export const PlaystoreIcon: React.FC<IIcon> = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    height="34"
    viewBox="0 0 38 43.429"
  >
    <path
      id="FontAwsome_google-play_"
      data-name="FontAwsome (google-play)"
      d="M50,19.878,31.83,1.1,54.954,14.779ZM27.087,0A3.309,3.309,0,0,0,25.3,2.995V40.434a3.309,3.309,0,0,0,1.787,2.995L48.218,21.71ZM62.1,19.139l-4.85-2.893-5.41,5.472,5.41,5.472L62.2,24.3A3.471,3.471,0,0,0,62.1,19.139ZM31.83,42.334,54.954,28.658,50,23.56Z"
      transform="translate(-25.3)"
    />
  </svg>
);

export const CopyClipboardIcon: React.FC<IIcon> = (props) => {
  return (
    <svg
      {...props}
      style={{ cursor: "pointer" }}
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="24"
      viewBox="0 0 21 24"
    >
      <path
        id="FontAwsome_copy_"
        data-name="FontAwsome (copy)"
        d="M15,21v1.875A1.125,1.125,0,0,1,13.875,24H1.125A1.125,1.125,0,0,1,0,22.875V5.625A1.125,1.125,0,0,1,1.125,4.5H4.5V18.375A2.628,2.628,0,0,0,7.125,21ZM15,4.875V0H7.125A1.125,1.125,0,0,0,6,1.125v17.25A1.125,1.125,0,0,0,7.125,19.5h12.75A1.125,1.125,0,0,0,21,18.375V6H16.125A1.128,1.128,0,0,1,15,4.875Zm5.671-1.454L17.579.329a1.125,1.125,0,0,0-.8-.329H16.5V4.5H21V4.216a1.125,1.125,0,0,0-.329-.8Z"
      />
    </svg>
  );
};

export const SaveIcon: React.FC<IIcon> = (props) => (
  <svg
    {...props}
    id="Layer_2"
    className="save"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
  >
    <g id="invisible_box" data-name="invisible box">
      <rect
        id="사각형_20"
        data-name="사각형 20"
        width="30"
        height="30"
        fill="none"
      />
    </g>
    <g id="icons_Q2" data-name="icons Q2" transform="translate(1.401 1.78)">
      <path
        id="패스_2"
        data-name="패스 2"
        d="M15.409,2a13.349,13.349,0,1,0,9.5,3.91A13.409,13.409,0,0,0,15.409,2Zm6.949,10-8.533,8.533a1.158,1.158,0,0,1-1.707,0l-3.6-3.6a1.341,1.341,0,0,1-.244-1.646,1.219,1.219,0,0,1,1.89-.122l2.8,2.8,7.68-7.68A1.219,1.219,0,0,1,22.358,12Z"
        transform="translate(-2 -2)"
      />
    </g>
  </svg>
);

export const FailedIcon: React.FC<IIcon> = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM99.5 144.8C77.15 176.1 64 214.5 64 256C64 362 149.1 448 256 448C297.5 448 335.9 434.9 367.2 412.5L99.5 144.8zM448 256C448 149.1 362 64 256 64C214.5 64 176.1 77.15 144.8 99.5L412.5 367.2C434.9 335.9 448 297.5 448 256V256z" />
  </svg>
);
export const ImgIcon: React.FC<IIcon> = (props) => (
  <svg
    {...props}
    style={{ position: "absolute", top: 20, left: 20 }}
    xmlns="http://www.w3.org/2000/svg"
    width="30.857"
    height="24"
    viewBox="0 0 30.857 24"
  >
    <path
      id="FontAwsome_images_"
      data-name="FontAwsome (images)"
      d="M25.714,52.571v.857A2.571,2.571,0,0,1,23.143,56H2.571A2.571,2.571,0,0,1,0,53.429V39.714a2.571,2.571,0,0,1,2.571-2.571h.857V48.286a4.291,4.291,0,0,0,4.286,4.286Zm5.143-4.286V34.571A2.571,2.571,0,0,0,28.286,32H7.714a2.571,2.571,0,0,0-2.571,2.571V48.286a2.571,2.571,0,0,0,2.571,2.571H28.286A2.571,2.571,0,0,0,30.857,48.286ZM13.714,37.143a2.571,2.571,0,1,1-2.571-2.571A2.571,2.571,0,0,1,13.714,37.143ZM8.571,44.857l2.974-2.974a.643.643,0,0,1,.909,0L14.571,44l7.26-7.26a.643.643,0,0,1,.909,0l4.688,4.688v6H8.571Z"
      transform="translate(0 -32)"
      fill="#fff"
    />
  </svg>
);

export const VideoIcon: React.FC<IIcon> = (props) => (
  <svg
    {...props}
    style={{ position: "absolute", top: 20, left: 20 }}
    xmlns="http://www.w3.org/2000/svg"
    width="34.133"
    height="24"
    viewBox="0 0 34.133 24"
  >
    <path
      id="FontAwsome_youtube_"
      data-name="FontAwsome (youtube)"
      d="M48.353,67.755a4.289,4.289,0,0,0-3.018-3.037C42.674,64,32,64,32,64s-10.674,0-13.336.718a4.289,4.289,0,0,0-3.018,3.037,48.29,48.29,0,0,0,0,16.538,4.225,4.225,0,0,0,3.018,2.989C21.326,88,32,88,32,88s10.674,0,13.336-.718a4.225,4.225,0,0,0,3.018-2.989,48.29,48.29,0,0,0,0-16.538ZM28.509,81.1V70.949l8.921,5.075L28.509,81.1Z"
      transform="translate(-14.933 -64)"
      fill="#fff"
    />
  </svg>
);

export const PlusIcon: React.FC<IIcon> = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="30.87"
    height="30.87"
    viewBox="0 0 30.87 30.87"
  >
    <g
      id="그룹_134"
      data-name="그룹 134"
      transform="translate(2 15.435) rotate(-45)"
    >
      <line
        id="선_21"
        data-name="선 21"
        x2="19"
        y2="19"
        fill="none"
        stroke="#000"
        strokeWidth="4"
      />
      <line
        id="선_22"
        data-name="선 22"
        x2="19"
        y2="19"
        transform="translate(19) rotate(90)"
        fill="none"
        stroke="#000"
        strokeWidth="4"
      />
    </g>
  </svg>
);

export const CautionIcon: React.FC<IIcon> = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="36.603"
    height="32.536"
    viewBox="0 0 36.603 32.536"
  >
    <path
      id="FontAwsome_exclamation-triangle_"
      data-name="FontAwsome (exclamation-triangle)"
      d="M36.191,27.961a3.052,3.052,0,0,1-2.642,4.575H3.053A3.052,3.052,0,0,1,.411,27.961L15.659,1.524a3.052,3.052,0,0,1,5.284,0L36.191,27.961ZM18.3,22.5a2.923,2.923,0,1,0,2.923,2.923A2.923,2.923,0,0,0,18.3,22.5ZM15.526,11.988,16,20.631a.763.763,0,0,0,.761.721h3.085a.763.763,0,0,0,.761-.721l.471-8.642a.763.763,0,0,0-.761-.8H16.287a.763.763,0,0,0-.761.8Z"
      transform="translate(0)"
    />
  </svg>
);

export const VisibleIcon: React.FC<IIcon> = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="21.333"
    viewBox="0 0 32 21.333"
  >
    <path
      id="FontAwsome_eye_"
      data-name="FontAwsome (eye)"
      d="M31.807,73.856A17.819,17.819,0,0,0,16,64,17.822,17.822,0,0,0,.194,73.856a1.8,1.8,0,0,0,0,1.622A17.819,17.819,0,0,0,16,85.333a17.822,17.822,0,0,0,15.807-9.856,1.8,1.8,0,0,0,0-1.622ZM16,82.667a8,8,0,1,1,8-8A8,8,0,0,1,16,82.667Zm0-13.333a5.3,5.3,0,0,0-1.406.211,2.658,2.658,0,0,1-3.717,3.717A5.321,5.321,0,1,0,16,69.333Z"
      transform="translate(-0.001 -64)"
    />
  </svg>
);
export const UnvisibleIcon: React.FC<IIcon> = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="28.8"
    viewBox="0 0 36 28.8"
  >
    <path
      id="FontAwsome_eye-slash_"
      data-name="FontAwsome (eye-slash)"
      d="M18,22.5a8.07,8.07,0,0,1-8.038-7.487l-5.9-4.56A18.748,18.748,0,0,0,2,13.576a1.82,1.82,0,0,0,0,1.642A18.041,18.041,0,0,0,18,25.2a17.472,17.472,0,0,0,4.381-.588L19.464,22.35A8.108,8.108,0,0,1,18,22.5Zm17.652,3.268-6.218-4.806a18.633,18.633,0,0,0,4.57-5.741,1.82,1.82,0,0,0,0-1.642A18.041,18.041,0,0,0,18,3.6,17.333,17.333,0,0,0,9.715,5.717L2.559.186A.9.9,0,0,0,1.3.344L.191,1.766A.9.9,0,0,0,.349,3.029l33.1,25.578a.9.9,0,0,0,1.263-.158l1.1-1.421A.9.9,0,0,0,35.654,25.765ZM25.319,17.777l-2.211-1.709A5.33,5.33,0,0,0,16.578,9.21,2.68,2.68,0,0,1,17.1,10.8a2.624,2.624,0,0,1-.087.562l-4.141-3.2A8,8,0,0,1,18,6.3a8.1,8.1,0,0,1,8.1,8.1,7.908,7.908,0,0,1-.782,3.381Z"
      transform="translate(-0.001 0.003)"
    />
  </svg>
);

export const SomethingIcon: React.FC<IIcon> = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path d="M256 448c141.4 0 256-93.1 256-208S397.4 32 256 32S0 125.1 0 240c0 45.1 17.7 86.8 47.7 120.9c-1.9 24.5-11.4 46.3-21.4 62.9c-5.5 9.2-11.1 16.6-15.2 21.6c-2.1 2.5-3.7 4.4-4.9 5.7c-.6 .6-1 1.1-1.3 1.4l-.3 .3 0 0 0 0 0 0 0 0c-4.6 4.6-5.9 11.4-3.4 17.4c2.5 6 8.3 9.9 14.8 9.9c28.7 0 57.6-8.9 81.6-19.3c22.9-10 42.4-21.9 54.3-30.6c31.8 11.5 67 17.9 104.1 17.9zM128 272c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32zm128 0c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32zm160-32c0 17.7-14.3 32-32 32s-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32z" />
  </svg>
);

export const ExclamationIcon: React.FC<IIcon> = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    viewBox="0 0 512 512"
    fill="rgba(255,70,70,0.8)"
    style={{ position: "absolute", bottom: 20, right: 20 }}
  >
    <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zm32 224c0 17.7-14.3 32-32 32s-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32z" />
  </svg>
);

export const QuestionIcon: React.FC<IIcon> = (props) => (
  <svg
    {...props}
    width="40"
    fill="white"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM169.8 165.3c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM288 352c0 17.7-14.3 32-32 32s-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32z" />
  </svg>
);

import React, { ButtonHTMLAttributes } from "react";
import {
  Instruction,
  ModalBtn,
  ModalBtnsBox,
  ModalContainer,
} from "../ModalStyled";
import { CautionIcon } from "../../SVGs";
import { ModalTerm } from "../Modal";

interface IModalBase extends ButtonHTMLAttributes<HTMLButtonElement> {
  term: ModalTerm;
}

export const linebreak = (content: string) => (
  <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
    {content.split("\n").map((des) => (
      <span>{des}</span>
    ))}
  </div>
);

const ModalBase: React.FC<IModalBase> = ({
  term: { instruction, buttons },
  ...props
}) => {
  return (
    <ModalContainer>
      <Instruction>
        <CautionIcon />
        {linebreak(instruction)}
      </Instruction>
      <ModalBtnsBox>
        {buttons.map(({ name, type }) => (
          <ModalBtn
            className={`${name} ${type}`}
            key={type}
            onClick={props.onClick}
          >
            {name || "undefined"}
          </ModalBtn>
        ))}
      </ModalBtnsBox>
    </ModalContainer>
  );
};

export default ModalBase;

import { useMemo } from "react";
import { useQueryClient } from "react-query";
import { useAppDispatch, useAppSelector } from "../../../../../modules";
import { ICP_data } from "../../../../../api/V2/3.CrossPromotion/API_CP";
import querykeys from "../../../../../api/V2/query";

import pagedata from "../CrossPromotionPageData.json";
import {
  Description,
  Label,
} from "../../../Interactions/Inputs/MayoInputStyled";
import { Flexbox, NumberInput } from "../CrossPromotionStyled";
import { CP_dataActions } from "../../../../../modules/3.CrossPromotion/CPDatas";

const {
  bannerForceShow: { label, description },
} = pagedata;

const BannerShowForce = () => {
  const platform = useAppSelector((state) => state.platform);
  const CP_data = useAppSelector((state) => state.CP_datas[platform]);
  const dispatch = useAppDispatch();

  const queryClient = useQueryClient();
  const fetchedCPdata = queryClient.getQueryData<ICP_data>([
    querykeys.cross_promotion.data,
    CP_data.id,
  ]);
  const defaultValue = useMemo(
    () => (fetchedCPdata ? fetchedCPdata.keep_time / 1000 : 0),
    [fetchedCPdata]
  );
  const isChanged = useMemo(
    () => defaultValue * 1000 !== CP_data.keep_time,
    [CP_data.keep_time, fetchedCPdata]
  );

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      currentTarget: { value },
    } = event;
    dispatch(CP_dataActions.setCP_keep_time(parseInt(value) * 1000, platform));
  };

  return (
    <>
      <Label id="CP_keep_time" className={isChanged ? "changed" : ""}>
        {label}
        <Description>{description}</Description>
        <Flexbox>
          <NumberInput
            type="number"
            min="0"
            defaultValue={defaultValue}
            onChange={onChange}
          />
          초
        </Flexbox>
      </Label>
    </>
  );
};

export default BannerShowForce;

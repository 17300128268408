import { useQuery } from "react-query";
import { getHallOfFame } from "../../../../../../api/V2/5.Rankboard/API_Rank";
import querykeys from "../../../../../../api/V2/query";
import { FormLoader } from "../../../../Interactions/Loaders";
import NoData from "../../../../Interactions/notification/NoData";
import DataRow from "../dataComponents/TableRow";

interface IHallTableRowProps {
  page: number;
  board_id: string;
}

function Hall({ board_id, page }: IHallTableRowProps) {
  const { isLoading, isFetching, isFetched, data } = useQuery(
    [querykeys.rankboard, board_id, "hall", page],
    () =>
      getHallOfFame({ board_id, from: (page - 1) * 10, count: 10 }).then(
        (response) => response.data
      ),
    { enabled: board_id !== undefined, retry: 1 }
  );

  return (
    <>
      {isLoading || isFetching ? (
        <FormLoader />
      ) : isFetched ? (
        data?.length !== 0 ? (
          data?.map((data, index) => (
            <DataRow
              key={board_id + index + page}
              data={data}
              board_id={board_id}
              index={index}
              page={page}
              type="hall"
            />
          ))
        ) : (
          <NoData key={"No data"} content={"보드가 텅 비었어요."} />
        )
      ) : (
        <NoData
          key={"can't load data"}
          content={"데이터를 불러오지 못했습니다."}
        />
      )}
    </>
  );
}

export default Hall;

import styled from "styled-components";

export const ToggelLayout = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
`;

export const LanguageLayout = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 120px 1fr;
  & section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  & select {
    margin-top: 1rem;
  }
`;

export const DefaultLanguage = styled.div`
  width: fit-content;
  min-width: 90px;
  height: fit-content;
  padding: 36px 0 23px 0;
  font-size: 1.5rem;
  font-weight: 600;
  border-bottom: 4px solid ${(props) => props.theme.border.base};
`;

export const Label = styled.label<{ after?: string }>`
  height: fit-content;
  width: fit-content;
  position: relative;
  display: flex;
  justify-content: center;
  gap: 0.6rem;
  font-size: 1.5rem;
  font-weight: 700;
  background-color: inherit;
  cursor: pointer;
  &.multi-platform::after {
    content: "플랫폼 공통";
    position: absolute;
    top: -4px;
    left: 200px;
    width: fit-content;
    padding: 8px 14px;
    font-size: 1.2rem;
    color: ${(props) => props.theme.text.reversal};
    background-color: ${(props) => props.theme.backgroundColor.reversal};
    border-radius: 12px;
  }
  &.changed::before {
    display: block;
    position: absolute;
    content: "";
    top: 4px;
    left: -25px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.input.changed};
  }
`;
export const Description = styled.div`
  margin-top: 1rem;
  font-size: 1.2rem;
  font-weight: 500;
  color: ${(props) => props.theme.dimColor};
  & span {
    display: block;
  }
`;

export const TextInput = styled.input`
  position: relative;
  max-width: 100%;
  width: fit-content;
  min-width: 500px;
  margin-top: 1rem;
  padding: 1rem 0px;
  border: none;
  border-bottom: 4px solid black;
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: -1px;
  & + span {
    position: absolute;
    left: 0px;
    top: 2rem;
    font-size: 1.2rem;
    color: ${(props) => props.theme.noti.caution};
    opacity: 0;
  }
  &[type="url"] {
    min-width: 800px;
  }
  &:invalid {
    background-position-x: right;
    background-position-y: center;
    background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='36.603' height='32.536' viewBox='0 0 36.603 32.536' %3E%3Cpath id='FontAwsome_exclamation-triangle_' data-name='FontAwsome (exclamation-triangle)' d='M36.191,27.961a3.052,3.052,0,0,1-2.642,4.575H3.053A3.052,3.052,0,0,1,.411,27.961L15.659,1.524a3.052,3.052,0,0,1,5.284,0L36.191,27.961ZM18.3,22.5a2.923,2.923,0,1,0,2.923,2.923A2.923,2.923,0,0,0,18.3,22.5ZM15.526,11.988,16,20.631a.763.763,0,0,0,.761.721h3.085a.763.763,0,0,0,.761-.721l.471-8.642a.763.763,0,0,0-.761-.8H16.287a.763.763,0,0,0-.761.8Z' transform='translate(0)' fill='rgba(238,113,39,0.8)' /%3E%3C/svg%3E");
    background-repeat: no-repeat;
  }
  &:invalid + span {
    opacity: 1;
  }
`;

export const LanguageInput = styled(TextInput)`
  &.title {
    min-width: 200px;
  }
  &.description {
    width: 100%;
    min-width: 100%;
  }
`;

export const Toggle = styled.input`
  position: relative;
  top: -2px;
  left: 60px;
  width: 0px;
  height: 0px;
  z-index: 1;
  &::before {
    position: absolute;
    content: "";
    top: 0;
    right: 5px;
    width: 60px;
    height: 36.6px;
    border-radius: 19px;
    background-color: ${(props) => props.theme.toggle.unchecked};
    pointer-events: none;
    z-index: 1;
    cursor: pointer;
  }
  &:checked::before {
    background-color: ${(props) => props.theme.toggle.checked};
  }
  &::after {
    position: absolute;
    content: "";
    top: 5.8px;
    right: 33px;
    width: 25px;
    height: 25px;
    border-radius: 12.5px;
    background-color: ${(props) => props.theme.toggle.egg};
    pointer-events: none;
    transition: 0.36s;
    z-index: 1;
  }
  &:checked::after {
    right: 13px;
  }
`;

export const ToggleArea = styled.label`
  position: relative;
  width: 60px;
  height: 36.6px;
  cursor: pointer;
`;

export const InputIconField = styled.div<{ src?: string }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  margin-top: 20px;
  border-radius: 20px;
  background-color: ${(props) => (!!props.src ? "white" : "black")};
  background-image: url(${(props) => props.src});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  font-size: 40px;
  color: ${(props) => props.theme.text.reversal};
  & input[type="file"] {
    width: 100%;
    height: 100%;
    border: none;
    opacity: 0;
    position: absolute;
    cursor: pointer;
    z-index: 1;
  }
  & svg {
    -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
    filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
    z-index: 3;
  }
`;

export const InputMediaField = styled.label`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 320px;
  height: 216px;
  border-radius: 20px;
  background-color: black;
  font-size: 2rem;
  color: ${(props) => props.theme.backgroundColor.base};
  z-index: 1;
  cursor: pointer;
  & div.blank {
    position: absolute;
    top: -120px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
  }
  & input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: none;
    opacity: 0;
    border-radius: 20px;
    cursor: pointer;
    z-index: 2;
  }
  & svg {
    -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
    filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
    z-index: 3;
  }
  & svg line {
    stroke: white;
  }
`;

export const Image = styled.div<{ src?: string }>`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background-color: ${(props) => (!!props.src ? "white" : "black")};
  background-image: url(${(props) => props.src});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  filter: grayscale(50%) blur(1px);
  z-index: 0;
`;
export const Video = styled.video`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  filter: grayscale(50%) blur(1px);
  z-index: 0;
`;

export const Details = styled.details`
  position: relative;
  display: flex;
  align-items: center;
  outline: none;
  cursor: pointer;
  /* &::before {
    position: absolute;
    content: "";
    right: 5px;
    width: 60px;
    height: 36.6px;
    border-radius: 19px;
    background-color: ${(props) => props.theme.toggle.unchecked};
    pointer-events: none;
    z-index: 1;
    cursor: pointer;
  }
  &[open]::before {
    background-color: ${(props) => props.theme.toggle.checked};
  }
  &::after {
    position: absolute;
    content: "";
    top: 5.8px;
    right: 33px;
    width: 25px;
    height: 25px;
    border-radius: 12.5px;
    background-color: ${(props) => props.theme.toggle.egg};
    pointer-events: none;
    transition: 0.36s;
    z-index: 1;
  }
  &[open]::after {
    right: 13px;
  } */
`;

export const Summary = styled.summary`
  letter-spacing: -2.5px;
  list-style: none;
  z-index: 1;
`;

export const Fieldset = styled.fieldset`
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
  margin-top: 3rem;
  margin-left: 3rem;
`;

export const AddLanguageBtn = styled.button`
  max-width: 1200px;
  width: 100%;
  border: none;
  font-size: 2.5rem;
  cursor: pointer;
`;

export const DeleteLangBtn = styled.button`
  position: absolute;
  padding-left: 150px;
  padding-bottom: 60px;
  top: 16px;
  right: 16px;
  font-size: 2rem;
  font-weight: 800;
  border: none;
  opacity: 0;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
  &:active {
    color: red;
  }
`;

export const ResourceError = styled.div`
  position: absolute;
  bottom: -20px;
  right: 5px;
  font-size: 1rem;
  color: ${({ theme }) => theme.noti.caution};
`;

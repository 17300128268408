import React from "react";
import { PlusIcon } from "../../SVGs";
import { Header, Title } from "./StickyHeaderStyled";

interface IStickyHeader {
  title: string;
  onClick: React.MouseEventHandler<HTMLSpanElement>;
}

const StickyHeader: React.FC<IStickyHeader> = ({ title, onClick }) => {
  return (
    <Header>
      <Title>
        {title}
        <span onClick={onClick}>
          <PlusIcon />
        </span>
      </Title>
    </Header>
  );
};

export default React.memo(StickyHeader);

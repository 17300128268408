import { Reducer } from "redux";

export type CPLinkQueue = {
  cp_id: string;
  link_id: string;
  id?: string;
};

export interface CPLinks {
  default: CPLinkQueue[];
  current: CPLinkQueue[];
}

const initialState: CPLinks = {
  default: [],
  current: [],
};

type CPLinksAction = ReturnType<
  | typeof setLinkQueue
  | typeof initDefaultLinks
  | typeof addDefaultLink
  | typeof deleteDefaultLink
  | typeof clearLinkQueue
>;

/**
 * Dispatch : set existing Queues
 * @param queues
 */
const initDefaultLinks = (links: CPLinkQueue[]) => ({
  type: "initDefaultLinks" as const,
  payload: links,
});
/**
 * Dispatch : set Queues to make new CP Link for current_game_cp_id + target_game_cp_id
 * @param queues
 */
const setLinkQueue = (queues: CPLinkQueue[]) => ({
  type: "setLinkQueue" as const,
  payload: queues,
});

/**
 * Dispatch : set existing Queues
 * @param queues
 */
const addDefaultLink = (link: CPLinkQueue) => ({
  type: "addDefaultLink" as const,
  payload: link,
});

const deleteDefaultLink = (link_id: CPLinkQueue["id"]) => ({
  type: "deleteDefaultLink" as const,
  payload: link_id,
});
/**
 * Dispatch : Clear Queue
 */
const clearLinkQueue = () => ({
  type: "clearLinkQueue" as const,
  payload: initialState,
});

const CP_linkQueues: Reducer<CPLinks, CPLinksAction> = (
  state = initialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case "initDefaultLinks":
      return { current: payload, default: payload };
    case "setLinkQueue":
      return { ...state, current: [...payload] };
    case "addDefaultLink":
      return { ...state, default: [...state.default, payload] };
    case "deleteDefaultLink":
      return {
        ...state,
        default: [...state.default.filter((data) => data.id !== payload)],
      };
    case "clearLinkQueue":
      return initialState;
    default:
      return state;
  }
};

export default CP_linkQueues;

export const CP_linkActions = {
  setLinkQueue,
  initDefaultLinks,
  addDefaultLink,
  deleteDefaultLink,
  clearLinkQueue,
};

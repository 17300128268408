import styled from "styled-components";

export const Header = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: max-content;
  min-height: 65px;
  padding-bottom: 20px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  background-color: white;
  z-index: 3;
  & svg {
    margin-left: 1rem;
    cursor: pointer;
  }
  &::before {
    content: "";
    position: absolute;
    left: -40px;
    width: 40px;
    height: 120px;
    background-color: inherit;
  }
`;
export const Title = styled.div`
  display: flex;
  font-size: 2rem;
  font-weight: 600;
  column-gap: 20px;
  white-space: nowrap;
`;

export const Tabs = styled.div`
  display: flex;
  justify-self: flex-end;
`;

export const Tab = styled.div<{ valid?: boolean; current: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  height: 50px;
  font-size: 1.6rem;
  color: ${(props) =>
    props.valid
      ? props.current
        ? props.theme.text.reversal
        : props.theme.text.normal
      : props.theme.dimColor};
  background-color: ${(props) =>
    props.current
      ? props.theme.backgroundColor.reversal
      : props.theme.backgroundColor.base};
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom: 5px solid ${(props) => props.theme.color};
  pointer-events: ${(props) => (props.valid ? "auto" : "none")};
  cursor: ${(props) => (props.valid ? "pointer" : "not-allowed")};
`;

import { AxiosError } from "axios";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { ICP_data } from "../../../../../api/V2/3.CrossPromotion/API_CP";
import cp_textAPI, {
  Language,
} from "../../../../../api/V2/3.CrossPromotion/API_CPText";
import querykeys from "../../../../../api/V2/query";
import { useAppDispatch, useAppSelector } from "../../../../../modules";
import { CP_dataActions } from "../../../../../modules/3.CrossPromotion/CPDatas";
import { modalActions } from "../../../../../modules/modal";
import {
  AddLanguageBtn,
  Description,
  Label,
} from "../../../Interactions/Inputs/MayoInputStyled";
import MayoLanguageInput from "../../../Interactions/Inputs/MayoLanguageInput";
import pageData from "../CrossPromotionPageData.json";

const {
  bannerComments: { label, description },
} = pageData;

const initials: Language[] = [
  "ko",
  "ja",
  "ru",
  "de",
  "es",
  "fr",
  "it",
  "pt",
  "tr",
  "zh-chs",
  "zh-cht",
  "th",
];

interface IBannerPhrases {}

const BannerPhrases: React.FC<IBannerPhrases> = () => {
  //Local State
  const [isChanged, setIsChanged] = useState(false);
  //State
  const platform = useAppSelector((state) => state.platform);
  const CP_data = useAppSelector((state) => state.CP_datas[platform]);
  const dispatch = useAppDispatch();
  //destruction State
  const { addCP_textData, editCP_textLang, deleteCP_textData } = CP_dataActions;
  const { setWarningModalOn, setDeleteModalOn } = modalActions;
  //LocalState
  const [actives, setActives] = useState<Language[]>([]);
  const remainLangs = initials.filter((lang) => !actives.includes(lang));

  //Queried
  const queryClient = useQueryClient();
  const defaultCP_data = queryClient.getQueryData<ICP_data>([
    querykeys.cross_promotion.data,
    CP_data.id,
  ]);
  //LifeCycle
  //렌더링시 현재 게임에서 해당 플랫폼 cross promotion ID 가 있는지 확인 후에, 있을 경우에는 data를 불러옵니다.
  useEffect(() => {
    const currentLangs = CP_data.cpTextDatas
      .map((data) => data.language)
      .filter((lang) => lang !== "en");
    setActives(currentLangs);
    return () => {
      setActives([]);
    };
  }, [CP_data]);

  useEffect(() => {
    const before = !!defaultCP_data
      ? defaultCP_data.cpTextDatas.map(({ language, title, script }) => {
          return {
            language,
            title,
            script,
          };
        })
      : [];
    const current = CP_data.cpTextDatas.map(({ language, title, script }) => {
      return {
        language,
        title,
        script,
      };
    });
    console.log("PHRASES :", current, before);
    const result = JSON.stringify(before) !== JSON.stringify(current);
    setIsChanged(result);
  }, [defaultCP_data, CP_data]);

  //Function
  const onLanguageChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    index: number
  ) => {
    const {
      currentTarget: { value },
    } = event;
    //language 바뀔 때 방식??
    dispatch(editCP_textLang(actives[index], value as Language, platform));
    setActives((state) => {
      const result = [...state];
      result.splice(index, 1, value as Language);
      return result;
    });
  };

  const onAddLanguageBtnClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    if (!remainLangs[0]) return;
    setActives((state) => [...state, remainLangs[0]]);
    dispatch(addCP_textData({ language: remainLangs[0], title: "" }, platform));
  };

  const onDeleteLanguage = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number
  ) => {
    const {
      currentTarget: { name: text_id },
    } = event;
    event.preventDefault();
    const target = actives[index];
    //경고 메시지. + id 기반 삭제 요청
    const deleteState = () => {
      dispatch(deleteCP_textData(target, platform));
      setActives((state) => [...state].filter((v) => v !== target));
    };

    const deleteFailed = (error: AxiosError) => {
      if (error.response?.status !== 401) {
        throw new Error(
          "삭제에 실패했습니다. 응답 코드 : " + error.response?.status ||
            error.response?.statusText
        );
      } else {
        console.log("refreshing Token...");
      }
    };

    const deleteText = async (id: string) => {
      try {
        await cp_textAPI.delete(id).catch(deleteFailed);
        deleteState();
      } catch (error) {
        dispatch(setWarningModalOn(error + ""));
      }
    };
    if (text_id !== "") dispatch(setDeleteModalOn(() => deleteText(text_id)));
    if (text_id === "") deleteState();
  };

  return (
    <>
      <Label id="CP_text_data" className={isChanged ? "changed" : ""}>
        {label}
        <Description>
          {description.split("\n").map((des) => (
            <span key={des}>{des}</span>
          ))}
        </Description>
      </Label>
      <MayoLanguageInput defaultLanguage lang="en" />
      {actives.map((lang, index) => {
        return (
          <MayoLanguageInput
            key={lang + index}
            name="language"
            lang={lang}
            languages={initials.filter((lang) => !actives.includes(lang))}
            onChange={(event) => onLanguageChange(event, index)}
            onDelete={(event) => onDeleteLanguage(event, index)}
          />
        );
      })}
      <AddLanguageBtn type="button" onClick={onAddLanguageBtnClick}>
        +
      </AddLanguageBtn>
    </>
  );
};

export default BannerPhrases;

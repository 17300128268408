import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../modules";
import { useQueryClient } from "react-query";
import querykeys from "../../../../../api/V2/query";
import { ICP_data } from "../../../../../api/V2/3.CrossPromotion/API_CP";
import { CP_dataActions } from "../../../../../modules/3.CrossPromotion/CPDatas";
import { modalActions } from "../../../../../modules/modal";
import MayoToggleInput from "../../../Interactions/Inputs/MayoToggleInput";
import { bannerActive } from "../CrossPromotionPageData.json";

const { label, description, name } = bannerActive;

interface IBannerActiveSet {}

const BannerActiveSet: React.FC<IBannerActiveSet> = () => {
  //State
  const platform = useAppSelector((state) => state.platform);
  const CP_data = useAppSelector((state) => state.CP_datas[platform]);
  const CP_resources = useAppSelector((state) => state.CP_resources);
  const dispatch = useAppDispatch();

  //destruction State
  const { id: cp_id } = CP_data;
  const { setCP_is_public } = CP_dataActions;
  const { setWarningModalOn } = modalActions;

  //Query
  const queryClient = useQueryClient();
  const fetchedCPdata = queryClient.getQueryData<ICP_data>([
    querykeys.cross_promotion.data,
    cp_id,
  ]);

  //LifeCycle
  useEffect(() => {
    if (fetchedCPdata)
      dispatch(setCP_is_public(fetchedCPdata.is_public, platform));
  }, [fetchedCPdata]);

  //Function
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      currentTarget: { checked: is_public },
    } = event;
    //미디어 파일이 있는 경우에만 온이 되도록 추가.
    const { image, video } = CP_resources;
    if (!!image.file && !!video.file) {
      return dispatch(setCP_is_public(is_public, platform));
    } else {
      return dispatch(
        setWarningModalOn(
          "등록된 배너이미지 또는 비디오가 없어 활성화할 수 없습니다. \n 배너이미지 또는 비디오를 등록해주세요."
        )
      );
    }
  };

  return (
    <>
      <MayoToggleInput
        id="CP_is_public"
        label={label}
        description={description}
        name={name}
        checked={CP_data.is_public}
        beforeChecked={fetchedCPdata?.is_public || false}
        onChange={onChange}
      />
    </>
  );
};

export default BannerActiveSet;

import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../modules";
import { CP_dataActions } from "../../../../modules/3.CrossPromotion/CPDatas";
import { CP_resourceActions } from "../../../../modules/3.CrossPromotion/CPResources";
import { CP_linkActions } from "../../../../modules/3.CrossPromotion/CPLinkQueue";
import { Container } from "./CrossPromotionStyled";
import StickyPlatformHeader from "../../Interactions/StickyHeader/StickyPlatformHeader";
import CPForm from "./CrossPromotionForm";
import { header } from "./CrossPromotionPageData.json";
import BannerMedias from "./components/BannerMedias";

const { title } = header;

function CrossPromotion() {
  //State
  const platform = useAppSelector((state) => state.platform);
  const dispatch = useAppDispatch();

  //destruction State
  const { clearCP } = CP_dataActions;
  const { clearCP_uploadFiles } = CP_resourceActions;
  const { clearLinkQueue } = CP_linkActions;

  useEffect(() => {
    return () => {
      dispatch(clearCP());
      dispatch(clearCP_uploadFiles());
      dispatch(clearLinkQueue());
    };
  }, []);

  return (
    <Container>
      <StickyPlatformHeader key={title} title={title} />
      <div style={{ marginTop: 50 }}>
        <CPForm key={platform} />
      </div>
    </Container>
  );
}

export default React.memo(CrossPromotion);

import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../modules";
import { modalActions } from "../../../../modules/modal";
import { ModalTerm } from "../Modal";
import {
  Instruction,
  ModalBtn,
  ModalBtnsBox,
  ModalContainer,
} from "../ModalStyled";
import { CautionIcon } from "../../SVGs";
import { linebreak } from "./ModalBase";
import modalTerms from "../ModalTerms.json";

const unsaved_term: ModalTerm = modalTerms.unsaved;

const Unsaved: React.FC = () => {
  //State
  const modal = useAppSelector((state) => state.modal);
  const dispatch = useAppDispatch();
  const { setModalOff } = modalActions;
  const { defaultAction } = modal;

  //Selectors
  const form = document.querySelector("form");

  const onClick = async (type: "submit" | "ignore" | "cancel" | string) => {
    const clear = () => {
      dispatch(setModalOff());
      defaultAction && defaultAction();
    };
    switch (type) {
      case "submit":
        form?.requestSubmit();
        return clear();
      case "ignore":
        return clear();
      case "cancel": {
        return dispatch(setModalOff());
      }
      default:
        return console.error(
          `No such modal button type : ${type} / in "Confirm" modal Error`
        );
    }
  };

  return (
    <ModalContainer>
      <Instruction>
        <CautionIcon />
        {linebreak(unsaved_term.instruction)}
      </Instruction>
      <ModalBtnsBox>
        {unsaved_term.buttons.map(({ name, type }) => (
          <ModalBtn
            key={type}
            className={`${name} ${type}`}
            form={type === "submit" ? form?.id : undefined}
            onClick={() => onClick(type)}
          >
            {name || "undefined"}
          </ModalBtn>
        ))}
      </ModalBtnsBox>
    </ModalContainer>
  );
};

export default Unsaved;

import styled from "styled-components";

export const MenuContainer = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 130px calc(100vh - 330px) 100px;
`;
export const MenuSection = styled.div`
  max-width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 1.5rem;
`;
export const Menu = styled.div<{ isCurrentPage: boolean | null }>`
  font-size: 2rem;
  color: ${(props) =>
    props.isCurrentPage ? props.theme.activce : props.theme.color};
  cursor: pointer;
`;

export const Container = styled.div`
  margin: 15px 0px;
  max-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const Title = styled.div<{ isCuurentPage?: boolean }>`
  position: relative;
  display: flex;
  align-items: flex-end;
  padding-bottom: 30px;
  font-size: 2rem;
  font-weight: 900;
  text-transform: capitalize;
  border-bottom: 5px solid ${(props) => props.theme.color};
  color: ${(props) =>
    props.isCuurentPage ? props.theme.activce : props.theme.color};
  & svg {
    fill: ${(props) =>
      props.isCuurentPage ? props.theme.activce : props.theme.color};
  }
`;

export const Number = styled.div`
  margin-left: 30px;
  margin-bottom: 2px;
  font-size: 2rem;
  font-weight: 600;
  color: ${(props) => props.theme.text.normal};
`;

export const CreateNew = styled.div`
  position: absolute;
  right: 5px;
  font-size: 2.5rem;
  cursor: pointer;
`;
export const List = styled.ul`
  max-height: calc(100vh - 600px);
  min-width: 320px;
  display: flex;
  flex-direction: column;
  padding: 30px;
  gap: 15px;
  overflow-x: hidden;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  ::-webkit-scrollbar-button {
    display: none;
  }
  &:hover {
    ::-webkit-scrollbar {
      display: block;
      width: 8px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: black;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-track {
      background-color: transparent;
      margin: 40px 0px;
    }
  }
`;
export const ListItem = styled.li`
  display: flex;
  align-items: center;
  font-family: "Noto Sans CJK KR" sans-serif;
  cursor: pointer;
`;
export const GameTitle = styled.h2`
  width: 260px;
  min-height: 40px;
  margin-left: 15px;
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: -2px;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const CMSList = styled.ul`
  max-height: calc(100vh - 600px);
  min-width: 320px;
  display: flex;
  flex-direction: column;
  padding: 30px;
  gap: 20px;
  overflow-x: hidden;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  ::-webkit-scrollbar-button {
    display: none;
  }
  &:hover {
    ::-webkit-scrollbar {
      display: block;
      width: 8px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: black;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-track {
      background-color: transparent;
      margin: 40px 0px;
    }
  }
`;

export const CMSListItem = styled.li`
  display: flex;
  align-items: center;
  font-family: "Noto Sans CJK KR" sans-serif;
  cursor: pointer;
`;

export const CMSMenu = styled.h2<{ isCuurentPage: Boolean }>`
  width: 260px;
  min-height: 44px;
  font-weight: 700;
  font-size: 2rem;
  color: ${(props) =>
    props.isCuurentPage ? props.theme.activce : props.theme.color};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: visible;
  &:first-letter {
    text-transform: uppercase;
  }
`;

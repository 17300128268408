import { applyMiddleware, combineReducers, createStore } from "redux";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension";
import version from "./version";
import gameList from "./1.Game/gameList";
import createGame from "./1.Game/createGame";
import currentGame from "./1.Game/currentGame";
import platform from "./2.Platform/platform";
import rankboard from "./rankboard";
import modal from "./modal";
import submitBtn from "./submitBtn";
import CP_datas from "./3.CrossPromotion/CPDatas";
import CP_resources from "./3.CrossPromotion/CPResources";
import CP_linkQueues from "./3.CrossPromotion/CPLinkQueue";

const rootReducer = combineReducers({
  version,
  gameList,
  createGame,
  currentGame,
  platform,
  CP_datas,
  CP_resources,
  CP_linkQueues,
  rankboard,
  modal,
  submitBtn,
});

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...[]))
);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch: () => AppDispatch = useDispatch;

export default store;

import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../modules";
import {
  IPlatform,
  setPlatform,
} from "../../../../modules/2.Platform/platform";
import { modalActions } from "../../../../modules/modal";
import { isChanged } from "../../../utils";
import { PlusIcon } from "../../SVGs";
import { Header, Tab, Tabs, Title } from "./StickyHeaderStyled";

const tab = ["iOS", "AOS"];

interface IStickyHeader {
  title: string;
  onPlusClick?: () => any;
}

const StickyPlatformHeader: React.FC<IStickyHeader> = ({
  title,
  onPlusClick,
}) => {
  //State
  const currentGame = useAppSelector((state) => state.currentGame);
  const platform = useAppSelector((state) => state.platform);
  const dispatch = useAppDispatch();

  //destruction State
  const { setUnsavedModalOn } = modalActions;
  //Page
  const isCP = window.location.pathname.split("/").at(-1) === "cross_promotion";
  //Lifecycle
  useEffect(() => {
    const isIos = !!currentGame.platform_datas.ios;
    const isAos = !!currentGame.platform_datas.aos;
    if (!isIos && isAos) {
      dispatch(setPlatform("aos"));
    } else {
      dispatch(setPlatform("ios"));
    }
  }, []);

  //Function
  const onClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const {
      currentTarget: { id },
    } = event;
    if (id === platform) return;
    const clickTab = () => dispatch(setPlatform(id as IPlatform));
    isChanged() ? dispatch(setUnsavedModalOn(clickTab)) : clickTab();
  };

  return (
    <Header>
      <Title>
        {title}
        {isCP ? null : <PlusIcon onClick={onPlusClick} />}
      </Title>
      <Tabs>
        {tab.map((tab) => {
          const lowerTab = tab.toLowerCase();
          return (
            <Tab
              key={tab}
              id={lowerTab}
              onClick={onClick}
              valid={!!currentGame.platform_datas[lowerTab as IPlatform]}
              current={platform === (lowerTab as IPlatform)}
            >
              {tab}
            </Tab>
          );
        })}
      </Tabs>
    </Header>
  );
};

export default React.memo(StickyPlatformHeader);

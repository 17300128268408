import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../modules";
import { modalActions } from "../../../../modules/modal";
import { ModalTerm } from "../Modal";
import {
  Instruction,
  ModalBtn,
  ModalBtnsBox,
  ModalContainer,
} from "../ModalStyled";
import { CautionIcon } from "../../SVGs";
import modalTerms from "../ModalTerms.json";
import { linebreak } from "./ModalBase";

const not_created_term: ModalTerm = modalTerms.not_created;

const NotCreated: React.FC = () => {
  //State
  const modal = useAppSelector((state) => state.modal);
  const dispatch = useAppDispatch();
  const { defaultAction } = modal;
  const { setModalOff } = modalActions;

  const onClick = (type: "ignore" | "cancel" | string) => {
    const clear = () => {
      dispatch(setModalOff());
      defaultAction && defaultAction();
    };
    switch (type) {
      case "ignore":
        return clear();
      case "cancel": {
        return dispatch(setModalOff());
      }
      default:
        return console.error(
          `No such modal button type : ${type} / in "Confirm" modal Error`
        );
    }
  };

  return (
    <ModalContainer>
      <Instruction>
        <CautionIcon />
        {linebreak(not_created_term.instruction)}
      </Instruction>
      <ModalBtnsBox>
        {not_created_term.buttons.map(({ name, type }) => (
          <ModalBtn key={type} onClick={() => onClick(type)}>
            {name || "undefined"}
          </ModalBtn>
        ))}
      </ModalBtnsBox>
    </ModalContainer>
  );
};

export default NotCreated;

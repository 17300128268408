import React, { useEffect, useState } from "react";
import { useCP_resourceQuery } from "../../../../../../api/V2/queryHooks";
import { useAppDispatch, useAppSelector } from "../../../../../../modules";
import { CP_resourceActions } from "../../../../../../modules/3.CrossPromotion/CPResources";
import {
  ExclamationIcon,
  ImgIcon,
  PlusIcon,
  QuestionIcon,
  ReplaceIcon,
  VideoIcon,
} from "../../../../SVGs";
import {
  Image,
  InputMediaField,
  ResourceError,
  Video,
} from "../../../../Interactions/Inputs/MayoInputStyled";
import { ICPResource } from "../../../../../../api/V2/3.CrossPromotion/API_CPresource";
import { modalActions } from "../../../../../../modules/modal";

interface IResourceBox {
  resourceType: ICPResource["type"];
}

const ResourceBox: React.FC<IResourceBox> = ({ resourceType }) => {
  //State
  const platform = useAppSelector((state) => state.platform);
  const currentGame_cp_data = useAppSelector(
    (state) => state.currentGame.cp_datas[platform]
  );
  const CP_resource = useAppSelector(
    (state) => state.CP_resources[resourceType]
  );
  const dispatch = useAppDispatch();
  //destruction State
  const { file, blob_name } = CP_resource;
  const { setCP_file } = CP_resourceActions;
  const { setWarningModalOn } = modalActions;

  //LocalState
  const [sizeWarning, setSizeWarning] = useState(false);

  //Query
  const { isLoading, isFetching, error } = useCP_resourceQuery(
    currentGame_cp_data?.id as string,
    blob_name as string,
    resourceType,
    {
      enabled: !!blob_name,
      retry: 1,
    }
  );

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      currentTarget: { files },
    } = event;
    if (!files) return;
    const target = files[0];
    switch (resourceType) {
      case "image":
        if (target.size > 1048576)
          dispatch(
            setWarningModalOn("업로드하려는 이미지 크기가 1Mb를 초과했습니다.")
          );
        break;
      case "video":
        if (target.size > 10485760)
          dispatch(
            setWarningModalOn("업로드하려는 비디오 크기가 10Mb를 초과했습니다.")
          );
        break;
    }
    dispatch(setCP_file(target, resourceType));
  };

  useEffect(() => {
    if (!file) return;
    switch (resourceType) {
      case "image":
        setSizeWarning(file.size > 1048576);
        break;
      case "video":
        setSizeWarning(file.size > 10485760);
        break;
    }
  }, [file]);

  return (
    <>
      <InputMediaField>
        <div className="blank" />
        {resourceType === "image" && (
          <>
            <Image src={!!file ? URL.createObjectURL(file) : undefined} />
            <ImgIcon />
          </>
        )}
        {resourceType === "video" && (
          <>
            <Video src={!!file ? URL.createObjectURL(file) : undefined} muted />
            <VideoIcon />
          </>
        )}
        {isLoading || isFetching ? (
          "Loading..."
        ) : !!file ? (
          <ReplaceIcon />
        ) : !!currentGame_cp_data?.id ? (
          <QuestionIcon />
        ) : (
          <PlusIcon />
        )}
        {sizeWarning ? <ExclamationIcon id="big" /> : null}
        <input
          key={resourceType + Math.random().toFixed(2)}
          type="file"
          accept={`${resourceType}/*`}
          onChange={onChange}
          //
          // required={!fetchedResource}
        />
        <ResourceError key={resourceType + "error"}>
          {isLoading
            ? null
            : file
            ? null
            : error instanceof Error
            ? !!blob_name
              ? `가져올 ${resourceType} 파일이 없습니다!`
              : error.message || "unknown Error"
            : null}
        </ResourceError>
      </InputMediaField>
    </>
  );
};

export default React.memo(ResourceBox);

import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { useQueryClient } from "react-query";
import querykeys from "../../../../api/V2/query";

import {
  IPlatformData,
  PlatformDataKey,
} from "../../../../api/V2/1.Game/API_PlatformDatas";
import gameAPI from "../../../../api/V2/1.Game/API_Game";
import iconAPI from "../../../../api/V2/1.Game/API_GameIcon";
import platform_dataAPI from "../../../../api/V2/1.Game/API_PlatformDatas";

import { useAppDispatch, useAppSelector } from "../../../../modules";
import {
  createGameActions,
  ICreateGame,
  CreateGameKey,
} from "../../../../modules/1.Game/createGame";
import { IPlatform } from "../../../../modules/2.Platform/platform";
import { modalActions } from "../../../../modules/modal";
import { submitBtnActions } from "../../../../modules/submitBtn";

import { Form } from "./Gamestyled";
import MayoInput from "../../Interactions/Inputs/MayoTextInput";
import PlatformInputField from "./PlatformInputField";
import MayoIconInput from "../../Interactions/Inputs/MayoIconInput";
import { consoleLog } from "../../../utils";
import { formTerms } from "./GamePageData.json";

function CreateGame() {
  //State
  const createGame = useAppSelector((state) => state.createGame);
  const dispatch = useAppDispatch();
  //descruction State
  const {
    icon,
    platform_datas: { ios, aos },
  } = createGame;
  const {
    setCreateGame,
    setCreateGameIcon,
    setCreateGameIos,
    setCreateGameAos,
    resetCreateGame,
  } = createGameActions;
  const { giveSubmitBtnNoti, setSubmitBtnLoading } = submitBtnActions;
  const { setWarningModalOn } = modalActions;
  //Page
  const navigate = useNavigate();
  //Query
  const queryClient = useQueryClient();
  //Life Cycle
  useEffect(() => {
    return () => {
      dispatch(resetCreateGame());
    };
  }, []);
  //Function
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      currentTarget: { name, value },
    } = event;
    dispatch(
      setCreateGame(name as CreateGameKey, value as ICreateGame[CreateGameKey])
    );
  };
  const onIconChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      currentTarget: { files },
    } = event;
    if (!files) return;
    const file = files[0];
    dispatch(setCreateGameIcon(file));
  };
  const onToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      currentTarget: { id: platform, checked },
    } = event;
    const onNoff = checked ? { platform: platform as IPlatform } : undefined;
    if (platform === "ios") dispatch(setCreateGameIos(onNoff));
    if (platform === "aos") dispatch(setCreateGameAos(onNoff));
  };
  const onVersionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      currentTarget: { id: platform, value },
    } = event;
    const target = createGame.platform_datas[platform as IPlatform];
    const [major, minor, patch, reversion] = value.split(".");
    const edited: IPlatformData = {
      ...target,
      major,
      minor,
      patch,
      reversion,
    };
    if (platform === "ios") dispatch(setCreateGameIos(edited));
    if (platform === "aos") dispatch(setCreateGameAos(edited));
  };
  const onFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      currentTarget: { id, name, value },
    } = event;
    const target = createGame.platform_datas[id as IPlatform];
    const edited: IPlatformData = {
      ...target,
      [name as PlatformDataKey]: value,
    };
    if (id === "ios" && !!target) dispatch(setCreateGameIos(edited));
    if (id === "aos" && !!target) dispatch(setCreateGameAos(edited));
  };
  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { currentTarget } = event;
    const formdata = new FormData(currentTarget);
    if (!icon) return; //파일 없으면 실행 X (파일)
    if (!!ios || !!aos) {
      dispatch(setSubmitBtnLoading(true));
      try {
        const newGameData = await gameAPI
          .createNewGame(formdata)
          .catch((error) => {
            throw new Error(error);
          });
        await iconAPI
          .uploadGameIcon(newGameData.data.id, icon)
          .catch((error) => {
            dispatch(
              giveSubmitBtnNoti("fail", "게임 아이콘이 등록되지 않았습니다.")
            );
            throw new Error(error);
          });

        //활성화된 플랫폼 기준으로 플랫폼 정보 등록
        if (!!ios)
          await platform_dataAPI
            .uploadPlatform(ios, newGameData.data.id)
            .catch((error) => {
              dispatch(
                giveSubmitBtnNoti(
                  "fail",
                  "iOS 플랫폼 정보가 등록되지 않았습니다."
                )
              );
              throw new Error(error);
            });
        if (!!aos)
          await platform_dataAPI
            .uploadPlatform(aos, newGameData.data.id)
            .catch((error) => {
              dispatch(
                giveSubmitBtnNoti(
                  "fail",
                  "AOS 플랫폼 정보가 등록되지 않았습니다."
                )
              );
              throw new Error(error);
            });

        //Refresh
        queryClient.fetchQuery([querykeys.game_list]).then(
          () => {
            navigate(`/game/${newGameData.data.id}/dashboard`);
          },
          (error) => {
            dispatch(
              giveSubmitBtnNoti(
                "fail",
                error.message || "게임 갱신에 실패했습니다."
              )
            );
            throw new Error(error);
          }
        );
      } catch (error) {
        consoleLog("Create Game ERROR", error);
        dispatch(giveSubmitBtnNoti("fail", error as string));
      } finally {
        dispatch(setSubmitBtnLoading(false));
      }
    } else {
      return dispatch(
        setWarningModalOn(
          "출시 스토어는  iOS, AOS 중 한 곳은 필수로 활성화해야 합니다."
        )
      );
    }
  };

  return (
    <Form id="create-game-form" onSubmit={onSubmit}>
      {formTerms.map(({ label, type, name, platform, placeholder, fields }) => {
        switch (type) {
          case "file":
            return (
              <MayoIconInput
                key={name}
                id={name}
                label={label}
                currentfile={createGame[name as CreateGameKey] as File}
                onChange={onIconChange}
                required
              />
            );
          case "text":
            return (
              <MayoInput
                key={name}
                label={label}
                name={name}
                value={createGame[name as CreateGameKey] as string}
                onChange={onChange}
                required
              />
            );
          case "url":
            return (
              <MayoInput
                key={name}
                label={label}
                name={name}
                type="url"
                value={createGame[name as CreateGameKey] as string}
                invalidNoti="URL 형식으로 입력해주세요. Ex: http://~"
                placeholder={placeholder}
                onChange={onChange}
                required
              />
            );
          case "field":
            return (
              <PlatformInputField
                //custom props
                label={label}
                fields={fields}
                values={createGame.platform_datas[platform as IPlatform]}
                onVersionChange={onVersionChange}
                onFieldChange={onFieldChange}
                //attr
                key={platform}
                id={platform}
                checked={!!createGame.platform_datas[platform as IPlatform]}
                defaultChecked={false}
                onToggle={onToggle}
              />
            );
          default:
            return null;
        }
      })}
    </Form>
  );
}

export default CreateGame;

import styled from "styled-components";

export const TableContainer = styled.div`
  padding: 2rem 0 2rem 2rem;
  display: grid;
  grid-template-rows: repeat(11, auto);
`;

export const TableColumn = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 2fr 4fr 4fr 50px;
  & div {
    min-width: fit-content;
    line-height: 3rem;
  }
  &.row:hover > div {
    color: ${(props) => props.theme.hover};
    cursor: pointer;
  }
  &.row:active > div {
    color: ${(props) => props.theme.noti.success};
  }
`;

export const Detail = styled.details<{ block?: boolean }>`
  margin: 1rem 0px;
  padding-left: 2rem;
  pointer-events: ${(props) => (props.block ? "none" : "auto")};
  opacity: ${(props) => (props.block ? 0.4 : 1)};
  &::-webkit-details-marker {
    display: none;
  }
  & summary::before {
    content: url("/svgs/chevron-right.svg");
    display: inline-block;
    height: 1rem;
    width: 1rem;
    margin-right: 1rem;
    transition: 0.63s;
  }
  &[open] summary::before {
    rotate: 90deg;
    translate: 0px 5px;
  }
`;

export const Sum = styled.summary`
  list-style: none;
`;

export const Ranking = styled.div``;
export const Nickname = styled.div`
  position: relative;
  width: fit-content;
  & svg {
    position: absolute;
    right: -1rem;
    width: 1.3rem;
    fill: ${(props) => props.theme.noti.success};
  }
`;
export const CountryCode = styled.div``;
export const Point = styled.div``;
export const Data = styled.div``;
export const Delete = styled.span`
  font-size: 3rem;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.submitBtn.danger};
  }
`;
export const PageSelection = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem 0;
  gap: 40px;
`;
export const PageNumber = styled.div<{ currentPage?: boolean }>`
  color: ${(props) =>
    props.currentPage ? props.theme.text.highlight : props.theme.text.normal};
  cursor: pointer;
`;

import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
  GlobalSetting,
  getGlobalSetting,
  updateGlobalSetting,
} from "../../../api/V2/API_GlobalSetting";
import { useAppDispatch } from "../../../modules";
import { submitBtnActions } from "../../../modules/submitBtn";
import { isChanged } from "../../utils";
import MayoInput from "../Interactions/Inputs/MayoTextInput";
import pageData from "./GlobalSettingPageData.json";

const defaultValues = {
  url_website: "",
  url_instagram: "",
  url_facebook: "",
  url_google_developer: "",
  url_apple_developer: "",
  id: "",
};
const { global_settings } = pageData;

function Settings() {
  //State
  const dispatch = useAppDispatch();
  //destruction State
  const { giveSubmitBtnNoti, setSubmitBtnValid } = submitBtnActions;
  //LocalState
  const [values, setValues] = useState<GlobalSetting>(defaultValues);
  //Query
  const { data, refetch } = useQuery("global-setting", getGlobalSetting, {});
  //LifeCycle
  useEffect(() => {
    if (data) setValues(data);
  }, [data]);
  //Functions
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      currentTarget: { name, value },
    } = event;
    setValues((state) => ({ ...state, [name]: value }));
  };
  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { currentTarget } = event;
    const formdata = new FormData(currentTarget);

    const onSucess = () => {
      refetch();
      dispatch(giveSubmitBtnNoti("save"));
    };

    const onFailed = () => {
      dispatch(giveSubmitBtnNoti("fail"));
    };
    await updateGlobalSetting(formdata).then(onSucess).catch(onFailed);
  };

  useEffect(() => {
    setTimeout(() => {
      dispatch(setSubmitBtnValid(isChanged()));
    }, 300);
  }, [values]);

  return (
    <form id="global-settings-form" onSubmit={onSubmit}>
      {global_settings.map((prop) => (
        <MayoInput
          key={prop.label}
          label={prop.label}
          name={prop.name}
          value={values[prop.name]}
          beforeValue={!!data ? data[prop.name] : ""}
          onChange={onChange}
          invalidNoti="URL 형식으로 입력해주세요."
          required
        />
      ))}
    </form>
  );
}

export default Settings;

import {
  CreateBoardConfig,
  ExistBoardConfig,
} from "../../../modules/rankboard";
import client from "../baseV2API";

export interface GameRankboard {
  type: string;
  title: string;
}

/** Axios : C create rankboard.
 * @param {CreateBoardConfig} config
 * @return {ExistBoardConfig} Response
 */
const createRankboard = (config: CreateBoardConfig) => {
  const formdata = new FormData();
  Object.entries(config).map(([key, value]) => formdata.append(key, value));
  return client.post<ExistBoardConfig>("/rankboard", formdata);
};

/** Axios : R get rankboard.
 * @param {ExistBoardConfig['id']} board_id
 * @return {ExistBoardConfig} Response
 */
const getRankboard = (board_id: ExistBoardConfig["id"]) => {
  return client.get<ExistBoardConfig>("/rankboard", {
    params: { id: board_id },
  });
};

/** Axios : R get All rankboard list by game_id.
 * @param {string} game_id
 * @return {ExistBoardConfig[]} Response
 */
const getRankboardList = (game_id: string) => {
  return client.get<ExistBoardConfig[]>("/rankboard/list", {
    params: { game_id },
  });
};

/** Axios : U update rankboard.
 * @param {ExistBoardConfig} config
 * @return {ExistBoardConfig} Response
 */
const updateRankboard = (config: ExistBoardConfig) => {
  const formdata = new FormData();
  Object.entries(config).map(([key, value]) => formdata.append(key, value));
  return client.put<ExistBoardConfig>("/rankboard", formdata);
};

/** Axios : D delete rankboard.
 * @param {CreateBoardConfig['id']} board_id
 */
const deleteRankboard = (board_id: ExistBoardConfig["id"]) => {
  const formdata = new FormData();
  formdata.append("id", board_id);
  return client.delete("/rankboard", { data: formdata });
};

////////////////////////////////////

interface GetRankingPrams {
  board_id?: ExistBoardConfig["id"];
  from?: number;
  count?: number;
  country?: number;
}

export interface RankingConfig {
  id?: string;
  rank?: number;
  score?: number;
  country?: number;
  data?: string;
  userData?: { nick?: string };
}

export interface HonorRankingConfig extends RankingConfig {
  game_id: string;
  rankBoardId: string;
  recordIdRef: string;
  userId: string;
}

/**
 * Axios : R 보드 정보 리스트에서 Id를 이용해서 랭킹가져오기
 * @param {GetRankingPrams} params {board_id, from = 0 , count = 10, country = -1}
 * @returns  {RankingConfig[]}
 */
export const getRanking = ({
  board_id,
  from = 0,
  count = 10,
  country,
}: GetRankingPrams) => {
  return client.get<RankingConfig[]>("/rankboard/ranking", {
    params: {
      board_id,
      from,
      count,
      country: country === undefined ? -1 : country,
    },
  });
};

/**
 * Axios : R 명예의 전당 랭킹 가져오기.
 * @param {GetRankingPrams} params {board_id, from = 0 , count = 10, country = -1}
 * @returns  {HonorRankingConfig[]}
 */
export const getHallOfFame = ({
  board_id,
  from = 0,
  count = 10,
  country = -1,
}: GetRankingPrams) => {
  return client.get<HonorRankingConfig[]>("/honor/ranking", {
    params: {
      board_id,
      from,
      count,
      country,
    },
  });
};

const requestRankboard = {
  create: createRankboard,
  update: updateRankboard,
  get: getRankboard,
  getList: getRankboardList,
  delete: deleteRankboard,
  queries: {
    getRanking,
    getHallOfFame,
  },
};

export default requestRankboard;

export const consoleLog = (keyword: string, ...target: any[]) => {
  const style = [
    "color: #fff",
    "background-color: #E30",
    "padding: 1px 3px",
    "border-radius: 5px",
  ].join(";");
  console.log(`%c${keyword}`, style, ...target);
};

/** Examine is there any "changed" class */
export const isChanged = () => {
  const changedElements = document.getElementsByClassName("changed");
  return changedElements.length !== 0;
};
/**
 * Find All elements with 'changed' class, then check it's id name by param
 * @param {T} name
 * @returns {boolean}boolean
 */
export const isThisChanged = <T>(name: T): boolean => {
  const changedElements = document.getElementsByClassName("changed");
  const string = name as any;
  const changeds = changedElements.namedItem(string);
  return !!changeds;
};

/**
 * Object to FormData
 * @param dataObject
 * @returns FormData
 */
export const objectToFormData = (dataObject: { [key: string]: any }) => {
  const formdata = new FormData();
  Object.entries(dataObject).forEach(([key, value]) =>
    formdata.append(key, value)
  );
  return formdata;
};

/**
 * Make Canvas caturing video's Thumbnail
 * @param videoFile video/*
 * @returns HTMLCanvasElement
 */

export const makeVideoThumbnailURL = (videoFile: File, setImg: any) => {
  const video = document.createElement("video");
  const canvas = document.querySelector("canvas");
  if (!canvas) return;
  const canvasCTX = canvas.getContext("2d");
  //When Video's metadata is loaded
  video.addEventListener("loadedmetadata", () => {
    //Dimension of canvas = video
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
  });
};

/**라우트 구조상 게임페이지로 진입한 상태에서 뒤에서 2번째 인자를 가져옴(gameTitle) */
export const current_GameID = () =>
  window.location.pathname.split("/").at(-2)?.replace(/_/g, " ");
/**
 * String value 의 빈공간 space 제거합니다.
 * @param value
 * @returns string
 */
export const noSpace = (value: string) => value.replace(/\s/g, "_");

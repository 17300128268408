import { Reducer } from "redux";
import { IPlatformData } from "../../api/V2/1.Game/API_PlatformDatas";

export type CreateGameKey = keyof ICreateGame;
export interface ICreateGame {
  title?: string;
  package_name?: string;
  url_customer?: string;
  url_gamepage?: string;
  icon?: File;
  platform_datas: {
    ios?: IPlatformData;
    aos?: IPlatformData;
  };
}

const initialState: ICreateGame = {
  title: "",
  package_name: "",
  url_customer: "",
  url_gamepage: "",
  icon: undefined,
  platform_datas: {
    ios: undefined,
    aos: undefined,
  },
};

type ICurrentGameAction = ReturnType<
  | typeof resetCreateGame
  | typeof setCreateGame
  | typeof setCreateGameIcon
  | typeof setCreateGameIos
  | typeof setCreateGameAos
>;
/**
 * Dispatch : 새로 생성할 게임 State를 default 초기화 합니다.
 */
const resetCreateGame = () => ({
  type: "resetCreateGame" as const,
  payload: initialState,
});

/**
 * Dispatch : 새로 생성할 게임의 [key] : value 를 설정합니다.
 * @param name
 * @param value
 */
const setCreateGame = (
  name: CreateGameKey,
  value: ICreateGame[CreateGameKey]
) => ({
  type: "setCreateGame" as const,
  payload: { name, value },
});

/**
 * Dispatch : 새로 생성할 게임의 Icon 파일을 설정합니다.
 * @param icon
 */
const setCreateGameIcon = (icon: File) => ({
  type: "setCreateGameIcon" as const,
  payload: icon,
});

/**
 * Dispatch : 새로 생성할 게임의 IOS platform_data 를 설정합니다.
 * @param platform_data
 */
const setCreateGameIos = (platform_data: IPlatformData | undefined) => ({
  type: "setCreateGameIos" as const,
  payload: platform_data,
});

/**
 * Dispatch : 새로 생성할 게임의 IOS platform_data 를 설정합니다.
 * @param platform_data
 */
const setCreateGameAos = (platform_data: IPlatformData | undefined) => ({
  type: "setCreateGameAos" as const,
  payload: platform_data,
});

const createGame: Reducer<ICreateGame, ICurrentGameAction> = (
  state = initialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case "resetCreateGame":
      return initialState;
    case "setCreateGameIcon":
      return { ...state, icon: payload };
    case "setCreateGame":
      return { ...state, [payload.name]: payload.value };
    case "setCreateGameIos":
      return {
        ...state,
        platform_datas: { ...state.platform_datas, ios: payload },
      };
    case "setCreateGameAos":
      return {
        ...state,
        platform_datas: { ...state.platform_datas, aos: payload },
      };
    default:
      return state;
  }
};

export default createGame;

export const createGameActions = {
  resetCreateGame,
  setCreateGame,
  setCreateGameIcon,
  setCreateGameIos,
  setCreateGameAos,
};

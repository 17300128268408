import { useQueryClient } from "react-query";
import querykeys from "../../../../../api/V2/query";
import { IconData } from "../../../../../api/V2/queryHooks";
import { ExposeSelection, IconImage } from "../CrossPromotionStyled";
import defaultImg from "../../../../../assets/sd.png";
import { useAppSelector } from "../../../../../modules";
import { useEffect, useState } from "react";

interface IExposeGameIcon extends React.InputHTMLAttributes<HTMLInputElement> {
  game_id: string;
  game_title: string;
}

const ExposeGameIcon: React.FC<IExposeGameIcon> = ({
  game_id,
  game_title,
  ...props
}) => {
  const [checked, setChecked] = useState(false);
  const CP_currentLinks = useAppSelector(
    (state) => state.CP_linkQueues.current
  );
  const iconData = useQueryClient().getQueryData<IconData>([
    querykeys.game_icon,
    game_id,
  ]);

  useEffect(() => {
    if (!props.name) return;
    setChecked(CP_currentLinks.some(({ link_id }) => link_id === props.name));
  }, [CP_currentLinks]);

  return (
    <ExposeSelection
      className={
        (props.name === undefined ? "not_released" : "") +
        " " +
        (checked ? "checked" : "") +
        " " +
        (props.disabled ? "disabled" : "")
      }
    >
      <IconImage src={iconData?.url || defaultImg} />
      <h1>{game_title}</h1>
      <input
        type="checkbox"
        className={props.disabled ? "disabled" : ""}
        {...props}
        checked={checked}
        disabled={false}
      />
    </ExposeSelection>
  );
};

export default ExposeGameIcon;

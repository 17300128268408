import React, { useState, useEffect } from "react";
import { ReplaceIcon } from "../../SVGs";
import { GameIconLoader } from "../Loaders";
import { InputIconField, Label } from "./MayoInputStyled";

interface IMayoFileInput extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  beforefile?: File;
  currentfile?: File;
  isLoading?: boolean;
  image_url?: string;
  stateAction?: (any: any) => any;
}

/**
 * Mayo Game Icon 파일 전용 Input Element
 * @param props
 * @returns
 */
const MayoIconInput: React.FC<IMayoFileInput> = ({ stateAction, ...props }) => {
  const [iconURL, setIconURL] = useState<string>();
  useEffect(() => {
    if (props.currentfile) {
      const url = URL.createObjectURL(new Blob([props.currentfile]));
      if (url !== iconURL) setIconURL(url);
    } else {
      if (iconURL !== props.image_url) setIconURL(props.image_url);
    }
  }, [props.currentfile, props.image_url]);
  return (
    <div>
      <Label
        id={props.id}
        htmlFor={props.id}
        className={props.beforefile !== props.currentfile ? "changed" : " "}
      >
        {props.label}
      </Label>
      <InputIconField src={iconURL}>
        {props.isLoading ? (
          <GameIconLoader color="white" />
        ) : props.currentfile || props.image_url ? (
          <ReplaceIcon />
        ) : (
          "+"
        )}
        <input type="file" {...props} />
      </InputIconField>
    </div>
  );
};

export default React.memo(MayoIconInput);

import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const BoardForm = styled.form`
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  font-weight: 700;
  gap: 2rem;
  margin: 50px 0px;
`;

export const Section = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  margin: 0;
  padding: 0 2rem;
  grid-template-columns: 1fr auto;
  margin: 50px 0px;
  gap: 2rem;
`;

export const BoardBox = styled.div`
  position: relative;
  margin-bottom: 20px;
  & span {
    padding: 0 1rem;
  }
  & span svg {
    margin-left: 1rem;
    height: 1.5rem;
  }
  & span svg.copy:hover {
    fill: ${(props) => props.theme.hover};
  }
  & span svg.copy:active {
    fill: ${(props) => props.theme.noti.success};
  }
  & span svg.save {
    fill: ${(props) => props.theme.noti.success};
  }
  &.changed::before {
    display: block;
    position: absolute;
    content: "";
    top: 4px;
    left: -25px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.input.changed};
  }
`;

export const Main = styled.main``;

export const Label = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Input = styled.input`
  padding: 1.5rem 0;
`;

export const Name = styled.div`
  padding: 1.5rem 0;
`;

export const Select = styled.select`
  min-width: 200px;
  padding: 1.5rem 0;
  font-weight: 600;
`;
export const Method = styled.div`
  min-width: 200px;
  padding: 1.5rem 0;
  font-weight: 600;
`;

export const DeleteBtn = styled.button`
  position: absolute;
  padding-left: 60px;
  padding-bottom: 30px;
  width: 50px;
  top: 0;
  right: 0px;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  &:hover {
    color: red;
  }
`;

export const Page = styled.div``;

import { useState } from "react";
import styled from "styled-components";
import Table from "./Table";
import { national_codes } from "../../../../utils/nation";
import { CautionIcon } from "../../../SVGs";

const Container = styled.div`
  padding: 2rem 0 2rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & label input {
    width: fit-content;
  }
  & div {
    display: flex;
    align-items: flex-end;
  }
  & div span {
    display: flex;
    align-items: center;
    padding: 1rem;
    color: ${(props) => props.theme.noti.alert};
    & svg {
      fill: ${(props) => props.theme.noti.alert};
    }
  }
  & button {
    padding: 1rem 3rem;
    font-size: 1.4rem;
    font-weight: 800;
    border-width: 4px;
    border-radius: 20px;
    cursor: pointer;
  }
`;

interface ICTProps {
  board_id: string;
}

function CountryTable({ board_id }: ICTProps) {
  const [inputCode, setInputCode] = useState<string>();
  const [applyCode, setApplyCode] = useState<number>();
  const [noti, setNoti] = useState(false);
  const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const {
      currentTarget: { value, style },
    } = event;
    if (
      national_codes.findIndex((nation) => nation.code === parseInt(value)) !==
      -1
    ) {
      setNoti(false);
      setInputCode(value);
      style.color = "black";
    } else {
      setNoti(true);
      style.color = "red";
    }
  };

  const onClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    if (!inputCode) return setNoti(true);
    setApplyCode(parseInt(inputCode));
    setNoti(false);
  };
  return (
    <>
      <Container>
        <label>
          국가코드
          <div>
            <input
              type="number"
              min={-1}
              list="country_code"
              onChange={onChange}
            />
            <datalist id="country_code">
              {national_codes.sort().map(({ code, name }) => (
                <option key={code} value={code}>
                  {name}
                </option>
              ))}
            </datalist>
            {noti ? (
              <span>
                <CautionIcon /> 입력한 국가코드가 존재하지 않습니다.
              </span>
            ) : null}
          </div>
        </label>
        <button onClick={onClick}>적 용</button>
      </Container>
      {applyCode !== undefined ? (
        <Table
          key={board_id + applyCode + "country"}
          board_id={board_id}
          type="country"
          country={applyCode}
        />
      ) : null}
    </>
  );
}

export default CountryTable;

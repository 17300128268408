import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../modules";
import { modalActions } from "../../../../modules/modal";
import { ModalTerm } from "../Modal";
import {
  Instruction,
  ModalBtn,
  ModalBtnsBox,
  ModalContainer,
  ModalInput,
} from "../ModalStyled";
import { CautionIcon } from "../../SVGs";
import modalTerms from "../ModalTerms.json";
import { linebreak } from "./ModalBase";

const delete_game_terms: ModalTerm = modalTerms.delete_game;

function DeleteGame() {
  //State
  const currentGame = useAppSelector((state) => state.currentGame);
  const modal = useAppSelector((state) => state.modal);
  const dispatch = useAppDispatch();
  //destruction State
  const { deleteAction } = modal;
  const { setModalOff } = modalActions;
  //Local State
  const [packageName, setPackageName] = useState("");
  //Function
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      currentTarget: { value },
    } = event;
    // Package 값이 다르면 버튼 활성화가 안됨
    setPackageName(value);
  };

  const onClick = (type: "delete" | "cancel" | string) => {
    const deleteExcute = () => {
      deleteAction && deleteAction();
      dispatch(setModalOff());
    };
    switch (type) {
      case "delete":
        return deleteExcute();
      case "cancel": {
        return dispatch(setModalOff());
      }
      default:
        return console.error(
          `No such modal button type : ${type} / in "Delete" modal Error`
        );
    }
  };

  return (
    <ModalContainer>
      <Instruction>
        <CautionIcon />
        {linebreak(delete_game_terms.instruction)}
      </Instruction>
      <ModalInput
        type="text"
        placeholder="Package Name"
        value={packageName}
        onChange={onChange}
      />
      <ModalBtnsBox>
        {delete_game_terms.buttons.map(({ name, type }) => (
          <ModalBtn
            key={type}
            className={`${name} ${type}`}
            onClick={() => onClick(type)}
            disabled={
              type === "delete" && currentGame.package_name !== packageName
            }
          >
            {name || "undefined"}
          </ModalBtn>
        ))}
      </ModalBtnsBox>
    </ModalContainer>
  );
}

export default DeleteGame;

import { Reducer } from "redux";
import { IGameList } from "../../api/V2/1.Game/API_Game";

const initialState: IGameList[] = [];

type IGameListAction = ReturnType<typeof initGameList>;

const initGameList = (gameList: IGameList[]) => ({
  type: "initGameList" as const,
  payload: gameList,
});

const gameList: Reducer<IGameList[], IGameListAction> = (
  state = initialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case "initGameList":
      return payload;
    default:
      return state;
  }
};

export default gameList;

export const gameListActions = {
  initGameList,
};
